import {
    dealResult
} from './dealResult';
import {
    message
} from 'antd';
import * as https from "https";




function obj2String(obj, arr = [], idx = 0) {
    for (let item in obj) {
        arr[idx++] = [item, obj[item]];
    }
    return new URLSearchParams(arr).toString();
}


/**
 * 真正的请求
 * @param url 请求地址
 * @param options 请求参数
 * @param method 请求方式
 */
const httpsAgent = new https.Agent({
  rejectUnauthorized: false, // 忽略 SSL 验证
});
 function commonFetcdh(url, options, method = 'GET', other) {
    let regions;
    let token = other.token;
    console.log('token commonFetcdh', token);
    if (other.regions === 'en') {
      regions = 'SG';
    } else {
      regions = other.regions.split('-')[1];
    }
    const searchStr = obj2String(options);
    let initObj = {};
    if (method === 'GET') { // 如果是GET请求，拼接url
      url += '?' + searchStr;
      initObj = {
        method: method,
        credentials: 'include',
        headers: new Headers({
            'regions': regions,
            'token': token

        }),
      };
    } else {
      initObj = {
        method: method,
        credentials: 'include',
        dataType: 'json',
        headers: new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'regions': regions,
          'token': token
        }),
        body: options
      };
    }
    initObj.agent = httpsAgent;
    return fetch(url, initObj).then((res) => {
      try {
        return  res.json();
      } catch {
        return {};
      }

    }).then((res) => {
      return res;
    });
  }


export default {

  GET(url, options, other) {
    return commonFetcdh(url, options, 'GET', other);
  },
  POST(url, options, other) {
    return commonFetcdh(url, options, 'POST', other);
  }
};
