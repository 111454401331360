import { useWeb3Modal } from "@web3modal/react";
import styles from './index.module.scss';
import {Button, Form, Input, message, Modal, Spin} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {t} from "i18next";
import { GoogleOAuthProvider, googleLogout } from "google-oauth-gsi";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {getCookie, getQueryString, getRightAreaCode, isMobile} from "@/tools/action";
import {
  APICheckEmail,
  APICheckWallet, APIGetIGMBalance, APIGetIntegralAccountPage, APIGetProfile,
  APIGetProfileUnDeal, APIGetTwitterLoginUrl,
  APILogin, APILoginV2, APIModifyUserInfo, APIRegister,
  APISendRetrieveCode,
  APISendVerificationCode,
  APISmsRetrieveCode,
  APISmsVericationCode
} from "@/api";
import {useRouter} from "next/router";
import useDispatchAction from "@/hooks/useDisptachAction";
import {setUserInfo} from "@/redux/actions/userInfo";
import {LoadingOutlined} from "@ant-design/icons";
import {WalletGetMetamaskProviderAndAddress} from "@/tools/walletTools";
import {walletConnect} from "@/tools/walletConnect";
import {
  setAllowPointsExchangeIGM,
  setCoinsBalance,
  setExpiringPoints,
  setIGMBalance, setLoginBindTipShow,
  setLoginShowModal,
} from "@/redux/actions/home";
import {useSelector} from "react-redux";
import {watch} from "@/tools/wacth";
import {loginShowModal} from "@/tools/dealResult";
import {useAccount, useDisconnect, useSignMessage} from "wagmi";
import walletConfig from "@/config/walletConfig";
const googleAppId = '470829691764-naqa6nfbnluvn8nf8jrafgich8e9njv1.apps.googleusercontent.com';
const facebookAppId = '2170943866417417';
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 20,
      color:'#095359',
      width:'34px'

    }}
    spin
  />
);

const googleProviderChange = {
  isLoad: false,
};

const googleProvider = new GoogleOAuthProvider({
  clientId: googleAppId,
  onScriptLoadError: () => {
    googleProviderChange.isLoad = false;
  },
  onScriptLoadSuccess: () => {
    googleProviderChange.isLoad = true;
  },
});


const GoogleLoginBtn = ({onCallBack}) => {

  const [googleLoginBtn, setGoogleLoginBtn] = useState(null);

  const [mobile, setMobile] = useState(false);

  const renderButton = googleProvider.useRenderButton({
    useOneTap: false,
    element: googleLoginBtn,
    // theme: 'filled_black',
    width: mobile ? '320px' : '400px',
    size: 'large',
    ux_mode: 'popup',
    logo_alignment: 'center',
    text: 'continue_with',
    onError: () => console.error('Failed to Google login'),
    onSuccess: (res) => {
      const backQuery = {
        tokenId: res.credential
      };
      onCallBack?.(backQuery);
    },
  });


  useEffect(() => {
    if (googleLoginBtn) {
      renderButton();
    }

  }, [googleLoginBtn]);

  useEffect(() => {
    setMobile(isMobile());
    setGoogleLoginBtn(document.getElementById('googleLogin'));
    const element = document.getElementById("googleLogin");
    if (element) {
      element.style.width = "100%";
      element.style.maxWidth = "100%";
      element.style.height = "57px";
    }
  }, []);

  return <div className={styles.google_login_btn} id={'googleLogin'}></div>;

};

const TwitterLoginBtn = () => {
  const [loading, setLoading] = useState(false);
  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    setMobile(isMobile());
  }, []);


  const loginTwitter = ( ) =>{
    setLoading(true);
    APIGetTwitterLoginUrl({}).then(resp=>{
      if (resp.result){
        window.location.href = resp.result;
      }
    }).finally(()=>{
      setLoading(false);
    });
  };

  return <Button loading={loading} onClick={loginTwitter} className={styles.twitter_login_btn} >
    <img src={"https://static-img.igamie.com/static/twitter_x_icon.webp"}/>
    {t("Continue with Twitter")}
  </Button>;

};

const LoginModal = ()=>{
  const router = useRouter();
  const [step, setStep] = useState(1); // 1:登录流程 2:注册流程 3:钱包登录 4:忘记密码 5:密码输入框 6:钱包登录绑定邮箱流程 7:填写名字
  const [mobile, setMobile] = useState(false);
  const [inviteCode, setInviteCode] = useState("");

  const [loginForm] = Form.useForm();

  const [newLoginStep, setNewLoginStep] = useState(1); // 1：登录 2:注册

  const [state, setState] = useState({
    loading: false,
    googleLoading: false,
    fbLoading: false,
    discordLoading: false,
    keepLogin: true,
    isCheck: true,
    // 注册
    codePending:false,
    getCodeTips: t('send'),

    // 忘记密码
    tabActive: 1,
    ReCAPTCHATOKEN:null,
    reShow:false,
    accountValue:null,
    flagMenu: false,
    locale: router.locale,

    metamaskLoading:false,
    walletLoading:false
  });
  const dataZoomTimeId = useRef();

  const codeTime = useRef(null);

  const dispatchAction = useDispatchAction({setUserInfo, setLoginShowModal, setIGMBalance, setCoinsBalance, setAllowPointsExchangeIGM, setExpiringPoints, setLoginBindTipShow});
  const showModal = useSelector(state => state.loginModal.setLoginShowModal.showModal);

  const userInfo = useSelector(state => state.userInfo.userInfo.userInfo);

  const [isForget, setIsforget] = useState(false);

  const oneTap = googleProvider.useGoogleOneTapLogin({
    cancel_on_tap_outside: false,
    onSuccess: (res) => {
      const backQuery = {
        tokenId: res.credential
      };
      responseGoogle(backQuery);
    },
  });
  const responseGoogle = (resp) => {
    let channel =  getQueryString('c') || getQueryString('channel') || localStorage.getItem('channel');
    let utmSource = getQueryString('utm_source') || getQueryString('utmSource') || localStorage.getItem('utmSource');
    let referralCode = getQueryString('rc') || getQueryString('referralCode');
    let tokenId = resp.tokenId;

    if (!state.isCheck) {
      return message.warning(`
            To proceed, please agree to the Terms of Service and Privacy Policy.
            `)
    }

    setState({
      ...state,
      googleLoading: true
    });
    localStorage.removeItem('userInfo');
    let params = {
      loginType: 2,
      loginToken: tokenId,
      channel: channel || utmSource
    };
    if (referralCode) { params.referralCode = referralCode }
    if (state.keepLogin) { params.keepLogin = true; }
    APILogin(JSON.stringify(params)).then(async resp => {
      let isNewRegister = getCookie('isNewRegister')
      localStorage.setItem('isNewRegister', resp.result.isNewRegister);
      localStorage.setItem('isBindEmailOrPhoneNumber', resp.result.isBindEmailOrPhoneNumber);
      localStorage.setItem('isBindWallet', resp.result.isBindWallet);
      if (resp.result.isNewRegister === 1) {
        localStorage.setItem('token', resp.result.token);
        // router.push(`/welcome`)
        setStep(7);
      } else {
        localStorage.setItem('token', resp.result.token);
        getProfile();
      }
    }, e=> {
      setState({...state, googleLoading:false});
    });
  };
  const responseGoogleError = async () => {

  };

  const onCancel = ()=>{
    dispatchAction.setLoginShowModal(false);
  };

  const responseFacebook = (resp) => {
    let channel =  getQueryString('c') || getQueryString('channel') || localStorage.getItem('channel');
    let utmSource = getQueryString('utm_source') || getQueryString('utmSource') || localStorage.getItem('utmSource');
    let referralCode = getQueryString('rc') || getQueryString('referralCode');
    let accessToken = resp.accessToken;

    if (!state.isCheck) {
      return message.warning(`
            To proceed, please agree to the Terms of Service and Privacy Policy.
            `)
    }
    setState({
      ...state,
      fbLoading: true
    });
    localStorage.removeItem('userInfo');
    let params = {
      loginType: 3,
      loginToken: accessToken,
      accessToken: accessToken,
      channel: channel || utmSource
    };
    if (referralCode) { params.referralCode = referralCode }
    if (state.keepLogin) {
      params.keepLogin = true;
    }
    APILogin(JSON.stringify(params)).then(async resp => {
      if (resp.result) {
        let isNewRegister = getCookie('isNewRegister')
        localStorage.setItem('isNewRegister', resp.result.isNewRegister);
        localStorage.setItem('isBindEmailOrPhoneNumber', resp.result.isBindEmailOrPhoneNumber);
        localStorage.setItem('isBindWallet', resp.result.isBindWallet);
        if (resp.result.isNewRegister === 1) {
          localStorage.setItem('token', resp.result.token);
          // router.push(`/welcome`)
          setStep(7)
        } else {
          localStorage.setItem('token', resp.result.token);
          getProfile();
        }
      } else {
        message.error(resp.resultMessage)
      }
      setState({...state, fbLoading:false});
    }, e=> {
      setState({...state, fbLoading:false});
    });

  };
  const facebookClick = (onclick) => {
    onclick();
  };
  const gotoWallectConnect = () => {
    // onCancel();
    // router.push(`/walletLogin/`);
    setStep(3);
  }
  const onFinishFailed = (e) => {

  };
  const onFinishSingUp = (values) => {
    if (!state.isCheck) {
      return message.warning(`
            To proceed, please agree to the Terms of Service and Privacy Policy.
            `)
    }
    setLoading(true);
    let forgetPwd = getQueryString('forgetPwd');
    let channel =  getQueryString('c') || getQueryString('channel') || localStorage.getItem('channel');
    let utmSource = getQueryString('utm_source') || getQueryString('utmSource') || localStorage.getItem('utmSource');
    channel = channel || utmSource;
    let rc = getQueryString('rc') || getQueryString('referralCode') || localStorage.getItem('referralCode') || localStorage.getItem('rc');
    let reName = getQueryString('reName');
    let isEmail = values.account.includes('@');
    let areaCode = getRightAreaCode(router.locale);
    let password = values.password;
    window.gtag && window.gtag('event', 'register', {
      'event_category': 'Click',
      'event_label': 'channel',
      'value': channel,
    });
    let params = {
      registerType:forgetPwd === 'true' ? 3 : isEmail ? 1 : 2,
      verifyCode: values.verifyCode,
      password : password,
      channel: channel,
      referralCode: rc,
      // deviceId
    };
    if (isEmail){
      params.email = values.account;
    } else {
      params.mobilePhone = email;
      params.areaCode = areaCode;
    }
    APIRegister(JSON.stringify(params)).then(async resp => {
      if (resp.resultCode !== 200) {
        setLoading(false);
      }
      if (resp.resultCode === 200) {
        localStorage.setItem('token', resp.result.token);
        localStorage.setItem('isNewRegister', resp.result.isNewRegister);

        let isBindEmail = localStorage.getItem('emailBindWallet');


        if (isBindEmail && isBindEmail === "1"){
          let walletAddress = localStorage.getItem('waddress');
          let walletSignData = localStorage.getItem('walletSignData');
          let walletNumber = localStorage.getItem('walletNumber');
          localStorage.removeItem('waddress');
          localStorage.removeItem('emailBindWallet');
          localStorage.removeItem('walletSignData');
          localStorage.removeItem('walletNumber');
          walletLoginWithEmail(walletAddress, walletAddress, email, password, walletSignData, walletNumber);
        } else {
          setStep(7);
        }
      } else {
        message.error(resp.resultMessage);
      }

    }).catch((e) => setLoading(false));
  };
  const singUpSendCode = async () => {
    try {
      await singUpForm.validateFields(['account']);
      const account = await singUpForm.getFieldValue('account');
      sendAgainClickGoogle(account);

    } catch (e) {
      //
    }
  };
  const onFinish = (values) => {
    let channel =  getQueryString('c') || getQueryString('channel') || localStorage.getItem('channel');
    let utmSource = getQueryString('utm_source') || getQueryString('utmSource') || localStorage.getItem('utmSource');
    let referralCode = getQueryString('rc') || getQueryString('referralCode') || localStorage.getItem('referralCode');
    if (!state.isCheck) {
      return message.warning(`
            To proceed, please agree to the Terms of Service and Privacy Policy.
            `)
    }

    setState({
      ...state,
      loading: true
    });
    let params = {
      loginType:0,
      account: values.account,
      email: values.account,
      password: values.password,
    };
    if (loginForm.getFieldValue("referralCode")) {
      params.referralCode = loginForm.getFieldValue("referralCode");
    }
    if (channel || utmSource){
      params.channel = channel || utmSource;
    }
    if (state.keepLogin){
      params.keepLogin = true;
    }
    localStorage.removeItem('userInfo');
    localStorage.removeItem('token');
    APILoginV2(JSON.stringify(params)).then(async resp => {
      if (resp.resultCode === 200) {
        localStorage.setItem('isNewRegister', resp.result.isNewRegister);
        localStorage.setItem('isBindEmailOrPhoneNumber', resp.result.isBindEmailOrPhoneNumber);
        localStorage.setItem('isBindWallet', resp.result.isBindWallet);
        localStorage.setItem('token', resp.result.token);
        getProfile();
      }
      else if (resp.resultCode === 10002) {
        setEmail(values.account);
        sendAgainClickGoogle(values.account);
        setStep(2);
        localStorage.setItem('puid', window.btoa(values.password))
        localStorage.setItem('rc', referralCode)
      }
      else {
        message.error(resp.resultMessage)
      }
      setState({...state, loading: false});
    }, e=> {
      setState({...state, loading: false});
    });

  };
  const checkBindAccount = () => {
    const isNewRegister = localStorage.getItem('isNewRegister');
    const isBindEmailOrPhoneNumber = localStorage.getItem('isBindEmailOrPhoneNumber');
    const isBindWallet = localStorage.getItem('isBindWallet');
    if (isNewRegister === "1") {
      dispatchAction.setLoginBindTipShow(true);
    }
  };
  const getProfile = () =>{
    APIGetProfileUnDeal().then(async resp => {
      let userInfo = resp.result ? resp.result : {};
      dispatchAction.setUserInfo(userInfo);
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      getMyCoins();
      onCancel();
      checkBindAccount();
      loginShowModal.login = true;
    }).finally(()=>{
      setState({...state, loading: false});
    });
  }

  const getMyCoins = () => {
    APIGetIntegralAccountPage().then(resp => {
      if (resp.result){
        // setState({
        //   ...state,
        //   coinsNum: resp.result.balance
        // })
        dispatchAction.setCoinsBalance(resp.result && resp.result.balance)
        dispatchAction.setExpiringPoints(resp.result ? resp.result.expired : 0)
      }
    });

    APIGetIGMBalance().then(resp => {
      if (resp.result) {
        dispatchAction.setIGMBalance(resp.result && resp.result.balance);
        dispatchAction.setAllowPointsExchangeIGM(resp.result && resp.result.allowPointsExchange);
      }
    });
  };

  useEffect(async ()=>{
    if (showModal){
      setStep(loginShowModal.step);
    }
  }, [showModal]);

  useEffect(()=>{
    setMobile(isMobile());
  }, []);

  useEffect(()=>{
    watch(loginShowModal, "showModal", (v)=>{
      dispatchAction.setLoginShowModal(v);
    });
    watch(googleProviderChange, "isLoad", (v) => {
      if (v) {
        const token = localStorage.getItem('token');
        if (!token) {
          oneTap();
        }
      }
    });
  }, []);

  useEffect(()=>{
    const inviteCodeUtm = getQueryString("inviteCode");
    if (inviteCodeUtm){
      setNewLoginStep(2);
      setInviteCode(inviteCodeUtm);
      loginForm.setFieldValue("referralCode", inviteCodeUtm);
    }
  }, [])

  // 注册
  const [loading, setLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [email, setEmail] = useState("");
  let codeTimeNum = 60;
  const sendAgainClickGoogle = (account, forget) => {
    //  if (state.codePending) {
    //    return
    //  }
    //   setState({
    //       ...state,
    //       reShow: true
    //     });
    // window.grecaptcha.ready(function() {
    //   window.grecaptcha.execute('6Le9KfEcAAAAABuQEo55-59ygZV7ZQI8rh6QrbVm', {action: 'submit'}).then(function(token) {
    //     console.log('token', token);
    //     sendAgainClick(token, account);
    //
    //   });
    // });
    console.log('fogetClick', forget)
    if (forget) {
      forgetSendAgainClick("", account);
    } else {
      sendAgainClick("", account);
    }

  };

  const  getCode = () => {
    let account = getQueryString('account')
    codeTime.current = setInterval(codeTimeFn, 1000);
    if (!account || account === '') {
      return
    }
    if (state.codePending) {
      return
    }
    tipNextCode(60)
  }

  const tipNextCode = (n) => {
    if (n <= 0) {
      setState({
        ...state,
        codePending: false,
        getCodeTips: t('send')
      })
      return
    }
    setState({
      ...state,
      reShow: false,
      codePending: true,
      getCodeTips: `${n} sec after`
    })
    setTimeout(() => {
      tipNextCode(n - 1)
    }, 1000)
  }

  const sendAgainClick = (token, account) => {
    let areaCode = getRightAreaCode(router.locale);
    let forgetPwd = getQueryString('forgetPwd');
    let accountValue = email;
    setSendLoading(true)

    if (account){
      accountValue = account;
    }

    if (forgetPwd === 'true'){
      if (accountValue && accountValue.includes('@')) {
        APISendRetrieveCode({
          email: accountValue
        }).then(resp => {
          message.success('success');
        }).finally(() => {
          setSendLoading(false)
        });
      } else {
        APISmsRetrieveCode({
          mobilePhone: accountValue,
          areaCode
        }).then(resp => {
          message.success('success');
        }).finally(() => {
          setSendLoading(false)
        });
      }
    } else if (accountValue && accountValue.includes('@')) {
      APISendVerificationCode({
        email: accountValue,
        userToken: token
      }).then(resp => {
        message.success('success');
        getCode()
      }).catch(e => {
      }).finally(() => {
        setSendLoading(false)
      });
    } else {
      APISmsVericationCode({
        mobilePhone: accountValue,
        areaCode,
        userToken: token
      }).then(resp => {
        message.success('success');
        getCode()
      }).finally(() => {
        setSendLoading(false)
      });
    }

  };

  const forgetSendAgainClick = (token, account) => {
    console.log('fogetClick')
    let areaCode = getRightAreaCode(router.locale);
    let forgetPwd = getQueryString('forgetPwd');
    let accountValue = email;
    setSendLoading(true)

    if (account){
      accountValue = account;
    }

    if (accountValue && accountValue.includes('@')) {
      APISendRetrieveCode({
        email: accountValue
      }).then(resp => {
        message.success('success');
      }).finally(() => {
        setSendLoading(false)
      });
    } else {
      APISmsRetrieveCode({
        mobilePhone: accountValue,
        areaCode
      }).then(resp => {
        message.success('success');
      }).finally(() => {
        setSendLoading(false)
      });
    }
  };

  // 完成注册
  const onFinishRis =  (values) => {
    setLoading(true);
    let forgetPwd = getQueryString('forgetPwd');
    let channel =  getQueryString('c') || getQueryString('channel') || localStorage.getItem('channel');
    let utmSource = getQueryString('utm_source') || getQueryString('utmSource') || localStorage.getItem('utmSource');
    channel = channel || utmSource;
    let rc = getQueryString('rc') || getQueryString('referralCode') || localStorage.getItem('referralCode') || localStorage.getItem('rc');
    let reName = getQueryString('reName');
    let isEmail = email.includes('@');
    let areaCode = getRightAreaCode(router.locale);
    let password =  window.atob(localStorage.getItem('puid'));


    window.gtag && window.gtag('event', 'register', {
      'event_category': 'Click',
      'event_label': 'channel',
      'value': channel,
    });

    let params = {
      registerType:forgetPwd === 'true' ? 3 : isEmail ? 1 : 2,
      verifyCode: values.verifyCode,
      password : password,
      channel: channel,
      referralCode: rc,
      // deviceId
    };
    if (isEmail){
      params.email = email;
    } else {
      params.mobilePhone = email;
      params.areaCode = areaCode;
    }
    APIRegister(JSON.stringify(params)).then(async resp => {
      if (resp.resultCode !== 200) {
        setLoading(false);
      }
      if (resp.resultCode === 200) {
        localStorage.setItem('token', resp.result.token);
        localStorage.setItem('isNewRegister', resp.result.isNewRegister);

        let isBindEmail = localStorage.getItem('emailBindWallet');


        if (isBindEmail && isBindEmail === "1"){
          let walletAddress = localStorage.getItem('waddress');
          let walletSignData = localStorage.getItem('walletSignData');
          let walletNumber = localStorage.getItem('walletNumber');
          localStorage.removeItem('waddress');
          localStorage.removeItem('emailBindWallet');
          localStorage.removeItem('walletSignData');
          localStorage.removeItem('walletNumber');
          walletLoginWithEmail(walletAddress, walletAddress, email, password, walletSignData, walletNumber);
        } else {
          setStep(7);
        }
      } else {
        message.error(resp.resultMessage);
      }

    }).catch((e) => setLoading(false));
  };


  // 钱包登录
  const metaMaskConnectClick = async () => {
    setState({...state, metamaskLoading: true});

    try {
      await WalletGetMetamaskProviderAndAddress({callBack: metamaskCallBack});

    } catch (e) {
      console.log('e', e);
      setState({...state, metamaskLoading: false});
    }



  }
  const walletConnectClick = () => {
    setState({...state, walletLoading: true});
    console.log('xixiixixxi')
    if (localStorage.getItem('walletconnect')){
      localStorage.removeItem('walletconnect');
    }
    walletConnect(true, (walletAddress, signData, walletNumber)=>{
      console.log("走了这个")
      checkWalletAccount(walletAddress, signData, walletNumber);
    }).catch(e => setState({...state, walletLoading: false})).finally(()=>{
      setState({...state, walletLoading: false});
    });
  }
  const metamaskCallBack = (provider, address, signData, walletNumber) => {
    if (address && address.length > 0) {
      checkWalletAccount(address[0], signData, walletNumber);
    } else {
      setState({...state, metamaskLoading: false});
    }
  }
  const checkWalletAccount = (walletAddress, signData, walletNumber) =>{
    let referralCode = getQueryString('referralCode') || getQueryString('rc')
    APICheckWallet({
      account:walletAddress
    }).then(resp=>{
      if (resp.result){
        walletLogin(walletAddress, walletAddress, signData, walletNumber);
      } else {
        console.log("跳转去绑定");
        //   router.push("/walletBindEmail/[address]",
        //     `/walletBindEmail/${walletAddress}`, {locale:router.locale});
        localStorage.setItem('waddress', walletAddress);
        localStorage.setItem('walletSignData', signData);
        localStorage.setItem('walletNumber', walletNumber);
        // router.push(`/walletBindEmail/?rc=${referralCode}`);
        setState({...state, metamaskLoading: false, walletLoading: false});
        setStep(6);
        setWalletLoading(false);
      }
    }).catch(e => {
      setState({...state, metamaskLoading: false, walletLoading: false});
      setWalletLoading(false);
    });
  };

  const walletLogin = (walletAccounts, accessToken, signData, walletNumber) => {
    APILogin(JSON.stringify({
      walletAccounts,
      accessToken,
      isBindAccount: 0,
      loginType: 5,
      loginToken: signData,
      walletNum: walletNumber
    })).then((resp) => {
      if (resp.result){
        console.log('resp', resp)
        localStorage.setItem('token', resp.result.token);
        let isNewRegister = getCookie('isNewRegister')
        console.log('isNewRegister', isNewRegister)
        localStorage.setItem('isNewRegister', resp.result.isNewRegister);
        localStorage.setItem('isBindEmailOrPhoneNumber', resp.result.isBindEmailOrPhoneNumber);
        localStorage.setItem('isBindWallet', resp.result.isBindWallet);
        if (resp.result.isNewRegister === 1) {
          localStorage.setItem('token', resp.result.token);
          // router.push(`/welcome`)
          setStep(7);
        } else {
          localStorage.setItem('token', resp.result.token);
          getProfile();
        }
      }
    }, err => {
      localStorage.removeItem('walletconnect');
      setWalletLoading(false);
    }).finally(() => {
      setState({...state, metamaskLoading: false, walletLoading: false});
      setWalletLoading(false);
    });
  }

  // 注册
  const [singUpForm] = Form.useForm();

  // 忘记密码
  const [form] = Form.useForm();
  const onFinishGoogle = (values) => {
    // setState({
    //   ...state,
    //   accountValue: values,
    // });
    // window.grecaptcha.ready(function() {
    //   window.grecaptcha.execute('6Le9KfEcAAAAABuQEo55-59ygZV7ZQI8rh6QrbVm', {action: 'submit'}).then(function(token) {
    //     console.log('token', token)
    //     onFinishForget(values, token);
    //
    //   });
    // });
    onFinishForget(values, "");
  };

  const onFinishForget = (values, token) => {
    let areaCode = getRightAreaCode(router.locale);
    let channel =  getQueryString('c') || getQueryString('channel') || localStorage.getItem('channel');
    let utmSource = getQueryString('utm_source') || getQueryString('utmSource') || localStorage.getItem('utmSource');
    channel = channel || utmSource;
    setState({
      ...state,
      loading: true
    });

    let isEmail = values.account.includes('@');

    let params = {
      userToken: token
    }

    if (isEmail){
      params.email = values.account;
      APISendRetrieveCode(params).then(resp => {
        message.success('success');
        setEmail(values.account);
        setStep(5);
      }).finally(()=>{
        setState({
          ...state,
          reShow: false,
          loading: false
        });
      });
    } else {
      params.mobilePhone = values.account;
      params.areaCode = areaCode;
      APISmsRetrieveCode(params).then(resp => {
        message.success('success');
        setEmail(values.account);
        setStep(5);
      }).finally(()=>{
        setState({
          ...state,
          reShow: false,
          loading: false
        });
      });
    }
  };

  // 修改手机号国家去好
  const changeRegion = (item) => {
    setState({
      ...state,
      flagMenu: false,
      locale: item
    })
  }

  const onFinishForgetPasword = (values) => {
    let channel =  getQueryString('c') || getQueryString('channel') || localStorage.getItem('channel');
    let utmSource = getQueryString('utm_source') || getQueryString('utmSource') || localStorage.getItem('utmSource');
    channel = channel || utmSource;
    let rc = getQueryString('rc') || getQueryString('referralCode') || localStorage.getItem('referralCode');
    let reName = getQueryString('reName');
    let isEmail = email.includes('@');
    let areaCode = getRightAreaCode(router.locale);


    window.gtag && window.gtag('event', 'register', {
      'event_category': 'Click',
      'event_label': 'channel',
      'value': channel,
    });
    setState({
      ...state,
      loading: true
    })


    let params = {
      registerType:3,
      verifyCode: values.verifyCode,
      password: values.password,
      channel: channel,
      referralCode: rc,
    };
    if (isEmail){
      params.email = email;
    } else {
      params.mobilePhone = email;
      params.areaCode = areaCode;
    }
    APIRegister(JSON.stringify(params)).then(async resp => {

      if (resp.resultCode === 200) {
        localStorage.setItem('token', resp.result.token);
        localStorage.setItem('isNewRegister', resp.result.isNewRegister);
        getProfile();
        // if (forgetPwd) {
        //   if (redirectHref && redirectHref !== 'null') {
        //     window.location.href = decodeURIComponent( redirectHref);
        //   } else {
        //     window.location.href = '/';
        //   }
        // } else {
        //   if (redirectHref && redirectHref !== 'null'){
        //       router.push(`/welcome/?redirect=${redirectHref}`);
        //   } else {
        //     router.push(`/welcome`);
        //   }
        // }
      } else {
        message.error(resp.resultMessage)
      }
    }).finally(() => {
      setState({
        ...state,
        loading: false
      })
    });
  };


  // 钱包登录绑定邮箱流程
  const onFinishEmailBind = (values) => {

    setState({
      ...state,
      loading: true
    });

    let walletAddress = localStorage.getItem('waddress');
    let walletSignData = localStorage.getItem('walletSignData');
    let walletNumber = localStorage.getItem('walletNumber');
    let email = values.account
    let password = values.password
    setEmail(values.account);
    APICheckEmail({ email }).then(resp => {
      if (resp.result) {
        walletLoginWithEmail(walletAddress, walletAddress, email, password, walletSignData, walletNumber);
      } else {
        localStorage.setItem('puid', window.btoa(values.password));
        localStorage.setItem('emailBindWallet', "1");
        sendAgainClickGoogle(values.account);
        setStep(2);
      }
    }).finally(()=>{
      setState({
        ...state,
        loading: false
      });
    });
  };

  const walletLoginWithEmail = (walletAccounts, accessToken, email, password, walletSignData, walletNumber) => {
    APILogin(JSON.stringify({
      walletAccounts:walletAccounts,
      accessToken:accessToken,
      isBindAccount: 1,
      loginType: 5,
      password:password,
      email: email,
      loginToken: walletSignData,
      walletNum: walletNumber
    })).then((resp) => {
      if (resp.result){
        localStorage.setItem('token', resp.result.token);
        let isNewRegister = getCookie('isNewRegister');
        localStorage.setItem('isNewRegister', resp.result.isNewRegister);
        localStorage.setItem('isBindEmailOrPhoneNumber', resp.result.isBindEmailOrPhoneNumber);
        localStorage.setItem('isBindWallet', resp.result.isBindWallet);
        if (isNewRegister === '1') {
          localStorage.setItem('token', resp.result.token);
          setStep(7);
        } else {
          getProfile();
          localStorage.setItem('token', resp.result.token);
        }
        return;
      }
      message.error(resp.resultMessage);
    });
  };

  const changeEmail = (e) => {
    clearTimeout(dataZoomTimeId.current)
    dataZoomTimeId.current = setTimeout(() => {
      let email = e.target.value
      console.log('eamil', email)
      if (!email) {
        return
      }


    }, 1500)
  }

  const forgetPwdClick = () =>{
    setStep(4);
  };

  const SkipClick = () => {
    let walletAddress = localStorage.getItem('waddress')
    let walletSignData = localStorage.getItem('walletSignData');
    let walletNumber = localStorage.getItem('walletNumber');
    walletLogin(walletAddress, walletAddress, walletSignData, walletNumber);
  }


  // 修改昵称
  const onFinishNickname = (values) => {
    APIModifyUserInfo(JSON.stringify({
      nickName: values.nickName,
    })).then(resp => {
      getProfile();
    });
  };

  //
  const SkipClickNickname = () => {
    getProfile();
  };

  const [timeNum, setTimeNum] = useState(t('send'));
  const [codePending, setCodePending] = useState(false);

  // 倒计时
  const codeTimeFn = () => {
    if (codeTimeNum < 0) {
      codeTimeNum = 60;
      setCodePending(false);
      setTimeNum(t('send'));
      clearInterval(codeTime.current);
    } else {
      setCodePending(true);
      setTimeNum(`${codeTimeNum} sec after`);
      codeTimeNum -= 1;
    }
  };

  useEffect(() => {
    return () => {
      clearInterval(codeTime.current);
    };
  }, []);


  const [walletLoading, setWalletLoading] = useState(false);
  const { open, isOpen, close } = useWeb3Modal();
  const { isConnected, address } = useAccount();
  const { disconnect } = useDisconnect();
  const { signMessageAsync } = useSignMessage();


  useEffect( async  ()=>{
    if (isConnected){
      try {
        setWalletLoading(true);
        const walletNumber = Math.floor(Math.random() * 1000);
        const signMessageRes = await signMessageAsync({
          message: walletConfig.getWalletSignMessage(walletNumber, address)
        });
        checkWalletAccount(address, signMessageRes, walletNumber);
      } catch (err) {
        console.log(err);
      }
    }
  }, [isConnected]);

  async function onOpen() {
    try {
      if (isConnected){
        setWalletLoading(true);
        const walletNumber = Math.floor(Math.random() * 1000);
        const signMessageRes = await signMessageAsync({
          message: walletConfig.getWalletSignMessage(walletNumber, address)
        });
        checkWalletAccount(address, signMessageRes, walletNumber);
      } else {
        setWalletLoading(true);
        await open();
      }
    } catch (err){
      console.log(err);
    } finally {
      setWalletLoading(false);
    }
  }

  return (
    <>
        {
          mobile === false ?
            <Modal destroyOnClose={true} width={549} zIndex={99999}
                   transitionName={"ant-fade"} centered className={`login-new-modal ${styles.login_model_wrap}`}
                   footer={false} visible={showModal && step !== 1 && step !== 0} onCancel={onCancel}
            >
              {
                step === 2 ? <div style={{height:"685px"}}>
                  <div className={styles.login_model_title}>{t('enterVerificationCode')}</div>
                  <div className={styles.tips_register_tips}>{t('haveSentCodeToEmail')}</div>
                  <div className={styles.tips_register_email}>{email}</div>
                  <div className={styles.login_password_container}>
                    <Form
                      name='basic999'
                      initialValues={{
                        verifyCode:'',
                        password:null,
                        passwordconfirm:null
                      }}
                      autoComplete='off'
                      validateTrigger='onSubmit'
                      onFinish={onFinishRis}
                      onFinishFailed={onFinishFailed}>
                      <div className={`phone-input-wrapper ${styles.phone_input_wrapper} ${styles.vari_code_wrapper}`}>

                        <Form.Item
                          name='verifyCode'
                          className={styles.email_form}
                          rules={[
                            {
                              required: true,
                              message: 'Code is missing',
                            },
                          ]}>
                          <Input
                            placeholder={t('code')}
                            autoComplete='new-verifyCode'
                            className={styles.ant_input}
                          />
                        </Form.Item>
                        <div className={styles.send_again_wrapper}>
                          <div onClick={()=>sendAgainClickGoogle()}  className={ !state.codePending ?  styles.send_again : styles.send_again_dis }  > { sendLoading ? <Spin indicator={antIcon} /> :  state.getCodeTips } </div>
                        </div>
                      </div>

                      <Form.Item shouldUpdate={(prevValues, nextValues) => prevValues.verifyCode !== nextValues.verifyCode}>
                        {
                          ({getFieldValue})=>{
                            let haveValue = getFieldValue("verifyCode") === "";
                            return (
                              <Button
                                type='primary'
                                id='getDeviceId'
                                htmlType='submit'
                                disabled={haveValue}
                                className={`${styles.login_click_btn} ${haveValue ? styles.un_input_color : ""}`}
                                loading={loading}
                                style={{ width: '100%' }}>
                                {t('complete')}
                              </Button>
                            );
                          }
                        }
                      </Form.Item>
                    </Form>
                  </div>

                </div> : null
              }

              {
                step === 3 ? <div>
                  <div className={styles.login_model_title}>Login with wallet</div>
                  <div style={{height:"55px"}}></div>
                  <Button disabled={state.walletLoading} loading={state.metamaskLoading} className={`${styles.wallet_connect_btn_metamask}`} onClick={ metaMaskConnectClick  }>
                    <img className={styles.left_icon} src='https://static-img.igamie.com/static/metamask_icon1.webp'  />
                    <span className={styles.center_name}>MetaMask</span>
                    <img className={styles.right_icon} src='https://static-img.igamie.com/static/metamask_icon2.webp'  />
                  </Button>
                  <Button disabled={state.metamaskLoading} loading={state.walletLoading} className={styles.wallet_connect_btn_walletcon} onClick={ walletConnectClick } >
                    <img className={styles.left_icon} src='https://static-img.igamie.com/static/walletConnect_icon2.png'  />
                    <span className={styles.center_name} >WalletConnect</span>
                    <img className={styles.right_icon} src='https://static-img.igamie.com/static/walletConnect_icon1.png' />
                  </Button>
                  <div style={{height:"50px"}}></div>
                </div> : null
              }

              {
                step === 4 ? <div style={{height:"685px"}} >
                  <div className={styles.login_model_title}>Reset Password</div>
                  {/* <Button onClick={gotoWallectConnect} className={styles.wallet_connect_btn_walletconect}>*/}
                  {/*  <img className={styles.left_icon} src='https://static-img.igamie.com/static/walletConnect_icon2.png'  />*/}
                  {/*  <span className={styles.center_name} >Continue with WalletConnect</span>*/}
                  {/* </Button>*/}
                  {/* <div className={styles.or_txt}>Or</div>*/}
                  <div className={styles.tips_register}>
                    Enter your account verify it's you
                  </div>
                  <div className={styles.account_form}>
                    <div className={styles.login_password_container}>
                      <Form
                        form={form}
                        name='basic'
                        validateTrigger='onSubmit'
                        initialValues={{
                          remember: true,
                        }}
                        onFinish={onFinishGoogle}
                        onFinishFailed={onFinishFailed}>
                        <Form.Item
                          name='account'
                          className={`${styles.email_form} ${styles.email_signup_form}`}
                          rules={[
                            {
                              required: true,
                              message: t('pleaseAccount'),
                            },
                          ]}>
                          <Input autoFocus
                                 className={styles.ant_input}
                                 placeholder={"Your account"}
                          />
                        </Form.Item>

                        <Form.Item shouldUpdate={(prevValues, nextValues) => prevValues.account !== nextValues.account}>
                          {
                            ({getFieldValue}) =>{
                              let haveValue = getFieldValue("account") === "";
                              return  (
                                <Button
                                  type='primary'
                                  htmlType='submit'
                                  loading={state.loading}
                                  disabled={haveValue}
                                  className={`${styles.login_click_btn} ${haveValue ? styles.un_input_color : ""}`}
                                  style={{ width: '100%' }}>
                                  {t('next')}
                                </Button>
                              );
                            }
                          }
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </div> : null
              }

              {
                step === 5 ? <div style={{height:"685px"}}>
                  <div className={styles.login_model_title}>{t('enterVerificationCode')}</div>
                  <div style={{height:"10px"}}></div>
                  <div className={styles.tips_register_tips}>{email.includes("@") ? t('haveSentCodeToEmail') : t('haveSentCodeToPhone')}:</div>
                  <div className={styles.tips_register_email}>{email}</div>
                  <div style={{height:"30px"}}></div>
                  <div className={styles.login_password_container}>
                    <Form
                      name='basic999'
                      initialValues={{
                        verifyCode:'',
                        password:null,
                        passwordconfirm:null
                      }}
                      autoComplete='off'
                      validateTrigger='onSubmit'
                      onFinish={onFinishForgetPasword}
                      onFinishFailed={onFinishFailed}>
                      <div className={styles.vari_code_wrapper}>
                        <Form.Item
                          name='verifyCode'
                          className={styles.email_form}
                          rules={[
                            {
                              required: true,
                              message: 'Code is missing',
                            },
                          ]}>
                          <Input
                            placeholder={t('code')}
                            autoComplete='new-verifyCode'
                            className={styles.ant_input}
                          />
                        </Form.Item>
                        <div className={styles.send_again_wrapper}>
                          <div onClick={()=>sendAgainClickGoogle(null, true)}  className={ !state.codePending ?  styles.send_again : styles.send_again_dis }  > { sendLoading ? <Spin indicator={antIcon} /> :  state.getCodeTips } </div>
                        </div>

                        <Form.Item
                          name='password'
                          // hasFeedback
                          className={`${styles.password_form} ${styles.email_form}`}
                          rules={[
                            {
                              required: true,
                              message: t('password'),
                            },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                if (
                                  /^^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z0-9!@#$?.,%^&*_]{8,16}$/.test(
                                    value
                                  )
                                ) {
                                  return Promise.resolve();
                                }

                                return Promise.reject(
                                  t('passwordVerification')
                                  // 'Your password must be between 8-16 charactors long and include at least one uppercase letter(A-z) or one lower caseletter(a-z) or special characters'
                                );
                              },
                            }),
                          ]}>
                          <Input.Password
                            placeholder={t('password')}
                            autoComplete='new-password'
                            className={styles.ant_input_password}
                            iconRender = { (visible) => ( visible ? <img style={{ width:'18px' }} src={'http://static-img.igamie.com/static/eyes_can.png'}  />  : <img style={{ width:'18px' }} src='http://static-img.igamie.com/static/eyes_no.png' />   ) }
                            // prefix={<img className="ic-pwd" src={IcPwd} />}
                          />
                        </Form.Item>
                        <Form.Item
                          name='passwordconfirm'
                          className={styles.password_form}
                          rules={[
                            {
                              required: true,
                              message: t('confirmPassword'),
                            },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                  return Promise.resolve();
                                }

                                return Promise.reject(
                                  //  'The two passwords that you entered do not match!'
                                  t('passwordConfirmRegx')
                                );
                              },
                            }),
                          ]}>
                          <Input.Password
                            placeholder={t('confirmPassword')}
                            className={styles.ant_input_password}
                            iconRender = { (visible) => ( visible ? <img style={{ width:'18px' }} src={'http://static-img.igamie.com/static/eyes_can.png'}  />  : <img style={{ width:'18px' }} src='http://static-img.igamie.com/static/eyes_no.png' />   ) }
                            // prefix={<img className="ic-pwd" src={IcPwd} />}
                          />
                        </Form.Item>
                      </div>

                      <Form.Item shouldUpdate={(prevValues, nextValues) => prevValues !== nextValues}>
                        {
                          ({getFieldValue})=>{
                            let haveValue1 = getFieldValue("verifyCode") === "";
                            let haveValue2 = getFieldValue("password") === "";
                            let haveValue3 = getFieldValue("passwordconfirm") === "";

                            let haveValue = haveValue1 || haveValue2 || haveValue3;

                            return (
                              <Button
                                type='primary'
                                id='getDeviceId'
                                loading={state.loading}
                                htmlType='submit'
                                disabled={haveValue}
                                className={`${styles.login_click_btn} ${haveValue ? styles.un_input_color : ""}`}
                                style={{ width: '100%' }}>
                                {t('complete')}
                              </Button>
                            )
                          }
                        }

                      </Form.Item>
                    </Form>
                  </div>

                </div> : null
              }

              {
                step === 6 ? <div style={{height:"685px"}}>
                  <div className={styles.login_model_title}>Wallet Connected!</div>
                  <div className={styles.bind_account}>Bind existing account</div>
                  <div className={styles.bind_account_des}>After binding,you may use the email adress to sign in and retreve password</div>
                  <div className={styles.account_form}>
                    <div className={styles.login_password_container}>
                      <Form
                        name='basic999'
                        initialValues={{
                          verifyCode:'',
                          password:null,
                          passwordconfirm:null,
                          account:null,
                        }}
                        autoComplete='off'
                        validateTrigger='onSubmit'
                        onFinish={onFinishEmailBind}
                        onFinishFailed={onFinishFailed}>
                        <div className={styles.vari_code_wrapper}>
                          <Form.Item
                            name='account'
                            className={styles.email_form}
                            rules={[
                              {
                                required: true,
                                message: t('AccountMissing'),
                                // message:'2121'
                              },
                            ]}>
                            <Input
                              autoComplete='false'
                              className={styles.ant_input}
                              placeholder={t('email')}
                            />
                          </Form.Item>

                          <Form.Item
                            name='password'
                            className={styles.email_form}
                            rules={[
                              {
                                required: true,
                                message: t('pleasePassword'),
                              },
                              ({ getFieldValue }) => ({
                                validator(rule, value) {
                                  if (
                                    /^^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z0-9!@#$?.,%^&*_]{8,16}$/.test(
                                      value
                                    )
                                  ) {
                                    return Promise.resolve();
                                  }

                                  return Promise.reject(
                                    t('passwordVerification')
                                    // 'Your password must be between 8-16 charactors long and include at least one uppercase letter(A-z) or one lower caseletter(a-z) or special characters'
                                  );
                                },
                              }),
                            ]}>
                            <Input.Password
                              autoComplete='false'
                              className={styles.ant_input_password}
                              placeholder={t('passwordPlacehold')}
                              iconRender = { (visible) => ( visible ? <img style={{ width:'18px' }} src={'http://static-img.igamie.com/static/eyes_can.png'}  />  : <img style={{ width:'18px' }} src='http://static-img.igamie.com/static/eyes_no.png' />   ) }
                            />
                          </Form.Item>

                          <div className={styles.forget_button}>
                            <div onClick={forgetPwdClick}>{t('loginForgetPassword')}</div>
                          </div>

                        </div>

                        <Form.Item>
                          <Button
                            type='primary'
                            id='getDeviceId'
                            htmlType='submit'
                            loading={state.loading}
                            className={styles.login_click_btn}
                            style={{ width: '100%' }}>
                            Bind account
                          </Button>
                        </Form.Item>
                        <div onClick={ SkipClick } className={styles.skip_button}>{t('loginBtnSkip')}</div>
                      </Form>
                    </div>
                    <div className={styles.register_container}>
                      {/* <div className={styles.agree}>
                        <div className={styles.by_sign_up}>
                          <span onClick={() => { setState({ ...state, isCheck: !state.isCheck }); }}  className={ state.isCheck ? 'checkbox-new' : 'checkbox-no-new' } ></span>
                          {t('bySigningUp')}
                        <a className={styles.link_style}  href='https://igamie.s3.ap-southeast-1.amazonaws.com/config/privacy.html' > {t('privacyPolicy')} </a>
                        &
                        <a className={styles.link_style}  href='https://igamie.s3.ap-southeast-1.amazonaws.com/config/terms.html' > {t('termsConditions')} </a>
                       </div>
                    </div> */}
                    </div>
                  </div>
                </div> : null
              }

              {
                step === 7 ? <div style={{height:"685px"}}>
                  <div className={styles.login_model_title}>{t('loginSignSuccessText')}</div>
                  <div style={{height:"90px"}}></div>
                  <div className={styles.bind_account_des}>{t('loginWelcomeSettingName')}</div>
                  <div className={styles.account_form}>
                    <div className={styles.login_password_container}>
                      <Form
                        name='basic999'
                        initialValues={{
                          account:null,
                        }}
                        autoComplete='off'
                        validateTrigger='onSubmit'
                        onFinish={onFinishNickname}
                        onFinishFailed={onFinishFailed}>
                        <div className={styles.vari_code_wrapper}>
                          <Form.Item
                            name='nickName'
                            className={styles.email_form}
                            rules={[
                              {
                                required: true,
                                message: "The nickname is missing",
                                // message:'2121'
                              },
                            ]}>
                            <Input
                              autoComplete='false'
                              className={styles.ant_input}
                              placeholder={t('loginWelcomeNickname')}
                            />
                          </Form.Item>
                        </div>

                        <Form.Item shouldUpdate={(prevValues, nextValues) => prevValues.nickName !== nextValues.nickName}>
                          {
                            ({getFieldValue}) =>{
                              let haveValue = getFieldValue("nickName") === "";
                              return  (
                                <Button
                                  type='primary'
                                  id='getDeviceId'
                                  htmlType='submit'
                                  disabled={haveValue}
                                  loading={state.loading}
                                  className={`${styles.login_click_btn} ${styles.margin_top_20} ${haveValue ? styles.un_input_color : ""}`}
                                  style={{ width: '100%' }}>
                                  {t('loginBtnStartNow')}
                                </Button>
                              );
                            }
                          }
                        </Form.Item>
                        <div style={{height:"30px"}}></div>
                        <div onClick={ SkipClickNickname } className={styles.skip_button}>{t('loginBtnSkip')}</div>
                      </Form>
                    </div>
                  </div>
                </div> : null
              }
              {
                step === 8 ? <div style={{height:"685px"}}>
                  <div className={styles.login_model_title}>{t('signUp')}</div>
                  <Form
                    name='basic'
                    initialValues={{
                      remember: true,
                    }}
                    form={singUpForm}
                    onFinish={onFinishSingUp}
                    onFinishFailed={onFinishFailed}
                    validateTrigger='onSubmit'>
                    <Form.Item
                      name='account'
                      className={styles.email_form}
                      style={{textAlign:"left"}}
                      rules={[
                        {
                          required: true,
                          message: t('AccountMissing'),
                          // message:'2121'
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (
                              /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}(,[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,6})*$/.test(
                                value
                              ) || /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(value)
                            ) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              'Sorry, only letters (a-z) numbers (0-9), and priods(.) are allowed.'
                              // 'Your password must be between 8-16 charactors long and include at least one uppercase letter(A-z) or one lower caseletter(a-z) or special characters'
                            );
                          },
                        })
                      ]}>
                      <Input
                        autoComplete='false'
                        className={styles.ant_input}
                        placeholder={t('emailPhone')}
                        // placeholder={ language18 === 'ja' ? `メールアドレス / 電話番号` : t('emailPhone') }
                      />
                    </Form.Item>
                    <Form.Item
                      name='password'
                      className={styles.password_form}
                      style={{textAlign:"left"}}
                      rules={[
                        {
                          required: true,
                          message: t('pleasePassword'),
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (
                              /^^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z0-9!@#$?.,%^&*_]{8,16}$/.test(
                                value
                              )
                            ) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              t('passwordVerification')
                              // 'Your password must be between 8-16 charactors long and include at least one uppercase letter(A-z) or one lower caseletter(a-z) or special characters'
                            );
                          },
                        }),
                      ]}>
                      <Input.Password
                        autoComplete='false'
                        className={styles.ant_input_password}
                        placeholder={t('passwordPlacehold')}
                        // placeholder={ language18 === 'ja' ? `パスワード` : t('passwordPlacehold')}
                        iconRender = { (visible) => ( visible ? <img style={{ width:'18px' }} src={'http://static-img.igamie.com/static/eyes_can.png'}  />  : <img style={{ width:'18px' }} src='http://static-img.igamie.com/static/eyes_no.png' />   ) }
                      />
                    </Form.Item>
                    <Form.Item
                      name='verifyCode'
                      className={styles.email_form}
                      rules={[
                        {
                          required: true,
                          message: 'Code is missing',
                        },
                      ]}>
                      <Input
                        placeholder={t('code')}
                        autoComplete='new-verifyCode'
                        suffix={<div onClick={singUpSendCode} className={!codePending ? styles.send_again : styles.send_again_dis}>{sendLoading ? <Spin indicator={antIcon} /> : timeNum}</div>}
                        className={styles.ant_input}
                      />
                    </Form.Item>
                    <div className={styles.continue_wrap}>
                      <div></div>
                      <Button
                        type='primary'
                        htmlType='submit'
                        loading={state.loading}
                        className={ `${styles.login_click_btn} ${styles.login_click_btn_login}` }>
                        {t('continue')}
                      </Button>
                    </div>

                  </Form>
                  <div className={styles.or_txt}>Or</div>
                  {/* <div className={styles.social_login}>*/}

                  {/* <FacebookLogin*/}
                  {/*   appId={facebookAppId}*/}
                  {/*   callback={responseFacebook}*/}
                  {/*   render={(renderProps) => (*/}
                  {/*     <Button*/}
                  {/*       loading={state.fbLoading}*/}
                  {/*       className={styles.fb_img_wrapper}*/}
                  {/*       onClick={ () => {  if (! state.isCheck) { return message.warning(`*/}
                  {/*               To proceed, please agree to the Terms of Service and Privacy Policy.*/}
                  {/*               `) } facebookClick(renderProps.onClick); }}>*/}
                  {/*       <span></span>*/}
                  {/*       /!* <img src={FbLoginIcon} className='fb-login-icon'  /> *!/*/}
                  {/*       /!* <span>{t('loginWithFacebook')}</span> *!/*/}
                  {/*     </Button>*/}
                  {/*   )}*/}
                  {/* />*/}
                  {/* <div onClick={ twitterLogin } className={styles.twitter_login}></div>*/}

                  {/* </div>*/}
                  <GoogleLoginBtn onCallBack={responseGoogle} />
                  <div onClick={gotoWallectConnect} className={styles.wallet_connect_btn_walletconect_text}>{t('loginContinueWithWallet')}</div>
                  {/* <Button onClick={gotoWallectConnect} className={styles.wallet_connect_btn_walletconect}>*/}
                  {/*  <img className={styles.left_icon} src='https://static-img.igamie.com/static/walletConnect_icon2.png'  />*/}
                  {/*  <span className={styles.center_name} >{t('loginContinueWithWallet')}</span>*/}
                  {/* </Button>*/}
                  <div className={`${styles.register_container} ${styles.tc_wrapper}`}>
                    <div className={styles.agree}>
                      <div className={styles.by_sign_up}>
                        <span onClick={() => { setState({ ...state, isCheck: !state.isCheck }); }} style={{width:"22px", height:"20px"}}  className={ state.isCheck ? 'checkbox-new' : 'checkbox-no-new' } ></span>
                        <span>
                                {t('bySigningUp')}
                          <a className={styles.link_style}  href='https://imomo.ai/imomo_privacy_policy.html' > {t('privacyPolicy')}</a>  &

                                <a className={styles.link_style}  href='https://imomo.ai/imomo_terms.html' > {t('termsConditions')}</a>

               </span>
                      </div>
                    </div>
                  </div>
                </div> : null
              }
            </Modal> :
            <>
              {
                showModal ? <div onClick={(e)=>e.stopPropagation()} className={`${styles.login_model_mobile} ${styles.login_model_wrap} login-modal-mobile`}>
                  <div className={styles.close_wrap}>
                    <div onClick={onCancel} className={styles.close_icon}></div>
                  </div>
                  {
                    step === 1 ? <div>
                      <div className={styles.login_model_title}>{t('login')}/{t('signUp')}</div>
                      <Form
                        name='basic'
                        initialValues={{
                          remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        validateTrigger='onSubmit'>
                        <Form.Item
                          name='account'
                          className={styles.email_form}
                          style={{textAlign:"left"}}
                          rules={[
                            {
                              required: true,
                              message: t('AccountMissing'),
                              // message:'2121'
                            },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                if (
                                  /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}(,[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,6})*$/.test(
                                    value
                                  )
                                ) {
                                  return Promise.resolve();
                                }

                                return Promise.reject(
                                  'Sorry, only letters (a-z) numbers (0-9), and priods(.) are allowed.'
                                  // 'Your password must be between 8-16 charactors long and include at least one uppercase letter(A-z) or one lower caseletter(a-z) or special characters'
                                );
                              },
                            })
                          ]}>
                          <Input
                            autoComplete='false'
                            className={styles.ant_input}
                            placeholder={t('emailPhone')}
                            // placeholder={ language18 === 'ja' ? `メールアドレス / 電話番号` : t('emailPhone') }
                          />
                        </Form.Item>
                        <Form.Item
                          name='password'
                          className={styles.password_form}
                          style={{textAlign:"left"}}
                          rules={[
                            {
                              required: true,
                              message: t('pleasePassword'),
                            },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                if (
                                  /^^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z0-9!@#$?.,%^&*_]{8,16}$/.test(
                                    value
                                  )
                                ) {
                                  return Promise.resolve();
                                }

                                return Promise.reject(
                                  t('passwordVerification')
                                  // 'Your password must be between 8-16 charactors long and include at least one uppercase letter(A-z) or one lower caseletter(a-z) or special characters'
                                );
                              },
                            }),
                          ]}>
                          <Input.Password
                            autoComplete='false'
                            className={styles.ant_input_password}
                            placeholder={t('passwordPlacehold')}
                            // placeholder={ language18 === 'ja' ? `パスワード` : t('passwordPlacehold')}
                            iconRender = { (visible) => ( visible ? <img style={{ width:'18px' }} src={'http://static-img.igamie.com/static/eyes_can.png'}  />  : <img style={{ width:'18px' }} src='http://static-img.igamie.com/static/eyes_no.png' />   ) }
                          />
                        </Form.Item>

                        <div className={styles.continue_wrap}>
                          <div className={styles.forget_password}>
                            <span onClick={()=>setStep(4)}>{t('loginForgetPassword')}</span>
                          </div>
                          <Button
                            type='primary'
                            htmlType='submit'
                            loading={state.loading}
                            className={ `${styles.login_click_btn} ${styles.login_click_btn_login}` }>
                            {t('login')}
                          </Button>
                        </div>
                      </Form>
                      <div className={styles.or_txt}>Or</div>
                      <div className={styles.social_login}>
                        <GoogleLoginBtn onCallBack={responseGoogle} />
                        {/* <GoogleLogin*/}
                        {/*  clientId={googleAppId}*/}
                        {/*  buttonText='Login'*/}
                        {/*  onSuccess={responseGoogle}*/}
                        {/*  onFailure={responseGoogleError}*/}
                        {/*  cookiePolicy={'single_host_origin'}*/}
                        {/*  render={(renderProps) => (*/}
                        {/*    <Button className={styles.google_img_wrapper}*/}
                        {/*            loading={state.googleLoading}*/}
                        {/*            onClick={ () => { if (! state.isCheck) { return message.warning(`*/}
                        {/*          To proceed, please agree to the Terms of Service and Privacy Policy.*/}
                        {/*          `) } renderProps.onClick()  } }*/}
                        {/*            disabled={renderProps.disabled}>*/}
                        {/*      <span></span>*/}
                        {/*      /!* <img src={GoogleLoginIcon}  className='google-login-icon' /> *!/*/}
                        {/*      /!* <span> {t('loginWithGoogle')}</span> *!/*/}
                        {/*    </Button>*/}
                        {/*  )}*/}
                        {/* />*/}
                        {/* <FacebookLogin*/}
                        {/*  appId={facebookAppId}*/}
                        {/*  callback={responseFacebook}*/}
                        {/*  render={(renderProps) => (*/}
                        {/*    <Button*/}
                        {/*      loading={state.fbLoading}*/}
                        {/*      className={styles.fb_img_wrapper}*/}
                        {/*      onClick={ () => {  if (! state.isCheck) { return message.warning(`*/}
                        {/*          To proceed, please agree to the Terms of Service and Privacy Policy.*/}
                        {/*          `) } facebookClick(renderProps.onClick); }}>*/}
                        {/*      <span></span>*/}
                        {/*      /!* <img src={FbLoginIcon} className='fb-login-icon'  /> *!/*/}
                        {/*      /!* <span>{t('loginWithFacebook')}</span> *!/*/}
                        {/*    </Button>*/}
                        {/*  )}*/}
                        {/* />*/}
                        {/* <div onClick={ twitterLogin } className={styles.twitter_login}></div>*/}

                      </div>
                      <div onClick={gotoWallectConnect} className={styles.wallet_connect_btn_walletconect_text}>{t('loginContinueWithWallet')}</div>
                      {/* <Button onClick={gotoWallectConnect} className={styles.wallet_connect_btn_walletconect}>*/}
                      {/* <img className={styles.left_icon} src='https://static-img.igamie.com/static/walletConnect_icon2.png'  />*/}
                      {/* <span className={styles.center_name} >{t('loginContinueWithWallet')}</span>*/}
                      {/* </Button>*/}
                      {/* <div className={styles.or_txt}>Or</div>*/}
                      {/* <div onClick={gotoWallectConnect} className={styles.wallet_connect_btn_walletconect_text}>{t('loginContinueWithWallet')}</div>*/}
                      {/* <Button onClick={gotoWallectConnect} className={styles.wallet_connect_btn_walletconect}>*/}
                      {/*  <img className={styles.left_icon} src='https://static-img.igamie.com/static/walletConnect_icon2.png'  />*/}
                      {/*  <span className={styles.center_name} >{t('loginContinueWithWallet')}</span>*/}
                      {/* </Button>*/}
                      <div className={`${styles.register_container} ${styles.tc_wrapper}`}>
                        <div className={styles.agree}>
                          <div className={styles.by_sign_up}>
                            <span onClick={() => { setState({ ...state, isCheck: !state.isCheck }); }} style={{width:"22px", height:"20px"}}  className={ state.isCheck ? 'checkbox-new' : 'checkbox-no-new' } ></span>
                            <span>
                                {t('bySigningUp')}
                              <a className={styles.link_style}  href='https://imomo.ai/imomo_privacy_policy.html' > {t('privacyPolicy')}</a>  &

                                <a className={styles.link_style}  href='https://imomo.ai/imomo_terms.html' > {t('termsConditions')}</a>

               </span>
                          </div>
                        </div>
                      </div>
                    </div> : null
                  }

                  {
                    step === 2 ? <div style={{height:"685px"}}>
                      <div className={styles.login_model_title}>{t('enterVerificationCode')}</div>
                      <div className={styles.tips_register_tips}>{t('haveSentCodeToEmail')}</div>
                      <div className={styles.tips_register_email}>{email}</div>
                      <div className={styles.login_password_container}>
                        <Form
                          name='basic999'
                          initialValues={{
                            verifyCode:'',
                            password:null,
                            passwordconfirm:null
                          }}
                          autoComplete='off'
                          validateTrigger='onSubmit'
                          onFinish={onFinishRis}
                          onFinishFailed={onFinishFailed}>
                          <div className={`phone-input-wrapper ${styles.phone_input_wrapper} ${styles.vari_code_wrapper}`}>

                            <Form.Item
                              name='verifyCode'
                              className={styles.email_form}
                              rules={[
                                {
                                  required: true,
                                  message: 'Code is missing',
                                },
                              ]}>
                              <Input
                                placeholder={t('code')}
                                autoComplete='new-verifyCode'
                                className={styles.ant_input}
                              />
                            </Form.Item>
                            <div className={styles.send_again_wrapper}>
                              <div onClick={()=>sendAgainClickGoogle()}  className={ !state.codePending ?  styles.send_again : styles.send_again_dis }  > { sendLoading ? <Spin indicator={antIcon} /> :  state.getCodeTips } </div>
                            </div>
                          </div>

                          <Form.Item shouldUpdate={(prevValues, nextValues) => prevValues.verifyCode !== nextValues.verifyCode}>
                            {
                              ({getFieldValue})=>{
                                let haveValue = getFieldValue("verifyCode") === "";
                                return (
                                  <Button
                                    type='primary'
                                    id='getDeviceId'
                                    htmlType='submit'
                                    disabled={haveValue}
                                    className={`${styles.login_click_btn} ${haveValue ? styles.un_input_color : ""}`}
                                    loading={loading}
                                    style={{ width: '100%' }}>
                                    {t('complete')}
                                  </Button>
                                );
                              }
                            }
                          </Form.Item>
                        </Form>
                      </div>

                    </div> : null
                  }

                  {
                    step === 3 ? <div>
                      <div className={styles.login_model_title}>Login with wallet</div>
                      <div style={{height:"55px"}}></div>
                      <Button disabled={state.walletLoading} loading={state.metamaskLoading} className={`${styles.wallet_connect_btn_metamask}`} onClick={ metaMaskConnectClick  }>
                        <img className={styles.left_icon} src='https://static-img.igamie.com/static/metamask_icon1.webp'  />
                        <span className={styles.center_name}>MetaMask</span>
                        <img className={styles.right_icon} src='https://static-img.igamie.com/static/metamask_icon2.webp'  />
                      </Button>
                      <Button disabled={state.metamaskLoading} loading={state.walletLoading} className={styles.wallet_connect_btn_walletcon} onClick={ walletConnectClick } >
                        <img className={styles.left_icon} src='https://static-img.igamie.com/static/walletConnect_icon2.png'  />
                        <span className={styles.center_name} >WalletConnect</span>
                        <img className={styles.right_icon} src='https://static-img.igamie.com/static/walletConnect_icon1.png' />
                      </Button>
                      <div style={{height:"50px"}}></div>
                    </div> : null
                  }

                  {
                    step === 4 ? <div style={{height:"685px"}} >
                      <div className={styles.login_model_title}>Reset Password</div>
                      {/* <Button onClick={gotoWallectConnect} className={styles.wallet_connect_btn_walletconect}>*/}
                      {/*  <img className={styles.left_icon} src='https://static-img.igamie.com/static/walletConnect_icon2.png'  />*/}
                      {/*  <span className={styles.center_name} >Continue with WalletConnect</span>*/}
                      {/* </Button>*/}
                      {/* <div className={styles.or_txt}>Or</div>*/}
                      <div className={styles.tips_register}>
                        Enter your account verify it's you
                      </div>
                      <div className={styles.account_form}>
                        <div className={styles.login_password_container}>
                          <Form
                            form={form}
                            name='basic'
                            validateTrigger='onSubmit'
                            initialValues={{
                              remember: true,
                            }}
                            onFinish={onFinishGoogle}
                            onFinishFailed={onFinishFailed}>
                            <Form.Item
                              name='account'
                              className={`${styles.email_form} ${styles.email_signup_form}`}
                              rules={[
                                {
                                  required: true,
                                  message: t('pleaseAccount'),
                                },
                              ]}>
                              <Input autoFocus
                                     className={styles.ant_input}
                                     placeholder={"Your account"}
                              />
                            </Form.Item>

                            <Form.Item shouldUpdate={(prevValues, nextValues) => prevValues.account !== nextValues.account}>
                              {
                                ({getFieldValue}) =>{
                                  let haveValue = getFieldValue("account") === "";
                                  return  (
                                    <Button
                                      type='primary'
                                      htmlType='submit'
                                      loading={state.loading}
                                      disabled={haveValue}
                                      className={`${styles.login_click_btn} ${haveValue ? styles.un_input_color : ""}`}
                                      style={{ width: '100%' }}>
                                      {t('next')}
                                    </Button>
                                  );
                                }
                              }
                            </Form.Item>
                          </Form>
                        </div>
                      </div>
                    </div> : null
                  }

                  {
                    step === 5 ? <div style={{height:"685px"}}>
                      <div className={styles.login_model_title}>{t('enterVerificationCode')}</div>
                      <div style={{height:"10px"}}></div>
                      <div className={styles.tips_register_tips}>{email.includes("@") ? t('haveSentCodeToEmail') : t('haveSentCodeToPhone')}:</div>
                      <div className={styles.tips_register_email}>{email}</div>
                      <div style={{height:"30px"}}></div>
                      <div className={styles.login_password_container}>
                        <Form
                          name='basic999'
                          initialValues={{
                            verifyCode:'',
                            password:null,
                            passwordconfirm:null
                          }}
                          autoComplete='off'
                          validateTrigger='onSubmit'
                          onFinish={onFinishForgetPasword}
                          onFinishFailed={onFinishFailed}>
                          <div className={styles.vari_code_wrapper}>
                            <Form.Item
                              name='verifyCode'
                              className={styles.email_form}
                              rules={[
                                {
                                  required: true,
                                  message: 'Code is missing',
                                },
                              ]}>
                              <Input
                                placeholder={t('code')}
                                autoComplete='new-verifyCode'
                                className={styles.ant_input}
                              />
                            </Form.Item>
                            <div className={styles.send_again_wrapper}>
                              <div onClick={()=>sendAgainClickGoogle(null, true)}  className={ !state.codePending ?  styles.send_again : styles.send_again_dis }  > { sendLoading ? <Spin indicator={antIcon} /> :  state.getCodeTips } </div>
                            </div>

                            <Form.Item
                              name='password'
                              // hasFeedback
                              className={`${styles.password_form} ${styles.email_form}`}
                              rules={[
                                {
                                  required: true,
                                  message: t('password'),
                                },
                                ({ getFieldValue }) => ({
                                  validator(rule, value) {
                                    if (
                                      /^^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z0-9!@#$?.,%^&*_]{8,16}$/.test(
                                        value
                                      )
                                    ) {
                                      return Promise.resolve();
                                    }

                                    return Promise.reject(
                                      t('passwordVerification')
                                      // 'Your password must be between 8-16 charactors long and include at least one uppercase letter(A-z) or one lower caseletter(a-z) or special characters'
                                    );
                                  },
                                }),
                              ]}>
                              <Input.Password
                                placeholder={t('password')}
                                autoComplete='new-password'
                                className={styles.ant_input_password}
                                iconRender = { (visible) => ( visible ? <img style={{ width:'18px' }} src={'http://static-img.igamie.com/static/eyes_can.png'}  />  : <img style={{ width:'18px' }} src='http://static-img.igamie.com/static/eyes_no.png' />   ) }
                                // prefix={<img className="ic-pwd" src={IcPwd} />}
                              />
                            </Form.Item>
                            <Form.Item
                              name='passwordconfirm'
                              className={styles.password_form}
                              rules={[
                                {
                                  required: true,
                                  message: t('confirmPassword'),
                                },
                                ({ getFieldValue }) => ({
                                  validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                      return Promise.resolve();
                                    }

                                    return Promise.reject(
                                      //  'The two passwords that you entered do not match!'
                                      t('passwordConfirmRegx')
                                    );
                                  },
                                }),
                              ]}>
                              <Input.Password
                                placeholder={t('confirmPassword')}
                                className={styles.ant_input_password}
                                iconRender = { (visible) => ( visible ? <img style={{ width:'18px' }} src={'http://static-img.igamie.com/static/eyes_can.png'}  />  : <img style={{ width:'18px' }} src='http://static-img.igamie.com/static/eyes_no.png' />   ) }
                                // prefix={<img className="ic-pwd" src={IcPwd} />}
                              />
                            </Form.Item>
                          </div>

                          <Form.Item shouldUpdate={(prevValues, nextValues) => prevValues !== nextValues}>
                            {
                              ({getFieldValue})=>{
                                let haveValue1 = getFieldValue("verifyCode") === "";
                                let haveValue2 = getFieldValue("password") === "";
                                let haveValue3 = getFieldValue("passwordconfirm") === "";

                                let haveValue = haveValue1 || haveValue2 || haveValue3;

                                return (
                                  <Button
                                    type='primary'
                                    id='getDeviceId'
                                    loading={state.loading}
                                    htmlType='submit'
                                    disabled={haveValue}
                                    className={`${styles.login_click_btn} ${haveValue ? styles.un_input_color : ""}`}
                                    style={{ width: '100%' }}>
                                    {t('complete')}
                                  </Button>
                                )
                              }
                            }

                          </Form.Item>
                        </Form>
                      </div>

                    </div> : null
                  }

                  {
                    step === 6 ? <div style={{height:"685px"}}>
                      <div className={styles.login_model_title}>Wallet Connected!</div>
                      <div className={styles.bind_account}>Bind existing account</div>
                      <div className={styles.bind_account_des}>After binding,you may use the email adress to sign in and retreve password</div>
                      <div className={styles.account_form}>
                        <div className={styles.login_password_container}>
                          <Form
                            name='basic999'
                            initialValues={{
                              verifyCode:'',
                              password:null,
                              passwordconfirm:null,
                              account:null,
                            }}
                            autoComplete='off'
                            validateTrigger='onSubmit'
                            onFinish={onFinishEmailBind}
                            onFinishFailed={onFinishFailed}>
                            <div className={styles.vari_code_wrapper}>
                              <Form.Item
                                name='account'
                                className={styles.email_form}
                                rules={[
                                  {
                                    required: true,
                                    message: t('AccountMissing'),
                                    // message:'2121'
                                  },
                                ]}>
                                <Input
                                  autoComplete='false'
                                  className={styles.ant_input}
                                  placeholder={t('email')}
                                />
                              </Form.Item>

                              <Form.Item
                                name='password'
                                className={styles.email_form}
                                rules={[
                                  {
                                    required: true,
                                    message: t('pleasePassword'),
                                  },
                                  ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                      if (
                                        /^^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z0-9!@#$?.,%^&*_]{8,16}$/.test(
                                          value
                                        )
                                      ) {
                                        return Promise.resolve();
                                      }

                                      return Promise.reject(
                                        t('passwordVerification')
                                        // 'Your password must be between 8-16 charactors long and include at least one uppercase letter(A-z) or one lower caseletter(a-z) or special characters'
                                      );
                                    },
                                  }),
                                ]}>
                                <Input.Password
                                  autoComplete='false'
                                  className={styles.ant_input_password}
                                  placeholder={t('passwordPlacehold')}
                                  iconRender = { (visible) => ( visible ? <img style={{ width:'18px' }} src={'http://static-img.igamie.com/static/eyes_can.png'}  />  : <img style={{ width:'18px' }} src='http://static-img.igamie.com/static/eyes_no.png' />   ) }
                                />
                              </Form.Item>

                              <div className={styles.forget_button}>
                                <div onClick={forgetPwdClick}>{t('loginForgetPassword')}</div>
                              </div>

                            </div>

                            <Form.Item>
                              <Button
                                type='primary'
                                id='getDeviceId'
                                htmlType='submit'
                                loading={state.loading}
                                className={styles.login_click_btn}
                                style={{ width: '100%' }}>
                                Bind account
                              </Button>
                            </Form.Item>
                            <div onClick={ SkipClick } className={styles.skip_button}>{t('loginBtnSkip')}</div>
                          </Form>
                        </div>
                        <div className={styles.register_container}>
                          {/* <div className={styles.agree}>
                        <div className={styles.by_sign_up}>
                          <span onClick={() => { setState({ ...state, isCheck: !state.isCheck }); }}  className={ state.isCheck ? 'checkbox-new' : 'checkbox-no-new' } ></span>
                          {t('bySigningUp')}
                        <a className={styles.link_style}  href='https://igamie.s3.ap-southeast-1.amazonaws.com/config/privacy.html' > {t('privacyPolicy')} </a>
                        &
                        <a className={styles.link_style}  href='https://igamie.s3.ap-southeast-1.amazonaws.com/config/terms.html' > {t('termsConditions')} </a>
                       </div>
                    </div> */}
                        </div>
                      </div>
                    </div> : null
                  }

                  {
                    step === 7 ? <div style={{height:"685px"}}>
                      <div className={styles.login_model_title}>{t('loginSignSuccessText')}</div>
                      <div style={{height:"90px"}}></div>
                      <div className={styles.bind_account_des}>{t('welcomeStart')}</div>
                      <div className={styles.account_form}>
                        <div className={styles.login_password_container}>
                          <Form
                            name='basic999'
                            initialValues={{
                              account:null,
                            }}
                            autoComplete='off'
                            validateTrigger='onSubmit'
                            onFinish={onFinishNickname}
                            onFinishFailed={onFinishFailed}>
                            <div className={styles.vari_code_wrapper}>
                              <Form.Item
                                name='nickName'
                                className={styles.email_form}
                                rules={[
                                  {
                                    required: true,
                                    message: "The nickname is missing",
                                    // message:'2121'
                                  },
                                ]}>
                                <Input
                                  autoComplete='false'
                                  className={styles.ant_input}
                                  placeholder={t('loginWelcomeNickname')}
                                />
                              </Form.Item>
                            </div>

                            <Form.Item shouldUpdate={(prevValues, nextValues) => prevValues.nickName !== nextValues.nickName}>
                              {
                                ({getFieldValue}) =>{
                                  let haveValue = getFieldValue("nickName") === "";
                                  return  (
                                    <Button
                                      type='primary'
                                      id='getDeviceId'
                                      htmlType='submit'
                                      disabled={haveValue}
                                      loading={state.loading}
                                      className={`${styles.login_click_btn} ${styles.margin_top_20} ${haveValue ? styles.un_input_color : ""}`}
                                      style={{ width: '100%' }}>
                                      {t('loginBtnStartNow')}
                                    </Button>
                                  );
                                }
                              }
                            </Form.Item>
                            <div style={{height:"30px"}}></div>
                            <div onClick={ SkipClickNickname } className={styles.skip_button}>{t('loginBtnSkip')}</div>
                          </Form>
                        </div>
                      </div>
                    </div> : null
                  }

                  {
                    step === 8 ? <div style={{height:"685px"}}>
                      <div className={styles.login_model_title}>{t('signUp')}</div>
                      <Form
                        name='basic'
                        initialValues={{
                          remember: true,
                        }}
                        form={singUpForm}
                        onFinish={onFinishSingUp}
                        onFinishFailed={onFinishFailed}
                        validateTrigger='onSubmit'>
                        <Form.Item
                          name='account'
                          className={styles.email_form}
                          style={{textAlign:"left"}}
                          rules={[
                            {
                              required: true,
                              message: t('AccountMissing'),
                              // message:'2121'
                            },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                if (
                                  /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}(,[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,6})*$/.test(
                                    value
                                  ) || /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(value)
                                ) {
                                  return Promise.resolve();
                                }

                                return Promise.reject(
                                  'Sorry, only letters (a-z) numbers (0-9), and priods(.) are allowed.'
                                  // 'Your password must be between 8-16 charactors long and include at least one uppercase letter(A-z) or one lower caseletter(a-z) or special characters'
                                );
                              },
                            })
                          ]}>
                          <Input
                            autoComplete='false'
                            className={styles.ant_input}
                            placeholder={t('emailPhone')}
                            // placeholder={ language18 === 'ja' ? `メールアドレス / 電話番号` : t('emailPhone') }
                          />
                        </Form.Item>
                        <Form.Item
                          name='password'
                          className={styles.password_form}
                          style={{textAlign:"left"}}
                          rules={[
                            {
                              required: true,
                              message: t('pleasePassword'),
                            },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                if (
                                  /^^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z0-9!@#$?.,%^&*_]{8,16}$/.test(
                                    value
                                  )
                                ) {
                                  return Promise.resolve();
                                }

                                return Promise.reject(
                                  t('passwordVerification')
                                  // 'Your password must be between 8-16 charactors long and include at least one uppercase letter(A-z) or one lower caseletter(a-z) or special characters'
                                );
                              },
                            }),
                          ]}>
                          <Input.Password
                            autoComplete='false'
                            className={styles.ant_input_password}
                            placeholder={t('passwordPlacehold')}
                            // placeholder={ language18 === 'ja' ? `パスワード` : t('passwordPlacehold')}
                            iconRender = { (visible) => ( visible ? <img style={{ width:'18px' }} src={'http://static-img.igamie.com/static/eyes_can.png'}  />  : <img style={{ width:'18px' }} src='http://static-img.igamie.com/static/eyes_no.png' />   ) }
                          />
                        </Form.Item>
                        <Form.Item
                          name='verifyCode'
                          className={styles.email_form}
                          rules={[
                            {
                              required: true,
                              message: 'Code is missing',
                            },
                          ]}>
                          <Input
                            placeholder={t('code')}
                            autoComplete='new-verifyCode'
                            suffix={<div onClick={singUpSendCode} className={!codePending ? styles.send_again : styles.send_again_dis}>{sendLoading ? <Spin indicator={antIcon} /> : timeNum}</div>}
                            className={styles.ant_input}
                          />
                        </Form.Item>
                        <div className={styles.continue_wrap}>
                          <div></div>
                          <Button
                            type='primary'
                            htmlType='submit'
                            loading={state.loading}
                            className={ `${styles.login_click_btn} ${styles.login_click_btn_login}` }>
                            {t('continue')}
                          </Button>
                        </div>

                      </Form>
                      <div className={styles.or_txt}>Or</div>
                      <div className={styles.social_login}>
                        <GoogleLoginBtn onCallBack={responseGoogle} />
                        {/* <GoogleLogin*/}
                        {/*  clientId={googleAppId}*/}
                        {/*  buttonText='Login'*/}
                        {/*  onSuccess={responseGoogle}*/}
                        {/*  onFailure={responseGoogleError}*/}
                        {/*  cookiePolicy={'single_host_origin'}*/}
                        {/*  render={(renderProps) => (*/}
                        {/*    <Button className={styles.google_img_wrapper}*/}
                        {/*            loading={state.googleLoading}*/}
                        {/*            onClick={ () => { if (! state.isCheck) { return message.warning(`*/}
                        {/*          To proceed, please agree to the Terms of Service and Privacy Policy.*/}
                        {/*          `) } renderProps.onClick()  } }*/}
                        {/*            disabled={renderProps.disabled}>*/}
                        {/*      <span></span>*/}
                        {/*      /!* <img src={GoogleLoginIcon}  className='google-login-icon' /> *!/*/}
                        {/*      /!* <span> {t('loginWithGoogle')}</span> *!/*/}
                        {/*    </Button>*/}
                        {/*  )}*/}
                        {/* />*/}
                        {/* <FacebookLogin*/}
                        {/*  appId={facebookAppId}*/}
                        {/*  callback={responseFacebook}*/}
                        {/*  render={(renderProps) => (*/}
                        {/*    <Button*/}
                        {/*      loading={state.fbLoading}*/}
                        {/*      className={styles.fb_img_wrapper}*/}
                        {/*      onClick={ () => {  if (! state.isCheck) { return message.warning(`*/}
                        {/*          To proceed, please agree to the Terms of Service and Privacy Policy.*/}
                        {/*          `) } facebookClick(renderProps.onClick); }}>*/}
                        {/*      <span></span>*/}
                        {/*      /!* <img src={FbLoginIcon} className='fb-login-icon'  /> *!/*/}
                        {/*      /!* <span>{t('loginWithFacebook')}</span> *!/*/}
                        {/*    </Button>*/}
                        {/*  )}*/}
                        {/* />*/}
                        {/* <div onClick={ twitterLogin } className={styles.twitter_login}></div>*/}

                      </div>
                      <div onClick={gotoWallectConnect} className={styles.wallet_connect_btn_walletconect_text}>{t('loginContinueWithWallet')}</div>
                      {/* <Button onClick={gotoWallectConnect} className={styles.wallet_connect_btn_walletconect}>*/}
                      {/*  <img className={styles.left_icon} src='https://static-img.igamie.com/static/walletConnect_icon2.png'  />*/}
                      {/*  <span className={styles.center_name} >{t('loginContinueWithWallet')}</span>*/}
                      {/* </Button>*/}
                      <div className={`${styles.register_container} ${styles.tc_wrapper}`}>
                        <div className={styles.agree}>
                          <div className={styles.by_sign_up}>
                            <span onClick={() => { setState({ ...state, isCheck: !state.isCheck }); }} style={{width:"22px", height:"20px"}}  className={ state.isCheck ? 'checkbox-new' : 'checkbox-no-new' } ></span>
                            <span>
                                {t('bySigningUp')}
                              <a className={styles.link_style}  href='https://imomo.ai/imomo_privacy_policy.html' > {t('privacyPolicy')}</a>  &

                                <a className={styles.link_style}  href='https://imomo.ai/imomo_terms.html' > {t('termsConditions')}</a>

               </span>
                          </div>
                        </div>
                      </div>
                    </div> : null
                  }
                </div> : null
              }
            </>
        }

        {
          <Modal destroyOnClose={true} width={500} zIndex={99999}
                 transitionName={"ant-fade"} centered className={`login-new-modal login-new-login-modal ${styles.login_model_wrap} ${styles.new_login_wrap}`}
                 footer={false} visible={showModal && step === 1 && !mobile} onCancel={onCancel}
          >
            {
              step === 1 ? <div>
                {/* / {t('signUp')*/}
                <div className={styles.imomo_logo}></div>
                <div className={styles.login_model_title}>{t("Welcome")}</div>
                <Form
                  name='basic'
                  initialValues={{
                    remember: true,
                    inviteCode:inviteCode
                  }}
                  onFinish={onFinish}
                  form={loginForm}
                  onFinishFailed={onFinishFailed}
                  validateTrigger='onSubmit'>
                  <Form.Item
                    name='account'
                    className={styles.email_form}
                    style={{textAlign:"left"}}
                    rules={[
                      {
                        required: true,
                        message: t('AccountMissing'),
                        // message:'2121'
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}(,[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,6})*$/.test(
                              value
                            ) || /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(value)
                          ) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            'Sorry, only letters (a-z) numbers (0-9), and priods(.) are allowed.'
                            // 'Your password must be between 8-16 charactors long and include at least one uppercase letter(A-z) or one lower caseletter(a-z) or special characters'
                          );
                        },
                      })
                    ]}>
                    <Input
                      autoComplete='false'
                      className={styles.ant_input}
                      placeholder={t('emailPhone')}
                      // placeholder={ language18 === 'ja' ? `メールアドレス / 電話番号` : t('emailPhone') }
                    />
                  </Form.Item>
                  <Form.Item
                    name='password'
                    className={styles.password_form}
                    style={{textAlign:"left"}}
                    rules={[
                      {
                        required: true,
                        message: t('pleasePassword'),
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            /^^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z0-9!@#$?.,%^&*_]{8,16}$/.test(
                              value
                            )
                          ) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            t('passwordVerification')
                            // 'Your password must be between 8-16 charactors long and include at least one uppercase letter(A-z) or one lower caseletter(a-z) or special characters'
                          );
                        },
                      }),
                    ]}>
                    <Input.Password
                      autoComplete='false'
                      className={styles.ant_input_password}
                      placeholder={t('passwordPlacehold')}
                      // placeholder={ language18 === 'ja' ? `パスワード` : t('passwordPlacehold')}
                      iconRender = { (visible) => ( visible ? <img style={{ width:'26px' }} src={'http://static-img.igamie.com/static/eye.svg'}  />  : <img style={{ width:'26px' }} src='http://static-img.igamie.com/static/eye-close.svg' />   ) }
                    />
                  </Form.Item>
                  <Form.Item
                    name='referralCode'
                    className={styles.email_form}
                    style={{textAlign:"left"}}>
                    <Input
                      autoComplete='false'
                      className={`${styles.ant_input} ${ newLoginStep === 1 ? styles.opatiy : ""}`}
                      placeholder={t('inviteCode')}
                      // placeholder={ language18 === 'ja' ? `メールアドレス / 電話番号` : t('emailPhone') }
                    />
                  </Form.Item>
                  <div className={`${styles.continue_wrap} ${styles.no_flex_wrap}`}>
                    {
                      newLoginStep === 1 ? <div className={styles.forget_password}>
                        <span style={{fontWeight:"900"}} onClick={()=>setStep(4)}>{t('loginForgetPassword')} ?</span>
                      </div> : null
                    }
                    <Button
                      type='primary'
                      htmlType='submit'
                      loading={state.loading}
                      className={ `${styles.login_click_btn} ${styles.login_click_btn_login} ${styles.new_login_btn}` }>
                      {t('Continue')}
                    </Button>
                  </div>
                </Form>
                <div className={styles.signup_wrap}>{ newLoginStep === 1 ? t("Don't have an account?") : t("alreadyHaveAccount")} <span onClick={()=>setNewLoginStep(newLoginStep === 1 ? 2 : 1)}>{ newLoginStep === 1 ? t("Sign up") : t("login")}</span></div>
                <div className={styles.or_wrap}>
                  <div className={styles.driver}></div>
                  <span>{t("OR")}</span>
                  <div className={styles.driver}></div>
                </div>
                <GoogleLoginBtn onCallBack={responseGoogle} />
                <TwitterLoginBtn/>

                <div className={`${styles.register_container} ${styles.tc_wrapper}`}>
                  <div className={styles.agree}>
                    <div className={styles.by_sign_up}>
                      <span onClick={() => { setState({ ...state, isCheck: !state.isCheck }); }} style={{width:"22px", height:"20px"}}  className={ state.isCheck ? 'checkbox-new' : 'checkbox-no-new' } ></span>
                      <span>
                                {t('bySigningUp')}
                        <a className={styles.link_style}  href='https://imomo.ai/imomo_privacy_policy.html' > {t('privacyPolicy')}</a>  &

                                <a className={styles.link_style}  href='https://imomo.ai/imomo_terms.html' > {t('termsConditions')}</a>

               </span>
                    </div>
                  </div>
                </div>

                {
                //   <div className={styles.or_txt}>Or</div>
                //   <div onClick={gotoWallectConnect} className={styles.wallet_connect_btn_walletconect_text}>{t('loginContinueWithWallet')}</div>
                //   <div className={`${styles.register_container} ${styles.tc_wrapper}`}>
                //   <div className={styles.agree}>
                //   <div className={styles.by_sign_up}>
                //   <span onClick={() => { setState({ ...state, isCheck: !state.isCheck }); }} style={{width:"22px", height:"20px"}}  className={ state.isCheck ? 'checkbox-new' : 'checkbox-no-new' } ></span>
                //   <span>
                // {t('bySigningUp')}
                //   <a className={styles.link_style}  href='https://imomo.ai/imomo_privacy_policy.html' > {t('privacyPolicy')}</a>  &
                //
                //   <a className={styles.link_style}  href='https://imomo.ai/imomo_terms.html' > {t('termsConditions')}</a>
                //
                //   </span>
                //   </div>
                //   </div>
                //   </div>
                }
              </div> : null
            }
          </Modal>
        }

        {
          <Modal
            destroyOnClose={true} width={650} zIndex={99999}
            transitionName={"ant-fade"} centered className={`login-new-modal login-new-home-modal ${styles.login_model_wrap}`}
            footer={false} visible={showModal && step === 0 } onCancel={onCancel}
          >
            <div>
              <div className={styles.login_model_title} style={{textAlign:"center"}}>
                Welcome to imomo.ai
              </div>
              <div>
                {/* <Button disabled={state.metamaskLoading} loading={state.walletLoading} className={`${styles.wallet_connect_btn_walletcon} ${styles.new_home_login}`} onClick={ walletConnectClick } >*/}
                {/*  <span className={styles.center_name} >Connect Wallet</span>*/}
                {/*  <div className={styles.walletconnect_icon}  />*/}
                {/* </Button>*/}
                <Button loading={walletLoading} className={`${styles.wallet_connect_btn_walletcon} ${styles.new_home_login}`} onClick={ onOpen } >
                  <span className={styles.center_name} >Connect Wallet</span>
                  <div className={styles.walletconnect_icon}  />
                </Button>
                <Button className={`${styles.wallet_connect_btn_walletcon} ${styles.new_home_login}`} onClick={ ()=>setStep(1) } >
                  <span className={styles.center_name} >Register or Login With</span>
                  <div className={styles.google_icon} />
                  <div className={styles.twitter_icon} />
                  <div className={styles.email_icon} />
                  {/* <div className={styles.twitter_icon} />*/}
                </Button>
              </div>
            </div>
          </Modal>
        }
      </>
  );
};

export default LoginModal;
