import Head from 'next/head';
import { useState, useEffect } from 'react';
import { wrapper } from '../redux/store';
import Layout from '../components/Layout';
import '../config/i18n';
import 'antd/dist/antd.css';
import '../../assets/global.css';
import Script from 'next/script';
import * as gtag from '../lib/gtag';
import { useRouter, Router } from 'next/router';
import NProgress from 'nprogress';
import { GA_TRACKING_ID, TTQ_PIXEL_ID } from '@/lib/gtag';
import '../styles/nprogress.css';


Router.events.on('routeChangeStart', () => {
  NProgress.start();
});

Router.events.on('routeChangeComplete', () => {
  NProgress.done();
});

Router.events.on('routeChangeError', () => {
  NProgress.done();
});

function App({ Component, pageProps }) {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no minimum-scale=1.0, maximum-scale=1.0, user-scalable=no' />
        <meta name='format-detection' content='telephone=no, email=no'/>
        <meta name='description' content='imomo is the ultimate platform to take your gaming experience to the next level. Top-up in-games credits and connect with other like-minded gamers.' />
        <meta name='keywords' content='imomo' />
        <link rel='apple-touch-icon' sizes='114x114' href='/favicon.ico'/>
        <meta name='apple-mobile-web-app-title' content='imomo' />
        <meta name='facebook-domain-verification' content='ecqsysleovq13azqkk3i1ystj4zkzp' />
        <meta name='p:domain_verify' content='f666697d0934b7b9b73c4322582b2ff7'/>
        <meta charSet='utf-8' />
        <title>imomo - Generate Any AI Arts You Love</title>
        <link rel='shortcut icon' href='/favicon.ico' type='image/ico'/>
         <link rel='dns-prefetch' href='//www.imomo.ai'/>
         <link rel='dns-prefetch' href='//imomo.ai'/>
        <link rel='dns-prefetch' href='//igamie-web.s3.ap-southeast-1.amazonaws.com'/>
        <link rel='dns-prefetch' href='//cdn.igamie.com'/>
        <link rel='dns-prefetch' href='//www.googletagmanager.com'/>
        <link rel='dns-prefetch' href='//connect.facebook.net' />
        <link rel='dns-prefetch' href='//www.google.com' />
        <link rel='dns-prefetch' href='//static-img.igamie.com' />
        <link rel='dns-prefetch' href='//cdn.stablediffusionapi.com' />

        {/* <script async src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4236071392787117' crossorigin='anonymous'></script> */}
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
        {/* <script async src='https://www.google.com/recaptcha/api.js?render=6Le9KfEcAAAAABuQEo55-59ygZV7ZQI8rh6QrbVm'></script> */}
        {/* <script dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '996313531219399');
            fbq('track', 'PageView');
            `
          }} /> */}
        <script
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
            gtag('config', 'AW-319646800');
          `,
            }}
          />
      {/*  tikTok tag*/}
        <script
          dangerouslySetInnerHTML={{
            __html: `!function (w, d, t) {
              w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
            }(window, document, 'ttq');
            ttq.load('${TTQ_PIXEL_ID}');
            ttq.page();`
          }}
          />
      </Head>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </>
  );
}


export default wrapper.withRedux(App);
