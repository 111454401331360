import { SETALLPOINTSEXCHANGEIGM } from "@/constants/ActionTypes";

const initialState = {
  isExchange: true,
}

const setAllowPointsExchangeIGM = (state = initialState, { type, payload }) => {
  switch (type) {
    case SETALLPOINTSEXCHANGEIGM: {
      return {
        ...state,
        isExchange: payload
      }
    }
    default:
      return state;
  }
}

export default setAllowPointsExchangeIGM
