import {
  INCREMENT,
  DECREMENT,
  RESET,
  ADD_TO_NUMBER,
  SETLOGINMODALSHOW,
  SETLOGINMODALHIDDEN,
  SETHIDEAPPDOWNLOAD,
  SETCOINSBALANCE,
  SETEXPIRINGPPOINTS,
  SETUSERIGMBALANCE,
  SETALLPOINTSEXCHANGEIGM,
  SETIGMTRANSACTIONMODAL,
  SETCOLLAPSEDOPEN,
  SETCOLLAPSEDCLOSE,
  SETLOGINSHOWMODAL,
  SETBUYCOINSMODAL,
  SETSELLNFTSHOW,
  SETSELLNFTHIDDEN,
  SETLOGINBINDTIPSHOW, SETMODULEMAP
} from '../../constants/ActionTypes';

export function increment() {
  return {
    type: INCREMENT
  };
}

export function decrement() {
  return {
    type: DECREMENT
  };
}

export function reset() {
  return {
    type: RESET
  };
}

export function addToNumber(payload) {
  return  {
    type: ADD_TO_NUMBER,
    payload
  };
}

export function setLoginModalShow(payload) {
  return {
    type: SETLOGINMODALSHOW,
    payload
  };
}

export function setLoginModalHidden() {
  return {
    type: SETLOGINMODALHIDDEN
  };
}

export function setHideAppDownload(payload) {
  return {
    type: SETHIDEAPPDOWNLOAD,
    payload
  };
}

export function setCoinsBalance(payload) {
  return  {
    type: SETCOINSBALANCE,
    payload
  }
}

export function setExpiringPoints(payload) {
  return {
    type: SETEXPIRINGPPOINTS,
    payload
  }
}

export function setIGMBalance(payload) {
  return {
    type: SETUSERIGMBALANCE,
    payload
  }
}

export function setAllowPointsExchangeIGM(payload) {
  return {
    type: SETALLPOINTSEXCHANGEIGM,
    payload
  }
}

export function setIGMTransactionModal(payload) {
  return {
    type: SETIGMTRANSACTIONMODAL,
    payload
  }
}
// 设置aside 开关
export function setCollapsedOpen() {
  return {
    type: SETCOLLAPSEDOPEN,
  }
}

export function setCollapsedClose() {
  return {
    type: SETCOLLAPSEDCLOSE,
  }
}

export function setLoginShowModal(payload) {
  return {
    type: SETLOGINSHOWMODAL,
    payload
  };
}

// 设置显示积分购买的弹窗
export function setBuyCoinsModal(payload) {
  return {
    type: SETBUYCOINSMODAL,
    payload
  };
}

// 显示nft售卖发布
export function setNftSellShow(payload) {
  return {
    type: SETSELLNFTSHOW,
    payload
  }
}

// 隐藏nft售卖发布
export function setNftSellHidden(payload) {
  return {
    type: SETSELLNFTHIDDEN,
    payload
  }
}

// 登陆没有绑定邮箱或手机
export function setLoginBindTipShow(payload) {
  return {
    type: SETLOGINBINDTIPSHOW,
    payload
  };
}

// 模型对象
export function setModuleMap(payload) {
  return {
    type: SETMODULEMAP,
    payload
  };
}
