import {ADD_TO_NUMBER, DECREMENT, INCREMENT, RESET, SETLOGINSHOWMODAL} from "@/constants/ActionTypes";

const initialState = {
  showModal: false
};

const setLoginShowModal = (state = initialState, { type, payload }) => {
  switch (type) {
    case SETLOGINSHOWMODAL:
      return {
        ...state,
        showModal: payload
      };
    default:
      return state;
  }
};

export default setLoginShowModal;
