import { combineReducers } from 'redux';
import counter from './counter';
import loginmodal from './loginmodal';
// import hideAppDownload from "@/redux/reducers/home/hideAppdownload";
import hideAppDownload from './hideAppdownload';
import setCoinsBalance from './coinsBalance';
import setExpiringPoints from "@/redux/reducers/home/expiringPoints";
import setIGMBalance from "./igmBalance";
import setAllowPointsExchangeIGM from "./allowPointsExchangeIGM";
import setIGMTransactionModal from "./igmTransactionModal";
import setBuyCoinsModal from "@/redux/reducers/home/buyCoinsModal";
import nftsellmodal from './nftSellModal';
import collapsed from './collapsed';
import setLoginBindTipShow from "@/redux/reducers/home/loginBindTip";
import setModuleMap from "@/redux/reducers/home/moduleMap";

export default combineReducers({
  counter,
  hideAppDownload,
  loginmodal,
  setCoinsBalance,
  setExpiringPoints,
  setIGMBalance,
  setAllowPointsExchangeIGM,
  setIGMTransactionModal,
  collapsed,
  setBuyCoinsModal,
  nftsellmodal,
  setLoginBindTipShow,
  setModuleMap
});
