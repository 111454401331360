import { SETSELLNFTSHOW, SETSELLNFTHIDDEN } from "@/constants/ActionTypes";

const initialState = {
   show: false
}

const nftsellmodal = (state = initialState, { type, payload }) => {
  switch (type) {
    case SETSELLNFTSHOW:
      return {
        ...state,
        show: true
      }
    case SETSELLNFTHIDDEN:
      return {
        ...state,
        show: false
      }
    default:
      return state;
  }
}
export default nftsellmodal
