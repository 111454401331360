import {message} from "antd";
import {isMobile, isMobileAndroid} from "@/tools/action";
import WalletConnect from "@walletconnect/client";
import walletConfig from "@/config/walletConfig";


export const WalletGetMetamaskProviderAndAddress = async ({callBack}) => {
  if (!isMobile()) {
    try {
      if (typeof window.ethereum !== 'undefined' || (typeof window.web3 !== 'undefined')) {

        // Web3 browser user detected. You can now use the provider.
        const provider = window['ethereum'] || window.web3.currentProvider
        console.log(provider)
        const account = await provider.enable();
        const walletNumber = Math.floor(Math.random() * 1000000000)
        const sign = await provider.request({
          method: 'personal_sign',
          params: [walletConfig.getWalletSignMessage(walletNumber, account[0]), account[0]],
        });
        callBack?.(provider, account, sign, walletNumber);
      } else {
        message.error('Please install Metamask').then(value => {
          window.open('https://metamask.io/download/');
        });

        callBack?.(null, null);
      }
    } catch (e) {
      message.error(e.message);
      callBack?.(null, null);
    }
  } else {
    try {
      const bridge = 'https://bridge.walletconnect.org';
      const connector = new WalletConnect({ bridge });
      // await connector.killSession();
      if (!connector.connected) {
        await connector.createSession();
        connector.on('connect', (error, payload) => {
          if (error) {
            throw error;
          }
          onConnect(payload, connector);
        });

        connector.on('disconnect', (error, payload) => {
          // message.error('disconnect');
          callBack?.(null, null);
        });
        let metamaskUri = connector.uri;
        let metamaskAppDeepLink = '';
        if (isMobileAndroid()) {
          metamaskAppDeepLink = "https://metamask.app.link/wc?uri=" + encodeURIComponent(metamaskUri);
        } else {
          metamaskAppDeepLink = `metamask://wc?uri=${metamaskUri}`;
        }
        const a = document.createElement("a");
        a.href = metamaskAppDeepLink;
        a.click();
        a.remove();

      }
      if (connector.connected) {
        const { chainId, accounts } = connector;
        const address = accounts[0];
        const walletNumber = Math.floor(Math.random() * 1000)
        const msgParams = [
          walletConfig.getWalletSignMessage(walletNumber, address),
          address,
        ];
        let metamaskAppDeepLink = '';
        if (isMobileAndroid()) {
          let metamaskUri = connector.uri;
          // metamaskAppDeepLink = "https://metamask.app.link/wc?uri=" + encodeURIComponent(metamaskUri);
          metamaskAppDeepLink = "https://metamask.app.link/dapp/" + window.location.hostname;
          const a = document.createElement("a");
          a.href = metamaskAppDeepLink;
          a.click();
          a.remove();
        }
        // else {
        //   metamaskAppDeepLink = `metamask://wc?uri=`;
        //   // metamaskAppDeepLink = "https://metamask.app.link/wc";
        // }
        // window.location.href = metamaskAppDeepLink;
        // window.location.open(metamaskAppDeepLink);
        const signData = await connector.signPersonalMessage(msgParams);

        // await connectCallback(address, signData, walletNumber);
        callBack?.(null, accounts, signData, walletNumber);
      }
    } catch (e) {
      console.log(e);
      callBack?.(null, null);
    }

  }
  const onConnect = async (payload, connector) => {
    const { chainId, accounts } = payload.params[0];
    const address = accounts[0];
    const walletNumber = Math.floor(Math.random() * 1000);
    const msgParams = [
      walletConfig.getWalletSignMessage(walletNumber, address),
      address,
    ];
    let metamaskAppDeepLink = "https://metamask.app.link/dapp/" + window.location.hostname;
    const a = document.createElement("a");
    a.href = metamaskAppDeepLink;
    a.click();
    a.remove();
    // window.location.open(metamaskAppDeepLink);
    const signData = await connector.signPersonalMessage(msgParams);
    // callBack?.(null, accounts);
    callBack?.(null, accounts, signData, walletNumber);
  };
};
