import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import Header from './Header';
import { useRouter } from 'next/router';
import {CloseOutlined, GlobalOutlined} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useTranslation, Trans, Translation } from 'react-i18next';
import {setIsRegister, setUserInfo} from '@/redux/actions/userInfo';
import {
  APIGetProfileUnDeal,
  APIUtmCookie,
  APIUserBindWalletNew, APIGetRegionIp, APIGetGenerateModelList,
} from '../api';
import { setLoginModalShow, setLoginModalHidden, setModuleMap } from '@/redux/actions/home';
import useDispatchAction from '@/hooks/useDisptachAction';
import Footer from '@/components/Footer';
import LoginModal from "@/components/LoginModal";
import BuyCoinsModal from "@/components/BuyCoinsModal";

// web3 Modal配置
import {mainnet, optimism, polygon} from "wagmi/chains";
import {configureChains, createConfig, WagmiConfig} from "wagmi";
import {EthereumClient, w3mConnectors, w3mProvider} from "@web3modal/ethereum";
import {Web3Modal, useWeb3ModalTheme} from "@web3modal/react";
const projectId = "f2ae5537f931b247aa7432ef6cdd08cf";
const chains = [mainnet, polygon, optimism];
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const metadata = {
  name: 'imomo.ai',
  description: 'connect imomo.ai',
  url: 'https://imomo.ai',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
};
const wagmiConfig = createConfig({
  autoConnect: false,
  connectors: w3mConnectors({ chains, projectId, metadata }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);


function Layout({ children }) {
  const router = useRouter();
  let { t, i18n} = useTranslation();
  const domp1 = React.createRef();
  const [state, setState] = useState({
    menuShow: true,
    orderCompleteCount: 0,
    orderPendingCount: 0,
    menuToggle: false
  });
  const [HeaderShow, setHeaderShow] = useState(true)
  const [isMobileState, setIsMobile] = useState(false)
  // const [profile, setProfile] = useState({
  //   userInfo: {},
  //   nickName: null
  // });

  const userInfo = useSelector(state => state.userInfo.userInfo.userInfo);
  const show = useSelector(state => state.home.loginmodal && state.home.loginmodal.show);
  const loginEmail = useSelector(state => state.home.loginmodal && state.home.loginmodal.email )
  const registerInfo = useSelector(state => state.userInfo.setUserRegister && state.userInfo.setUserRegister.registerInfo);
  const collapsed = useSelector(state => state.home.collapsed && state.home.collapsed.open)
  const dispatchAction = useDispatchAction({setUserInfo, setLoginModalShow, setLoginModalHidden, setIsRegister, setModuleMap});
  const languageList = [
    {name:"Singapore", value:"en"},
    {name:"Malaysia", value:"en-MY"},
    {name:"Philippines", value:"en-PH"},
    {name:"Indonesia", value:"id-ID"},
    {name:"Brazil", value:"en-BR", value1:"en-br"},
    {name:"Egypt", value:"en-EG", value1:"en-eg"},
  ];
  const [fromApp, setFromApp] = useState(false);




 const getProfile = async (cb) => {
  if (localStorage.getItem('token')) {
    APIGetProfileUnDeal().then(async resp => {

      let nickName = resp.result && resp.result.nickName;
      let email = resp.result && resp.result.email;
      if (email && email.includes('facebook-')) {
        router.push('/profile');
      }
      let userInfo = resp.result ? resp.result : {};
      cb(userInfo && userInfo.id)
      if (!nickName) {
        nickName =  resp.result && resp.result.mobilePhone || resp.result && resp.result.email.split('@')[0];
      }
      dispatchAction.setUserInfo(userInfo);
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
    });

  }
 };

 const getQueryString = (name) => {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r !== null) return unescape(r[2]);
  return null;
};

 const initStorage = () => {
  let channel = getQueryString('x') || getQueryString('channel') || getQueryString('c');
  let utmSource = getQueryString('utm_source') || getQueryString('utmSource');
  let ref = getQueryString('ref');
  if (channel && channel !== 'null') {
    localStorage.setItem('channel', channel);
  }
  if (ref && ref !== 'null') {
    localStorage.setItem('ref', ref);
  }
  if (utmSource && utmSource !== 'null') {
    localStorage.setItem('utmSource', utmSource);
  }
  if (localStorage.getItem('isApp') === '1') {
    setHeaderShow(false)
  }
};

  const getCookieByName = (name) => {
    const cookie = document.cookie;
    return cookie.split(`; ${name}=`).pop().split(';').shift();
  };

  const setUtmCookies = () => {
    console.log('cookisss', router.pathname);
    console.log('lllcokkis', getCookieByName('_ttp'));
    let igamieUtmCookies = getCookieByName('igamieUtm');
    let index = router.asPath.lastIndexOf('?');
    let value = router.asPath.substring(index + 1, router.asPath.length);

    console.log('igamieUtmCookies', igamieUtmCookies)
    console.log('value', value)
    console.log('router.asPath', router.asPath)
    console.log('测试', /\?utm_/.test(router.asPath))

    if (/utm_/.test(router.asPath) && value !== igamieUtmCookies) {
        APIUtmCookie(JSON.stringify({
           igamieUtm: value
        })).then(resp => {

        });
    }
  };

  const isHideAsideRouter = () => {
    if (fromApp){
      return true;
    }

    const routerHidePath = [
      "/login", "/signUp", "/welocome", "/welocome", "/varification", "/transHistory", "/deposit-igm", "/withdrawIGMToWallet"
    ];



    console.log('router', router.pathname)
    if (routerHidePath.includes(router.pathname)) {
      return true
    }
  }

  const isHideAsideRouterHeader = () => {
    console.log('router', router.pathname)
    if (router.pathname === '/login' || router.pathname === '/signUp' || router.pathname === '/welocome' || router.pathname === '/verification' || router.pathname === '/varification') {
      return true
    }
  }

  const chooseCorrectLanguage = async (locale) => {
    console.log('locale', locale);
     switch (locale) {
       case 'en-BR':
         await i18n.changeLanguage('pt');
         break;
       case 'pt-BR':
         await i18n.changeLanguage('pt');
         break;
       case 'ja-JP':
         await i18n.changeLanguage('ja');
         break;
       case 'ja':
         await i18n.changeLanguage('ja');
         break;
       case 'id-ID':
         await i18n.changeLanguage('id');
         break;
       default:
         await i18n.changeLanguage('en');
         break;
     }
  };

  const initRegion = async () => {
    let regions = localStorage.getItem('regions');
    if (router.locale === 'en') {
      if (regions && (regions.includes('en') || regions.includes('ja'))) {
        router.replace(router.asPath, router.asPath, {locale: regions});
      }
    } else if (router.locales.includes(router.locale)) {
      localStorage.setItem('regions', router.locale);
      await chooseCorrectLanguage(router.locale);
    }
  }

  const closeMenu = () => {

  }

  const checkIPRegion = () => {
    let regions = localStorage.getItem('regions');
    if (!regions) {
      APIGetRegionIp({}).then(resp => {
        if (resp.resultCode === 200) {
          if (resp.result === 'JP' && router.locale !== 'ja') {
            router.replace(router.asPath, router.asPath, {locale: 'ja'});
            localStorage.setItem('regions', 'ja');
            chooseCorrectLanguage('ja');

          }
        }
      });
    }

  };

  const getModelList = () => {
    APIGetGenerateModelList().then(respp => {
      if (respp.resultCode === 200) {
        let baseModel = respp.result.base;
        let baseMap = {};
        baseModel.map((item) => {
          baseMap[item.trainingId] = item.modelName;
        });
        console.log('moduleMap', baseMap)
        dispatchAction.setModuleMap(baseMap);
      }
    });
  };


useEffect(() => {
  let fromApp = localStorage.getItem('fromApp');
  setFromApp(fromApp === "app");
  setUtmCookies();
  getModelList();
  initStorage();
  getProfile(() => {})
  isHideAsideRouter();
  checkIPRegion();
}, []);


 useEffect(() => {
   initRegion()
 }, [])

  const web3ModalTheme = useWeb3ModalTheme();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
    web3ModalTheme.setTheme({
      themeMode:"dark",
      themeVariables:{
        "--w3m-z-index":9999999
      }
    })
  }, []);


  return (
    <div className={`app-layout ${ collapsed ? 'app-layout-open' : 'app-layout-close' }`}>
      <style dangerouslySetInnerHTML={{
        __html: `
          html,body {font-family: ${router.locale === 'ja' ? 'Gothic' : 'Roboto'}!important;}
        `
      }} />
      <main className='content-container'>

        {ready ? (
          <WagmiConfig config={wagmiConfig}>
            <LoginModal></LoginModal>
          </WagmiConfig>
        ) : null}

        <Web3Modal   projectId={projectId} ethereumClient={ethereumClient} />


        <BuyCoinsModal></BuyCoinsModal>
        <div className={ isHideAsideRouter() ? 'display-none' : ''}>
            <Header  orderCompleteCount={state.orderCompleteCount}  orderPendingCount={state.orderPendingCount}   userInfo={userInfo} />
            <div className='header-copy'></div>
          </div>
        {children}
        {
          router.pathname === '/nft-sell-post' || router.pathname === '/deposit-igm' || router.pathname === '/withdrawIGMToWallet' ? (
            <></>
          ) : <Footer />
        }
      </main>
    </div>
  );
}
export default Layout;
Layout.propTypes = {
  children: PropTypes.any
};

Layout.defaultProps = {
  children: null
};
