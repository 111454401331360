import { SETUSERINFO } from "@/constants/ActionTypes";

const initialState = {
    userInfo: {}
};

const userInfo = (state = initialState, { type, payload }) => {
    switch (type) {
        case SETUSERINFO:
            return {
                ...state,
                userInfo:payload
            };
        default:
            return state;    
    }
};

export default userInfo;