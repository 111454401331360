import { SETCOINSBALANCE } from "@/constants/ActionTypes";

const initialState = {
    coinsBalance: 0,
}

const setCoinsBalance = (state = initialState, { type, payload }) => {
    switch (type) {
        case SETCOINSBALANCE: {
            return {
                ...state,
                coinsBalance: payload
            }
        }   
        default:
            return state;
    }
}

export default setCoinsBalance