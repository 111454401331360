
export const numFormat = (num) => {
  if (!num){
    return  0;
  }
  var numStr = num.toString();
  if (numStr.length <= 3){
    return numStr;
  }
  if (numStr.length >= 4 && numStr.length < 7){
    return  (num / 1000).toFixed(1) + "k";
  }
  if (numStr.length >= 7){
    return  (num / 1000000).toFixed(1) + "m";
  }
};

export const numFormatNotRound = (num) => {
  if (!num){
    return  num;
  }
  var numStr = num.toString();
  if (numStr.length <= 3){
    return numStr;
  }
  if (numStr.length >= 4 && numStr.length < 7){
    return  parseInt(num / 1000) + "k";
  }
  if (numStr.length >= 7){
    return  parseInt(num / 1000000) + "m";
  }
};
