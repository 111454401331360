import { SETCOLLAPSEDOPEN, SETCOLLAPSEDCLOSE } from "@/constants/ActionTypes";

const initialState = {
    open: true
}

const collapsed = (state = initialState, { type, payload }) => {
    switch (type) {
        case SETCOLLAPSEDOPEN:
            return {
                ...state,
                open: true
            };
        case SETCOLLAPSEDCLOSE:
            return {
                ...state,
                open: false
            }    
        default:
          return state      
    }
}
export default collapsed