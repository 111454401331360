// var host

// if (global.window.location.host === "p.anystarr.com") {
//     host = 'https://api.igamie.com'
// } else if (window.location.host === "igamie.clubchopp.com") {
//     host = 'http://112.124.40.161:8090'
// } else if (window.location.host === "local.clubchopp.com:3001" || window.location.host === "local.clubchopp.com:3002" || window.location.host === 'localhost:3001') {
//     host = 'http://112.124.40.161:8090'
// } else {
//     host = 'https://api.igamie.com'

// }

// var host = 'https://api.igamie.com';
// var host = 'https://np.igamie.com/iapi';
// var host = 'http://np.imomo.ai/iapi';
var host = 'http://8.222.228.63:8090';
host = 'https://imomo.ai';
// var host = 'http://8.219.118.16:8090';
// host = 'https://imomo.ai/iapi';
// host = 'http://192.168.2.126:8090';
console.log('hohhhh', process.env.NODE_ENV);
if (process.env.NODE_ENV === 'production') {
    // host = 'https://imomo.ai/iapi';
    // host = 'http://8.222.228.63:8090';
}

module.exports = host;
