import { SETLOGINMODALHIDDEN, SETLOGINMODALSHOW } from "@/constants/ActionTypes";

const initialState = {
    show: false,
    email: ''
};

const loginmodal = (state = initialState, { type, payload }) => {
    console.log('payload', payload)
    switch (type) {
        case SETLOGINMODALHIDDEN:
          return {
              ...state,
              show: false
          };
        case SETLOGINMODALSHOW:
          return {
              ...state,
              show: true,
              email: payload
          };
        default:
          return state;
    }
};
export default loginmodal;
