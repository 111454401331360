let WALLET_SIGN_MESSAGE = 'Login to imomo';

const getWalletSignMessage = (nonce, walletAddress) => {
  let message = `Welcome to imomo!\nThis signature request is to verify that you own the wallet that is trying to log in to imomo.\n\nWallet address:\n${walletAddress}\nNonce: ${nonce}\n\nThis request will not trigger blockchain transactions and cost any gas fees.`;
  return message;
}

export default {
  WALLET_SIGN_MESSAGE,
  getWalletSignMessage
};
