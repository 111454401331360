// ================= Home Part ==================== //
export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const RESET = 'RESET';
export const ADD_TO_NUMBER = 'ADD_TO_NUMBER';
export const SETHIDEAPPDOWNLOAD = 'SETHIDEAPPDOWNLOAD';

// ================= User Part ==================== //

export const FETCH_USER_LIST = 'FETCH_USER_LIST';
export const FETCH_USER_LIST_FAIL = 'FETCH_USER_LIST_FAIL';
export const FETCH_USER_LIST_SUCCESS = 'FETCH_USER_LIST_SUCCESS';

// ================= isMobile Part ==================== //

export const SETPCENV = 'SETPCENV';
export const SETMOBILEENV = 'SETMOBILEENV';

// ================= userinfo Part ==================== //

export const SETUSERINFO = 'SETUSERINFO';
export const SETISREGISTER = 'SETISREGISTER';

// ================= loginModal Part ==================== //
export const SETLOGINMODALSHOW = 'SETLOGINMODALSHOW';
export const SETLOGINMODALHIDDEN = 'SETLOGINMODALHIDDEN';


// ================= user coins balance ==================== //
export const SETCOINSBALANCE = 'SETCOINSBALANCE';
export const SETEXPIRINGPPOINTS = 'SETEXPIRINGPPOINTS';

// ============ user igm balance ======================== //
export const SETUSERIGMBALANCE = 'SETUSERIGMBALANCE';
export const SETALLPOINTSEXCHANGEIGM = 'SETALLPOINTSEXCHANGEIGM';
export const SETIGMTRANSACTIONMODAL = 'SETIGMTRANSACTIONMODAL';

// ============ collapsed  ======================== //
export const SETCOLLAPSEDOPEN = 'SETCOLLAPSEDOPEN'
export const SETCOLLAPSEDCLOSE = 'SETCOLLAPSEDCLOSE'


// ================= loginModal Part ==================== //
export const SETLOGINSHOWMODAL = 'SETLOGINSHOWMODAL';

export const SETBUYCOINSMODAL = 'SETBUYCOINSMODAL';


// ===== nft sell c to c  ==== //
export const SETSELLNFTSHOW = 'SETSELLNFTSHOW'
export const SETSELLNFTHIDDEN = 'SETSELLNFTHIDDEN'

// ========= login bind tip ==== //
export const SETLOGINBINDTIPSHOW = 'SETLOGINBINDTIPSHOW';

// ========== module map === //
export const SETMODULEMAP = 'SETMODULEMAP';

