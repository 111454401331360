export const GA_TRACKING_ID = 'G-HWXNQL4YTQ';
export const TTQ_PIXEL_ID = 'CIJRGK3C77U220R29T80';


// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
    });
  };

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  };


