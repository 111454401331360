import { SETISREGISTER } from "@/constants/ActionTypes";

const initialState = {
  registerInfo: {}
};

const setUserRegister = (state = initialState, { type, payload }) => {
  console.log(payload)
  switch (type) {
    case SETISREGISTER:
      return {
        ...state,
        registerInfo: payload
      };
    default:
      return state;
  }
};

export default setUserRegister;
