import React, { useEffect, useState, useRef } from 'react';
import router, { useRouter } from 'next/router';
import Link from 'next/link';
import getConfig from 'next/config';
import {Drawer, Dropdown, Menu, Avatar, Popover, Spin, Badge} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  APIGetProfileUnDeal,
  APILogout,
  APIGetVoucherInfo,
  APIGetIntegralAccountPage,
  APIGetIGMBalance, APIGetProfile, APIGetUserLastMessageList, APIGetAndroidDownloadLink
} from '../api';
import {defaultHeadPic, getYearMonthDayTimeStampNew, isMobile} from "@/tools/action";
import {useSelector} from "react-redux";
import useDispatchAction from "@/hooks/useDisptachAction";
import {
  addToNumber,
  reset,
  setCoinsBalance,
  setExpiringPoints,
  setIGMBalance,
  setAllowPointsExchangeIGM,
  setIGMTransactionModal,
  setNftSellHidden,
  setNftSellShow,
  setLoginShowModal
} from "@/redux/actions/home";
import dynamic from 'next/dynamic';
import OssImg from '../components/OssImg'
import MobileDrawer from "@/components/MobileDrawer";
import {copyWallet, splitWalletAddress, walletConnect} from "@/tools/walletConnect";
import WalletChooseModal from "@/components/WalletChooseModal";
import {setUserInfo} from "@/redux/actions/userInfo";
import {numFormatNotRound} from "@/tools/numFormat";
import Aside from '@/components/Aside'
import LoginBindTip from "@/components/LoginBindTip";
import SettingModal from "@/components/SettingModal";
import {loginShowModal} from "@/tools/dealResult";
import {LoadingOutlined} from "@ant-design/icons";

// Only holds serverRuntimeConfig and publicRuntimeConfig from next.config.js nothing else.
const { publicRuntimeConfig: { staticFolder } } = getConfig();
const antIcon1 = <LoadingOutlined style={{ fontSize: 30 }} spin />;


const Header = ({ orderCompleteCount, orderPendingCount, userInfo }) => {
  const router = useRouter();
  const domp1 = React.createRef();
  let { t, i18n} = useTranslation();
  const count = useSelector(state => state.noticeCount.noticeCount.count);
  const dispatchAction = useDispatchAction({reset, addToNumber, setCoinsBalance, setUserInfo, setExpiringPoints, setIGMBalance, setAllowPointsExchangeIGM, setIGMTransactionModal, setLoginShowModal, setNftSellHidden, setNftSellShow});
  const coinsBalance = useSelector(state => state.home.setCoinsBalance && state.home.setCoinsBalance.coinsBalance);
  const igmBalance = useSelector(state => state.home.setIGMBalance && state.home.setIGMBalance.igmBalance);

  const [chooseWalletModal, setChooseWalletModal] = useState(false);

  const [settingVis, setSettingVis] = useState(false);

  const [mobileState, setMobileState] = useState({
    isMobile: false
  });

  const [noticeList, setNoticeList] = useState([]);
  const [noticeLoading, setNoticeLoading] = useState(false);

  useEffect(()=>{
    initMobile();
  }, []);

  const initMobile = () => {
    setMobileState({
      isMobile: isMobile()
    })
  };

  const [state, setState] = useState({
      menuShow: false,
      searchVis: false,
      searchContent: '',
      gameLists: [],
      searchLoading: false,
      searchDraw: false,
      menuVisible: false,
      guestVis: false,
      regionlayer:false,
      cssVis: false,
      navMenuVis: false,
      imLoading:false,
      largetMenuVis: false,
      coinsNum:0,
      leftMenuVis: false
  });
  const [isHide, setIsHide] = useState(false);



  const pcLoginMenuClick = ({ key }) => {
    let value = key;
    console.log('valueppppp', value);
    console.log('yyyyy', typeof value);

    let locale = router.locale;
    if (value === 0){
      router.push('/');
    } else if (value === '1'){
      router.push('/profile');
    } else if (value === '2'){
      router.push('/profile');
    } else if (value === '3'){
      router.push('/myRewards/?type=active_voucher')
    } else if (value === '4'){
      window.location.href = `/${router.locale}/personal-center/${userInfo.id}`
    } else if (value === '5'){
      router.push('/myOrders');
    } else if (value === '6'){
      router.push('/my-coins');
    } else if (value === '7'){
      console.log('xixixixi77');
      setState({
        ...state,
        cssVis: true,
        navMenuVis: false
      });
      return;
    } else if (value === '8') {
      router.push('/affiliateProgram-Order')
    } else if (value === '9') {
      window.open('https://blog.igamie.com')
    }
    else if (value === '10') {
      console.log('10')
    }
    else if (value === '11') {
      router.push('/myNFTOrders');
    }
    else if (value === '12') {
      setSettingVis(true);
    }
    else if (value === '-1') {
      console.log('rrrt', router);
      APILogout({}).then(resp => {
        localStorage.removeItem('token');
        localStorage.removeItem('fk-token');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('walletconnect')
        switch (router.pathname) {
          case '/discover':
            window.location.reload();
            break;
          case '/':
            window.location.reload();
            break;
          default:
            window.location.href =  `/`;
            break;
        }
      });
    }
    else if (value === '13') {
      router.push({
        pathname:'/notice',
        query:{
          messageType: 2
        }
      });
    } else if (value === '14') {
      router.push('/tip-box');
    } else if (value === '15') {
      router.push('/examazon');
    }
    if (state.navMenuVis) {
      setState({
        ...state,
        navMenuVis: false
      });
    }
  };

  const languageMenuClick = ({ key }) => {
    if (key === 'en-ID' || key === 'id-ID') {
      i18n.changeLanguage('id');
    } else if (key === 'en-BR' || key === 'pt-BR') {
      i18n.changeLanguage('pt');
    } else if (key === 'ja-JP') {
       i18n.changeLanguage('ja');
    } else if (key === 'ja') {
      i18n.changeLanguage('ja');
    } else {
      i18n.changeLanguage('en');
    }
    setState({
      ...state,
      cssVis: false

    });
    router.replace(router.pathname, window.location.pathname.replace('/ja', ''), { locale: key })
    localStorage.setItem('regions', key);
  };


  const pcLoginMenu = () => (
    <Menu onClick={pcLoginMenuClick}>
      <Menu.Item key={4}>{t('imomoProfile')}</Menu.Item>
      {/* <Menu.Item key={14}>{t('imomoTipBox')}</Menu.Item>*/}
      {/* <Menu.Item key={15}>{t('redeemText')}</Menu.Item>*/}
      <Menu.Item key={12}>{t('imomoSetting')}</Menu.Item>
      <Menu.Item key={-1}>{t('logout1')}</Menu.Item>
    </Menu>
  );

  const mobileMenu = () => (
    <Menu onClick={pcLoginMenuClick}>
      <Menu.Item key={13}>{t('notification')}</Menu.Item>
      <Menu.Item key={4}>{t('imomoProfile')}</Menu.Item>
      <Menu.Item key={14}>{t('imomoTipBox')}</Menu.Item>
      <Menu.Item key={15}>{t('redeemText')}</Menu.Item>
      <Menu.Item key={12}>{t('imomoSetting')}</Menu.Item>
      <Menu.Item key={-1}>{t('logout1')}</Menu.Item>
    </Menu>
  )

  const languageMenu = () => (
    <Menu onClick={languageMenuClick}>
      <Menu.Item icon={<div className={"en-icon"}></div>} key='en'><span className='context'>English</span></Menu.Item>
      {/* <Menu.Item key='en-MY'><img className='region-flag' src='https://cdn.igamie.com/static/en-my.svg'/> <span className='context'>{t("malaysia")}</span> </Menu.Item>*/}
      {/* <Menu.Item key='en-PH'><img className='region-flag' src='https://cdn.igamie.com/static/en-ph.svg'/> <span className='context'>{t("philippines")}</span> </Menu.Item>*/}
      {/* <Menu.Item key='id-ID'><img className='region-flag' src='https://cdn.igamie.com/static/id-id.svg'/> <span className='context'>{t("indonesia")}</span> </Menu.Item>*/}
      {/* <Menu.Item key='pt-BR'><img className='region-flag' src='https://cdn.igamie.com/static/en-br.svg'/> <span className='context'>{t("brazil")}</span> </Menu.Item>*/}
      {/* <Menu.Item key='en-EG'><img className='region-flag' src='https://cdn.igamie.com/static/en-eg.svg'/> <span className='context'>{t("egypt")}</span> </Menu.Item>*/}
      <Menu.Item icon={<div className={"ja-icon"}></div>} key='ja'><span className='context'>日本語</span> </Menu.Item>
    </Menu>
  );

 const gotoOrderList = () => {

    APIGetProfileUnDeal().then(resp => {
       let locale = router.locale;
       if (resp.resultCode === 10006 || resp.resultCode === 10000 || resp.resultCode === 10005 || resp.resultCode === 10007 ) {
           setState({
             ...state,
             guestVis: true
           });
       } else {
        router.push('/myOrders');
       }
    });
 };

const isLogin = () => {
  if (router.pathname === '/login' || router.pathname === '/signUp' || router.pathname === '/verification' || router.pathname === '/welcome' ) {
    return true;
  }
};


const gotoBack = () => {
  router.back();
};

const [voucherState, setVoucherStatus] = useState({
  name: '',
  discount: 0,
  currencyUnit: '',
  currency: 0,
  gameAlias: '',
  skuId: null,
  voucherId: null,
  gameMerchant: '',
  voucherType: null,
  cssVis: false,
});

const [countDownValue, setCountDownValue] = useState({
  limitTime: 0,
  hour: 0,
  min: 0,
  s: 0,
});

let timer = useRef();

useEffect(() => {
  return () => timer.current && clearInterval(timer.current);
}, []);

  const getVoucherDetails = (voucherId) => {
    APIGetVoucherInfo(JSON.stringify(
      {
        voucherId: voucherId
      }
    )).then(resp => {

      if (resp.result.postEndTime > new Date().getTime()){
        startVoucherTimer(resp.result.postEndTime);
        setVoucherStatus({
          ...voucherState,
          name: resp.result.name,
          discount: resp.result.discount,
          skuId: resp.result.skuId,
          gameAlias: resp.result.gameAlias,
          gameMerchant: resp.result.gameMerchant,
          currency: resp.result.currency,
          currencyUnit: resp.result.currencyUnit,
          voucherType: resp.result.type,
          voucherId,
          cssVis: true,
        });
      }
    });
  };

const startVoucherTimer = (endTime) => {
  timer.current = setInterval(() => {
    const limitTime = ( endTime - new Date().getTime()) / 1000;
    const seconds = limitTime % 60;
    const minutes = parseInt(limitTime / 60) % 60;
    const hours = limitTime / (60 * 60);
    setCountDownValue({
      limitTime: limitTime,
      hour: parseInt(hours),
      min: parseInt(minutes),
      s: parseInt(seconds),
    });
  }, 1000);
};

const getMyCoins = () => {
  APIGetIntegralAccountPage().then(resp => {
    if (resp.result){
        dispatchAction.setCoinsBalance(resp.result && resp.result.balance)
        dispatchAction.setExpiringPoints(resp.result ? resp.result.expired : 0)
    }
  });
};

const getIGMBalance = () => {
  APIGetIGMBalance().then(resp => {
    if (resp.result) {
      dispatchAction.setIGMBalance(resp.result ?? {});
      dispatchAction.setAllowPointsExchangeIGM(resp.result && resp.result.allowPointsExchange);
    }
  });
};

const onCheckFirstCharge = () => {
  APIGetProfile().then(resp => {
    router.push('/orderPayment/7108573686512735/');
  });
};


const onIGMClick = () => {
  // router.push('/deposit')
};

const closeMenu = () => {
  console.log('closeMenu.....')
  setState({
    ...state,
    leftMenuVis: false
  })
}

const showCountryName = () => {
  let regions = router.locale;
  let countryName = '';
  switch (regions) {
    case 'en':
      countryName = "English";
      break;
    case 'en-MY':
      countryName = t('malaysia');
      break;
    case 'ja':
      countryName = "日本語";
      break;
    case 'en-PH':
      countryName = 'Philippines';
      break;
    case 'en-ID':
      countryName = 'Indonesia';
      break;
    case 'id-ID' :
      countryName = 'Indonesia';
      break;
    case 'en-BR':
      countryName = 'Brazil';
      break;
    case 'pt-BR':
      countryName = 'Brazil';
      break;
    case 'en-AE':
      countryName = 'United Arab Emirates';
      break;
    case 'en-EG':
      countryName = 'Egypt';
      break;
    case 'ja-JP':
      countryName = "日本語";
      break;
    case 'en-SA':
      countryName = 'Saudi Arabia';
      break;
    case 'en-QA':
      countryName = 'Qatar';
      break;
    default:
      countryName = 'English';
      break;
  }
  return countryName;
};

const onTabChange = (index) => {
  switch (index) {
    case 0:
      router.push('/');
      break;
    case 1:
      router.push('/discover');
      break;
    case 2:
      router.push('/generate');
      break;
  }
};

  const showCountryFlag = () => {
    let regions = router.locale;
    let countryName = '';
    switch (regions) {
      case 'en':
        countryName = `https://cdn.igamie.com/static/en-sg.svg`;
        break;
      case 'en-MY':
        countryName = 'https://cdn.igamie.com/static/en-my.svg';
        break;
      case 'en-PH':
        countryName = 'https://cdn.igamie.com/static/en-ph.svg';
        break;
      case 'en-ID':
        countryName = 'https://cdn.igamie.com/static/id-id.svg';
        break;
      case 'id-ID' :
        countryName = 'https://cdn.igamie.com/static/id-id.svg';
        break;
      case 'en-BR':
        countryName = 'https://cdn.igamie.com/static/en-br.svg';
        break;
      case 'pt-BR':
        countryName = 'https://cdn.igamie.com/static/en-br.svg';
        break;
      case 'en-AE':
        countryName = 'https://cdn.igamie.com/static/en-ae.svg';
        break;
      case 'en-EG':
        countryName = 'https://cdn.igamie.com/static/en-eg.svg';
        break;
      case 'ja-JP':
        countryName =  'https://static-img.igamie.com/static/Japan.svg';
        break;
      case 'en-SA':
        countryName = 'https://cdn.igamie.com/static/en-sa.svg';
        break;
      case 'en-QA':
        countryName = 'https://cdn.igamie.com/static/en-qa.svg';
        break;
      default:
        countryName = 'https://cdn.igamie.com/static/en-sg.svg';
        break;
    }
    return countryName;

  }

const setHeaderHide = () => {
  window.addEventListener('scroll', () => {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    setIsHide(scrollTop > 100)
  })
}

  const getHourMin = (str) =>{
    let hour = parseInt(str.split(" ")[1].split(":")[0]);
    let min = str.split(" ")[1].split(":")[1];
    return hour >= 12 ? (hour - 12).toString() + ":" + min + " pm" : hour.toString() + ":" + min + ' am';
  };

  const getNoticeList = ()=>{
    setNoticeLoading(true)
    APIGetUserLastMessageList({}).then(resp=>{
      if (resp.resultCode === 200) {
        setNoticeList(resp.result);
      }
    }).finally(()=>{
      setNoticeLoading(false);
    });
  };
  const gotoNotice = (e, type)=>{
    e.stopPropagation();
    router.push({
      pathname:'/notice',
      query:{
        messageType:type
      }
    });
  };

  const changeJPText = (en, jp) => {
    if (router.locale === 'ja') {
      if (jp) {
        return jp;
      }
      return en;
    }
    return en
  };

  const onAndroidDownloadBtnClick = (e) => {
    APIGetAndroidDownloadLink().then(resp => {
      if (resp.success) {
        window.location.href = resp.result.url;
      }
    });
  };

useEffect(() => {
  setHeaderHide()
}, [])

useEffect(() => {
  getMyCoins();
  getIGMBalance();
}, []);

useEffect(() => {
  getNoticeList();
}, [userInfo])

  return (
        <>
          <MobileDrawer
            closeDrawer={() => setState({ ...state, largetMenuVis: false })}
            userInfo={userInfo}
            coinsBalance={coinsBalance}
            visible={state.largetMenuVis}>
          </MobileDrawer>
          <Drawer
            closable={false}
            className='mobile-login-menu-new mobile-login-menu-new-aside'
            placement='right'
            visible={state.leftMenuVis}
            onClose={ () => { setState({ ...state, leftMenuVis: false }) } }
          >
            <Aside walletClick={() => {
              setChooseWalletModal(true)
            }} inDrawer={true} closeMenu={ closeMenu } isMobileN={true}  />

          </Drawer>
          <WalletChooseModal visible={chooseWalletModal} onCancel={() => setChooseWalletModal(false)} />
          <LoginBindTip />
          <SettingModal vis={settingVis} close={() => setSettingVis(false)} />
         {
           mobileState.isMobile ?
           <div className={ isLogin() ? `back-icon-wrapper  login-nav-wrapper` : `back-icon-wrapper` }>
              <div className='flex-row-center'>
                <span className='mobile-header-logo-new' onClick={ () => { router.push('/') } } ></span>
                <a href={'https://discord.com/invite/R3XQq9aY8s'} target={'_blank'} className={'mobile-discord-icon'}></a>
              </div>
              <div className='mobile-nav-right'>
                <div onClick={onCheckFirstCharge} className={'first-charge-wra'}>
                  <div className={'first-charge-icon'}></div>
                  {/* <div className={'text-first'}>$1 Offer</div>*/}
                </div>
                <div onClick={onIGMClick} className={'header-igm-box'}>
                    <div className={'ruby-icon-m'}></div>
                    <div className={'igm-number'}>{numFormatNotRound(igmBalance ?? 0)}</div>
                </div>
                {
                  (userInfo && userInfo.id) && (
                    <Dropdown overlay={ mobileMenu() } trigger={"click"} destroyPopupOnHide={true}   arrow={true} overlayClassName='login-menu-1 tanslate-menu dropdown-menu-away'>
                       {/* <Avatar className='coin-logo-i-2 m-header-dropdown' style={{width:'.5rem', height:'.5rem', marginLeft:'.3rem' }} src={userInfo.headPic} />*/}
                       <Avatar className='coin-logo-i-2 m-header-dropdown' style={{width:'.5rem', height:'.5rem', marginLeft:'.3rem' }} src={defaultHeadPic} />
                    </Dropdown>
                  )
                }
                <div className='flex-row-center'>
                  <span className='header-menu-icon' onClick={ () => { setState({ ...state, leftMenuVis: true }) } }>
                    <span className='style-menu-line style-menu-line-1'></span>
                    <span className='style-menu-line style-menu-line-2'></span>
                    <span className='style-menu-line style-menu-line-3'></span>
                  </span>
                </div>
              </div>
           </div>
          :
          <header id='header_bar' className={isHide ? ' nav-bar animated fadeIn nav-active-color' : ' nav-bar animated fadeIn'} >
            <div className='nav-con'>
              <div className={'imomo-logo-wrapper'}>
                <div onClick={() => {
                  window.location.href = "/";
                }} style={{color: 'white'}} className={'imomo-logo'}></div>

                {/* <a target={'_blank'} href={'https://apps.apple.com/sg/app/imomo/id6452010316'}>*/}
                {/*  <div className={"header_ios_icon"}></div>*/}
                {/* </a>*/}

                <div onClick={onAndroidDownloadBtnClick} className={"header_android_icon"}></div>

              </div>

              <div className={isLogin() ? 'display-none' : 'nav-right-con'}>
              {/* <div onClick={onCheckFirstCharge} className={'first-charge-wra'}> */}
                {/*   <div className={'first-charge-icon'}></div> */}
                {/*   /!* <div className={'text-first'}>$1 Offer</div>*!/ */}
                {/* </div> */}
                <div className={'header-tab-warpper'}>
                  {/* <Link href={`/`}> */}
                  {/*   <a aria-label='home' className={`header-tab-item ${router.pathname === '/' && "header-tab-item-active"}`}>{t('imomoHome')}</a> */}
                  {/* </Link> */}
                  <Link href={`/discover`}>
                    <a aria-label='discover' className={`header-tab-item ${(router.pathname === '/discover') && "header-tab-item-active"}`}>{t('imomoDiscover')}</a>
                  </Link>
                  <Link href={`/generate`}>
                    <a aria-label='generate' className={`header-tab-item ${router.pathname === '/generate' && "header-tab-item-active"}`}>{t('imomoGenerate')}</a>
                  </Link>
                  <Link href={`/mine`}>
                    <a aria-label='earn' className={`header-tab-item ${router.pathname === '/mine' && "header-tab-item-active"}`}>{t('Earn')}</a>
                  </Link>
                  <Link href={`/character`}>
                    <a aria-label='character' className={`header-tab-item ${router.pathname === '/character' && "header-tab-item-active"}`}>{t('Companion')}</a>
                  </Link>
                  {/* <div onClick={() => onTabChange(0)} className={`header-tab-item ${router.pathname === '/' && "header-tab-item-active"}`}> {t('imomoHome')}</div>
                  <div onClick={() => onTabChange(1)} className={`header-tab-item ${router.pathname === '/discover' && "header-tab-item-active"}`}>{t('imomoDiscover')}</div>
                  <div onClick={() => onTabChange(2)} className={`header-tab-item ${router.pathname === '/generate' && "header-tab-item-active"}`}>{t('imomoGenerate')}</div> */}
                </div>
                {
                  userInfo && userInfo.id ?
                    <>
                      <a>
                        <div className={'header-igm-box'}>
                          <div className={'ruby-icon'}></div>
                          <div className={'igm-number'}>{numFormatNotRound(igmBalance ?? 0)}</div>
                        </div>
                      </a>
                      {/* <Link href={`/deposit`}>*/}

                      {/* </Link>*/}
                    </> : null
                }
                <Dropdown destroyPopupOnHide={true} className='language-menu-dropdown' overlay={languageMenu()}
                          arrow={true} overlayClassName='login-menu-1 tanslate-menu dropdown-menu-away'>
                  <a className='ant-dropdown-link' onClick={ e => e.preventDefault() }>
                    <span style={{marginRight: 10}} className={`${router.locale}-icon`}></span>
                    {/* <span className='selected-language'>{showCountryName()}</span>
                    <img src='https://cdn.igamie.com/static/sc_arr.png' className='selectTri' /> */}
                  </a>
                </Dropdown>
                {
                  userInfo && userInfo.id ?
                    <Popover className='notice-popover-i' placement='bottomRight'  overlayClassName='notice-popover' content={
                      <div >
                        {
                          noticeLoading ?
                            <div className='language-empty'>
                              <Spin indicator={antIcon1} />
                            </div>
                            : noticeList.length === 0 ? <div className={"notice-menu"}>
                            <div className='notice-menu-title'>No information available at the moment</div>
                            </div> : <div className='language-menu' ref={domp1}>
                              <div className='notice-menu'>
                                <div className='notice-menu-title'>{t("notification")}</div>
                                <div>
                                  {
                                    noticeList.map((item, index) => {
                                      return (
                                        <div onClick={(e)=>gotoNotice(e, item.businessType)} className='notice-item'  key={index}>
                                          <div className='notice-item-title'>
                                            <div>{changeJPText(item.title, item.titleJp)}</div>
                                            <div className={item.isRead === 0 && count !== 0 ? 'notice-item-tips' : ''}></div>
                                          </div>
                                          <span className='notice-item-date' >{getHourMin(item.gmtCreate) + ", " + getYearMonthDayTimeStampNew(new Date(item.gmtCreate).getTime())}</span>
                                        </div>
                                      );
                                    })
                                  }
                                </div>
                                <div onClick={(e)=>gotoNotice(e, 2)} className='notice-view'>
                                  <span className='notice-view-btn'>{t("viewAll")}</span>
                                </div>
                              </div>
                            </div>
                        }
                      </div>
                    }>
                      <Badge dot  size={"small"}>
                        <span className='notice-icon'></span>
                        {/* <BellFilled   className='language-icon bellfilled' /> */}
                      </Badge>
                    </Popover> : null
                }

                {
                userInfo && userInfo.id ?
                <Dropdown overlay={ pcLoginMenu() } destroyPopupOnHide={true}   arrow={true} overlayClassName='login-menu-1 tanslate-menu dropdown-menu-away'>
                  <div className='login-avatar-name'>
                    {/* <OssImg src={userInfo.headPic} className='avatar-circle'  />*/}
                    <OssImg src={defaultHeadPic} className='avatar-circle'  />
                    <div>
                      <a className='ant-dropdown-link unset-link' onClick={ e => e.preventDefault() }>
                        <span className='hi-nickname ml-9'>{t('imomoHi')}{userInfo.nickName ? userInfo.nickName : userInfo.email ?  userInfo.email.split('@')[0] : userInfo.mobilePhone ? userInfo.mobilePhone : ''  } </span>
                      </a>
                      <div>
                        {
                          userInfo && userInfo.isBindWallet ?
                            <span onClick={()=>copyWallet(userInfo.walletAddress)} style={{fontSize:"12px", opacity:"0.79"}} className='wallet-address ml-9 pointer'>{splitWalletAddress(userInfo.walletAddress)}</span>
                            :
                            <span onClick={()=>setChooseWalletModal(true)} style={{fontSize:"14px"}} className='wallet-address ml-9 pointer gold-color'>{t('imomoConnectWallet')}</span>
                        }
                      </div>
                    </div>
                  </div>
                </Dropdown>
                :


                  <div className={"header_get_started_btn"} onClick={ () => {
                    loginShowModal.step = 0;
                    dispatchAction.setLoginShowModal(true);
                  }}>
                    {t("Get started")}
                  </div>

                  // <>
                  //   <a className='ant-dropdown-link login-menu-ty' onClick={ () => {
                  //     loginShowModal.step = 1;
                  //     dispatchAction.setLoginShowModal(true)
                  //   } }>
                  //     <div className='user-logout-wrapper-login'>
                  //       <span className='user-logout-txt'>{t("login")}</span>
                  //     </div>
                  //   </a>
                  //   <a className='ant-dropdown-link login-menu-ty' onClick={ () => {
                  //     loginShowModal.step = 8;
                  //     dispatchAction.setLoginShowModal(true)
                  //   } }>
                  //     <div className='user-logout-wrapper'>
                  //       <span className='user-logout-txt'>{t("signUp")}</span>
                  //       {/* <span className='user-logout-line'></span>*/}
                  //     </div>
                  //   </a>
                  //
                  // </>


              }

                {
                  // <Link href={`/home`}>
                  //   <div className={"home_header_icon"}></div>
                  // </Link>
                }

              </div>

            </div>
          </header>
         }
        </>
  );
};

export default Header;
