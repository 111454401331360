import React, {useEffect, useRef, useState} from "react";
import {Layout, Dropdown, Menu, Space, Tooltip, Popover, Avatar} from "antd";
import styles from './index.module.scss'
import {t} from "i18next";
import { DownOutlined } from '@ant-design/icons';
import router, { useRouter } from "next/router";
import { useSelector } from 'react-redux';
import Link from 'next/link'
import useDispatchAction from '@/hooks/useDisptachAction';
import { setCollapsedOpen, setCollapsedClose, setLoginShowModal } from '@/redux/actions/home';
import { useTranslation, Trans, Translation } from 'react-i18next';
import { isMobile } from "@/tools/action";
import {splitWalletAddress} from "@/tools/walletConnect";
import {loginShowModal} from "@/tools/dealResult";
import {APIGetAndroidDownloadLink} from "@/api";


const { Sider } = Layout




const Home = ({ inDrawer, closeMenu, walletClick, isMobileN  }) => {
    const router = useRouter()
    let { t, i18n} = useTranslation();
    const collapsed = useSelector(state => state.home.collapsed && state.home.collapsed.open)
    const dispatchAction = useDispatchAction({setCollapsedClose, setCollapsedOpen, setLoginShowModal});
    const userInfo = useSelector(state => state.userInfo.userInfo.userInfo);
    const [languageOpen, setLanguageOpen] = useState(false)

  const popoverRef = useRef(null);

  useEffect(() => {
    setLanguageOpen(false)
  }, [collapsed])

    const menuList = [
    {
      icon:'http://static-img.igamie.com/webMiniGames/webSprite.png',
      name:t("imomoHome"),
      routerName:'/home'
    },
    // {
    //   icon:'http://static-img.igamie.com/webMiniGames/webSprite.png',
    //   name:t("miniGames"),
    //   routerName:'/allMiniGames'
    // },
    // {
    //   icon:'http://static-img.igamie.com/webMiniGames/webSprite.png',
    //   name:'Mystery Box',
    //   routerName:'/mysteryBoxs'
    // },
    {
      icon:'http://static-img.igamie.com/webMiniGames/webSprite.png',
      name: t('imomoDiscover'),
      routerName:'/discover'
    },

    {
      icon:'http://static-img.igamie.com/webMiniGames/webSprite.png',
      name: t('imomoGenerate'),
      routerName:'/generate'
    },

    {
      icon:'http://static-img.igamie.com/webMiniGames/webSprite.png',
      name: t('Character'),
      routerName:'/character'
    },

    {
      icon:'http://static-img.igamie.com/webMiniGames/webSprite.png',
      name: t('Earn'),
      routerName:'/mine'
    },
]


    // const [state, setState] = useState({
    //     menuOpen: false
    // })

    // 菜单开关
    const menuToggle = () => {
        console.log('collapsed', collapsed)
        if (collapsed) {
            dispatchAction.setCollapsedClose()
        } else {
            dispatchAction.setCollapsedOpen()
        }

    }

    const items = [
        {
          label: <a href='https://www.antgroup.com'>1st menu item</a>,
          key: '0',
        },
        {
          label: <a href='https://www.aliyun.com'>2nd menu item</a>,
          key: '1',
        },
        {
          type: 'divider',
        },
        {
          label: '3rd menu item',
          key: '3',
        },
      ];

    const languageList = [
        {key:'en', content: "English"},
        // {key:'en-MY', content:t("malaysia")},
        // {key:'en-PH', content:t("philippines")},
        // {key:'id-ID', content:t("indonesia")},
        // {key:'pt-BR', content:t("brazil")},
        // {key:'en-EG', content:t("egypt")},
        {key:'ja', content: "日本語"},

    ]

    // 国家语言
    const languageMenu = () => (
        <Menu>
          <Menu.Item key='en'><img className='region-flag' src='https://cdn.igamie.com/static/en-sg.svg'/> <span className='context'>{t("singapore")}</span>  </Menu.Item>
          {/* <Menu.Item key='en-MY'><img className='region-flag' src='https://cdn.igamie.com/static/en-my.svg'/> <span className='context'>{t("malaysia")}</span> </Menu.Item>*/}
          {/* <Menu.Item key='en-PH'><img className='region-flag' src='https://cdn.igamie.com/static/en-ph.svg'/> <span className='context'>{t("philippines")}</span> </Menu.Item>*/}
          {/* <Menu.Item key='id-ID'><img className='region-flag' src='https://cdn.igamie.com/static/id-id.svg'/> <span className='context'>{t("indonesia")}</span> </Menu.Item>*/}
          {/* <Menu.Item key='pt-BR'><img className='region-flag' src='https://cdn.igamie.com/static/en-br.svg'/> <span className='context'>{t("brazil")}</span> </Menu.Item>*/}
          {/* <Menu.Item key='en-EG'><img className='region-flag' src='https://cdn.igamie.com/static/en-eg.svg'/> <span className='context'>{t("egypt")}</span> </Menu.Item>*/}
          <Menu.Item key='ja-JP'><img className='region-flag' src='https://static-img.igamie.com/static/Japan.svg'/> <span className='context'>{t("japan")}</span> </Menu.Item>
        </Menu>
    );

    // 显示国家国旗
    const showCountryFlag = () => {
        let regions = router.locale;
        let countryName = '';
        switch (regions) {
          case 'en':
            countryName = `https://cdn.igamie.com/static/en-sg.svg`;
            break;
          case 'en-MY':
            countryName = 'https://cdn.igamie.com/static/en-my.svg';
            break;
          case 'en-PH':
            countryName = 'https://cdn.igamie.com/static/en-ph.svg';
            break;
          case 'en-ID':
            countryName = 'https://cdn.igamie.com/static/id-id.svg';
            break;
          case 'id-ID' :
            countryName = 'https://cdn.igamie.com/static/id-id.svg';
            break;
          case 'en-BR':
            countryName = 'https://cdn.igamie.com/static/en-br.svg';
            break;
          case 'pt-BR':
            countryName = 'https://cdn.igamie.com/static/en-br.svg';
            break;
          case 'en-AE':
            countryName = 'https://cdn.igamie.com/static/en-ae.svg';
            break;
          case 'en-EG':
            countryName = 'https://cdn.igamie.com/static/en-eg.svg';
            break;
          case 'ja-JP':
            countryName =  'https://static-img.igamie.com/static/Japan.svg';
            break;
          case 'en-SA':
            countryName = 'https://cdn.igamie.com/static/en-sa.svg';
            break;
          case 'en-QA':
            countryName = 'https://cdn.igamie.com/static/en-qa.svg';
            break;
          default:
            countryName = 'https://cdn.igamie.com/static/en-sg.svg';
            break;
        }
        return countryName;

    }

    // 显示国家名称
    const showCountryName = () => {
        console.log('sjsjsjsjsjsj', router)
        let regions = router.locale;
        let countryName = '';
        switch (regions) {
          case 'en':
            // countryName = t('singapore');
            countryName = 'English'
            break;
          case 'en-MY':
            // countryName = 'Malaysia';
            countryName = 'MY'
            break;
          case 'en-PH':
            // countryName = 'Philippines';
            countryName = 'PH'
            break;
          case 'en-ID':
            // countryName = 'Indonesia';
            countryName = 'ID'
            break;
          case 'id-ID' :
            // countryName = 'Indonesia';
            countryName = 'ID'
            break;
          case 'en-BR':
            // countryName = 'Brazil';
            countryName = 'BR'
            break;
          case 'pt-BR':
            // countryName = 'Brazil';
            countryName = 'BR'
            break;
          case 'en-AE':
            // countryName = 'United Arab Emirates';
            countryName = 'UA'
            break;
          case 'en-EG':
            // countryName = 'Egypt';
            countryName = 'EG'
            break;
          case 'ja':
            // countryName =  t('japan');
            countryName = '日本語'
            break;
          case 'en-SA':
            // countryName = 'Saudi Arabia';
            countryName = 'SA'
            break;
          case 'en-QA':
            // countryName = 'Qatar';
            countryName = 'QA'
            break;
          default:
            // countryName = 'Singapore';
            countryName = 'English'
            break;
        }
        return countryName;
      };

    const changeLanguage = (item) => {
        console.log('item', item)

      console.log('ref:', popoverRef);

        console.log('222router', router)
        setLanguageOpen(false)
        let itemkey = item.key
        let languageNow = router.locale
        let pathname = router.asPath
        if (itemkey === languageNow) {
            return
        }
        if (itemkey === 'en-ID' || itemkey === 'id-ID') {
          i18n.changeLanguage('id');
        } else if (itemkey === 'en-BR' || itemkey === 'pt-BR') {
           i18n.changeLanguage('pt');
        } else if (itemkey === 'ja') {
            i18n.changeLanguage('ja');
        } else {
           i18n.changeLanguage('en');
        }
        // router.replace(`${pathname}`, `${pathname}`, { locale: itemkey })
        // router.replace(`/`, `/`, { locale: itemkey })
        localStorage.setItem('regions', itemkey);
        window.location.href = '/'
        // closeMenu()
    }

    const computedWindowWidth = () => {
      console.log('12121')
      window.addEventListener('resize', () => {
        if (window.innerWidth > 1500) {
          if (!collapsed) {
            dispatchAction.setCollapsedOpen()
          }
        } else {
          if (collapsed && !isMobile()) {
            dispatchAction.setCollapsedClose()
          }
        }
      })
    }

    const initwindowWidth = () => {
      if (window.innerWidth > 1500) {
        if (!collapsed) {
          dispatchAction.setCollapsedOpen()
        }
      } else {
        if (collapsed && !isMobile()) {
          dispatchAction.setCollapsedClose()
        }
      }
    }

  const onAndroidDownloadBtnClick = (e) => {
    APIGetAndroidDownloadLink().then(resp => {
      if (resp.success) {
        window.location.href = resp.result.url
      }
    })
  };

    useEffect(() => {
      computedWindowWidth()
    }, [])

    useEffect(() => {
      initwindowWidth()
    }, [])

    return (
        // <Layout className={`animated fadeIn profile-page`}>
        //     home
        // </Layout>
        // <aside className={ state.menuOpen ? `${styles.container} ${styles.menu_open}` : `${styles.container} ${styles.menu_close}` }>

        // </aside>
        <Sider className={inDrawer ? `aside ${styles.aside} ${styles.drawer_aside}` : `aside ${styles.aside}`} trigger={null} collapsible  width={ collapsed ? 265 : 96 }>
            <div className='logo' style={{ marginBottom:'30px', marginTop:'22px', textAlign:'center', position:'relative' }}>
              <span onClick={ closeMenu }  className={ styles.collapse_btn_close_new}></span>
              {
                !isMobileN ?
                <a  rel='noopener noreferrer' onClick={ () => { router.push('/') } }>
                  <img className={collapsed ? styles.logo_icon : styles.logo_icon_small}  src={collapsed ? `https://cdn.igamie.com/static/footer_logo.png` : `https://static-img.igamie.com/favicon.ico`} />
                </a> : null
              }
              <div className={styles.user_wrapper}>
                {
                  userInfo && userInfo.id ?
                    <div className={styles.user_icon}>
                      <div className={styles.name_wrapper}>
                        <span className={styles.user_name}>{userInfo.nickName}</span>
                        {
                          userInfo.isBindWallet ? (
                            <span className={styles.user_wallet}>{splitWalletAddress(userInfo.walletAddress)}</span>
                          ) : (
                            <span onClick={() => walletClick?.()} className={styles.user_not_wallet}>Connect Wallet</span>
                          )
                        }

                      </div>
                      <Avatar className='coin-logo-i-2' onClick={ () => {
                        // closeMenu?.();
                        // router.push(`/m-profile`);
                        // router.push(`/personal-center/${userInfo.id}/`);
                      } }  style={{width:'.8rem', height:'.8rem', marginLeft:'.3rem' }} src={userInfo.headPic} />
                    </div>
                    :
                    <>
                      <a className='ant-dropdown-link login-menu-ty' onClick={ () => {
                        closeMenu?.();
                        loginShowModal.step = 1;
                        dispatchAction.setLoginShowModal(true)
                        // router.push(`/login?redirect=${location.href}`);
                      } }>
                        <div className='user-logout-wrapper-login'>
                          <span className='user-logout-txt'>{t('login')}</span>

                        </div>
                      </a>
                      <a className='ant-dropdown-link login-menu-ty' onClick={ () => {
                        closeMenu?.();
                        loginShowModal.step = 8;
                        dispatchAction.setLoginShowModal(true)
                        // router.push(`/login?redirect=${location.href}`);
                      } }>
                        <div className='user-logout-wrapper'>
                          <span className='user-logout-txt'>{t('signUp')}</span>
                          {/* <span className='user-logout-line'></span>*/}
                          {/* <span className='user-logout-txt'>{t('login')}</span>*/}

                        </div>
                      </a>
                    </>

                }
              </div>
            </div>

            <div className={`aside-content ${styles.aside_content}` }>
              <div>
                    <div>
                      <ul className={styles.menu_list}>
                          {
                              menuList.map((item, index) => {
                                  return (
                                    collapsed ?
                                    <li key={index} className={styles.menu_item_li} >
                                      <Link  prefetch href={item.routerName}>
                                          <a title={item.name} onClick={ closeMenu }  className={ item.routerName === router.pathname ? `${styles.menu_item} ${styles.menu_item_active} aside-menu-item` : `${styles.menu_item} aside-menu-item` }>
                                                  <span className={`${styles.menu_icon} ${index === 0 ? styles.menu_market  : index === 1 ? styles.menu_minigames : index === 2 ? styles.menu_mysterybox : index === 3 ? styles.menu_airdrop : index === 4 ? styles.menu_gamiezone : '' }`}></span>
                                                  <span className={`${styles.menu_name} aside-menu-name` }>{item.name}</span>
                                          </a>
                                      </Link>
                                    </li> :
                                    <Tooltip title={item.name} placement='rightTop' overlayClassName='menu-tooltip' >
                                      <li key={index} className={styles.menu_item_li} >
                                        <Link  href={item.routerName} prefetch >
                                            <a onClick={ closeMenu }  className={ item.routerName === router.pathname ? `${styles.menu_item} ${styles.menu_item_active} aside-menu-item` : `${styles.menu_item} aside-menu-item` }>
                                                    <span className={`${styles.menu_icon} ${index === 0 ? styles.menu_market  : index === 1 ? styles.menu_minigames : index === 2 ? styles.menu_mysterybox : index === 3 ? styles.menu_airdrop : index === 4 ? styles.menu_gamiezone : '' }`}></span>
                                                    <span className={`${styles.menu_name} aside-menu-name` }>{item.name}</span>
                                            </a>
                                        </Link>
                                      </li>
                                    </Tooltip>
                                  )
                              })
                          }
                      </ul>
                    </div>
                    <div className={collapsed ? `${styles.collapse_wrapper_open} ${styles.collapse_wrapper}` : `${styles.collapse_wrapper_close} ${styles.collapse_wrapper}`  }>
                      {
                        !inDrawer ? <span onClick={ menuToggle }  className={ collapsed ? styles.collapse_btn : styles.collapse_btn_close}></span> : null
                      }
                    </div>
                    <div className={styles.community_wrapper}>
                        {/* <div className={`${styles.community_title} aside-title`}>COMMUNITY</div> */}
                        {/* <div className={`${styles.community_flex} aside-community-flex`}>*/}
                        {/*    <a href='https://discord.com/invite/rKkduXeSSm' title='Discord' target={`_blank`}>*/}
                        {/*        <div className={`${styles.community_item} aside-community-item`}>*/}
                        {/*            <span className={styles.discord_icon}></span>*/}
                        {/*            <span className={`${styles.name} name`}>Discord</span>*/}
                        {/*        </div>*/}
                        {/*    </a>*/}
                        {/*    <a href='https://twitter.com/igamie_global' title='twitter' target={`_blank`}>*/}
                        {/*        <div className={`${styles.community_item} aside-community-item`}>*/}
                        {/*            <span className={styles.twitter_icon}></span>*/}
                        {/*            <span className={`${styles.name} name`}>Twitter</span>*/}
                        {/*        </div>*/}
                        {/*    </a>*/}
                        {/*    <a href='https://t.me/igamie_official' title='Telegram' target={`_blank`}>*/}
                        {/*        <div className={`${styles.community_item} aside-community-item`}>*/}
                        {/*            <span className={styles.telegram_icon}></span>*/}
                        {/*            <span className={`${styles.name} name`}>Telegram</span>*/}
                        {/*        </div>*/}
                        {/*    </a>*/}
                        {/*    <a href='https://www.facebook.com/igamie.global' title='Facebook' target={`_blank`}>*/}
                        {/*        <div className={`${styles.community_item} aside-community-item`}>*/}
                        {/*            <span className={styles.facebook_icon}></span>*/}
                        {/*            <span className={`${styles.name} name`}>Facebook</span>*/}
                        {/*        </div>*/}
                        {/*    </a>*/}
                        {/* </div>*/}
                    </div>
              </div>

                <div className={styles.bottom_wrapper}>
                  {/* <div className={`${styles.download_app_wrapper} download-app-wrapper`}>*/}
                  {/*    <a target={`_blank`} href='https://play.google.com/store/apps/details?id=com.igamie_zone.abcomo'>*/}
                  {/*      <div className={`${styles.google_download_app} aside-download-app`  }>*/}
                  {/*        <span className={`${styles.download_icon} aside-download-app-icon`}></span>*/}
                  {/*      </div>*/}
                  {/*    </a>*/}
                  {/* </div>*/}
                  <div className={styles.language_select_wrapper}>
                    <div className={`${styles.language_select} aside-language-select` } onClick={ () => { setLanguageOpen(!languageOpen) } }>
                      <Popover ref={popoverRef} getPopupContainer={ node => isMobileN ? document.body : document.getElementById('language-select-w')  }   placement='bottom' title={null} trigger={'click'} overlayClassName='language-popover' content={
                        <div className='menu-overlay'>
                          {
                            languageList.map((item, index) => {
                              return (
                                <div onClick={ () => { changeLanguage(item) } }  className={`${styles.language_item} language-item `} key={index} ><div style={{marginRight: 10}} className={`${item.key}-icon`}></div>{item.content}</div>
                              )
                            })
                          }
                        </div>
                      }>
                        <div className={`${styles.language_content} language-content` }>
                          <div className={`${router.locale}-icon`}></div>
                          <span className={`${styles.name} language-name`  }>{showCountryName()}</span>
                        </div>
                        <span className={!languageOpen ? `${styles.arrow} language-arrow `  : `${styles.arrow} ${styles.arrow_rotate} language-arrow`}></span>
                      </Popover>
                    </div>
                  </div>
                  <div className={styles.download_app_wrapper}>
                    {/* <a target={'_blank'} href={'https://apps.apple.com/sg/app/imomo/id6452010316'}>*/}
                    {/*  <div className={styles.apple_download}></div>*/}
                    {/* </a>*/}
                    {/* <div onClick={onAndroidDownloadBtnClick} className={styles.android_download}></div>*/}
                  </div>
                </div>
            </div>
            <div id='language-select-w'></div>
        </Sider>
    )

}

export default Home
