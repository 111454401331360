import {
  SETHIDEAPPDOWNLOAD
} from '@/constants/ActionTypes';

const initialState = {
  hideAppDownload: true
};

const hideAppDownload = (state = initialState, { type, payload }) => {
  switch (type) {
    case SETHIDEAPPDOWNLOAD: {
      return {
        ...state,
        hideAppDownload: payload
      };
    }
    default:
      return state;
  }
};

export default hideAppDownload;
