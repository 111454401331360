import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import Img from '../components/Img'
import {useTranslation} from "react-i18next";
import {APIGetAndroidDownloadLink} from "@/api";

const Footer = () => {
  let { t, i18n} = useTranslation();


  const onAndroidDownloadBtnClick = (e) => {
    APIGetAndroidDownloadLink().then(resp => {
      if (resp.success) {
        window.location.href = resp.result.url
      }
    })
  };


    return (
       <footer className='footer'>
          <div className='footer-wrapper'>
            <div className='footer-left'>
                <div className='footer-logo-wrapper'>
                    {/* <Img src='https://cdn.igamie.com/static/footer_logo.png' />*/}
                  <div className={'imomo-footer-logo'}></div>
                  {/* <a target={'_blank'} href={'https://apps.apple.com/sg/app/imomo/id6452010316'}>*/}
                  {/*  <div className={'g_apple_download'}></div>*/}
                  {/* </a>*/}
                  {/* <div onClick={onAndroidDownloadBtnClick} className={'g_android_download'}></div>*/}
                </div>
                <div className='copyright-wrapper'>
                    <span title='©Copyright imomo 2023'>©Copyright imomo 2023</span>
                    <a title='Terms & Conditions of user' target={`_blank`} href='https://imomo.ai/imomo_terms.html'>{t("termsConditions")}</a>
                    <a title='Privacy Policy' target={`_blank`} href='https://imomo.ai/imomo_privacy_policy.html'>{t("privacyPolicy")}</a>
                    {/* <a title='iGamie Rewards Terms of Service' target={`_blank`} href='https://static-img.igamie.com/config/igamie_rewards_terms_of_service.html'>{t("rewardTermCondition")}</a>*/}
                </div>
            </div>
            {/* <div className='footer-right'>*/}
            {/*    <div className='footer-link-wrapper'><a title='Help Center' target={`_blank`} href='https://igamie.zendesk.com/hc/en-us'>{t("helpCenter")}</a> </div>*/}
            {/*    <div className='footer-link-wrapper'><a title='About Us' target={`_blank`} href='https://igamie.zendesk.com/hc/en-us/articles/4418202382233-About-iGamie'>{t("aboutUs")}</a></div>*/}
            {/*    <div className='footer-link-wrapper'><a title='iGamie Blog' target={`_blank`} href='https://blog.igamie.com/'>{t("igamieBlog")}</a></div>*/}
            {/*    <div className='footer-link-wrapper'><a title='Apply to List' target={`_blank`} href='https://forms.gle/LhNBUzQBsVFBvqFy9'>{t("applyToList1")}</a></div>*/}
            {/* </div>*/}
            <div className='footer-bottom'>
                <div className='footer-bottom-item'><span title='©Copyright imomo 2023'>©Copyright imomo 2023</span></div>
                <div className='footer-bottom-line'></div>
                <div className='footer-bottom-item'><a title='Terms & Conditions' target={`_blank`} href='https://imomo.ai/imomo_terms.html'>{t("termsConditions")}</a></div>
                <div className='footer-bottom-item'><a title='Terms & Conditions' target={`_blank`} href='https://imomo.ai/imomo_privacy_policy.html'>{t("privacyPolicy")}</a></div>
            </div>

          </div>
       </footer>
    )

}

export default Footer
