import { SETUSERIGMBALANCE } from "@/constants/ActionTypes";

const initialState = {
  igmBalance: 0,
  igmToUsdt: 0.00,
  firstCharge: 0,
}

const setIGMBalance = (state = initialState, { type, payload }) => {
  switch (type) {
    case SETUSERIGMBALANCE: {
      if (typeof payload === 'object') {
        let usdt = 0.00;
        if (payload.balance && (payload.balance) > 0) {
          usdt = (payload.balance / 400).toFixed(2);
        }
        return {
          ...state,
          firstCharge: payload.firstCharge,
          igmBalance: payload.balance,
          igmToUsdt: usdt,
        }
      }
      let usdt = 0.00;
      if (payload && (payload) > 0) {
        usdt = (payload / 400).toFixed(2);
      }
      return {
        ...state,
        igmBalance: payload,
        igmToUsdt: usdt,
      }
    }
    default:
      return state;
  }
}

export default setIGMBalance
