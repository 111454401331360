import {Drawer, message} from "antd";
import {CloseOutlined, LoadingOutlined} from "@ant-design/icons";
import Img from "@/components/Img";
import React, {useEffect, useState} from "react";
import i18n, {t} from "i18next";
import {useRouter} from "next/router";
import {APIGetProfile, APILogout} from "@/api";
import WalletChooseModal from "@/components/WalletChooseModal";
import {splitWalletAddress, walletConnect} from "@/tools/walletConnect";
import {useSelector} from "react-redux";
import useDispatchAction from "@/hooks/useDisptachAction";
import {setUserInfo} from "@/redux/actions/userInfo";
import {setLoginModalHidden, setLoginModalShow} from "@/redux/actions/home";


const MobileDrawer = ({visible, closeDrawer, coinsBalance}) =>{
  const router = useRouter();
  const [userWalletInfo, setUserWalletInfo] = useState({
    "nftNum": 0,
    "voucherNum": 0,
    "gameCodeNum": 0,
    "exclusiveCardNum": 0
  });
  const [chooseWalletModal, setChooseWalletModal] = useState(false);
  const userInfo = useSelector(state => state.userInfo.userInfo.userInfo);


  const gotoVoucher = (index) =>{
    closeDrawer();
    switch (index) {
      case 0:router.push("/myRewards/?type=active_voucher"); break;
      case 1:router.push("/myRewards/?type=exclusive_card"); break;
      case 2:router.push("/myRewards/?type=game_code"); break;
      case 3:router.push("/myNft"); break;
    }
  };

  const changeLanguage = (e) => {
    closeDrawer();
    let value = e.target.value;
    if (value === 'en-ID' || value === 'id-ID') {
      i18n.changeLanguage('id');
    } else if (value === 'en-BR' || value === 'pt-BR') {
      i18n.changeLanguage('pt');
    } else if (value === 'ja-JP') {
      i18n.changeLanguage('ja')
    } else {
      i18n.changeLanguage('en');
    }
    router.push('/', '/', { locale: value });
    localStorage.setItem('regions', value);
  };

  const gotoMyCoins = () => {
    router.push('/my-coins/');
    closeDrawer();
  };

  const gotoMyProfile = () => {
    closeDrawer();
    router.push('/profile');
  };

  const languageListMobile = [
    {name: `${t('singapore')}(EN)   S$`, value:"en", value1:'en'},
    {name: `${t('malaysia')}(EN)       RM`, value:"en-MY", value1:'en-my'},
    {name:`${t('philippines')}(EN)    ₱`, value:"en-PH", value1:'en-ph'},
    {name:`${t('indonesia')}(ID)     Rp`, value:"id-ID", value1:'id-id'},
    {name:`${t('brazil')}(BR)    R$`, value:"pt-BR", value1:"en-br"},
    // {name:"UAE", value:"ar-AE", value1:'ar-ae'},
    {name:`${t('egypt')}(EN)   EGP`, value:"en-EG", value1:'en-eg'},
    {name:`${t('japan')}(JA)`, value:'ja-JP',  value1:'ja-jp', symbol:'¥', language:"JA"}
    // {name:"Saudi Arabia", value:"ar-SA", value1:'ar-sa'},
    // {name:"Qatar", value:"ar-QA", value1:'ar-qa'},
  ];

  const showCountryName = () => {
    let regions = router.locale;
    let countryName = '';
    switch (regions) {
      case 'en':
        countryName = 'English';
        break;
      case 'en-MY':
        countryName = t('malaysia');
        break;
      case 'en-PH':
        countryName = 'Philippines';
        break;
      case 'en-ID':
        countryName = 'Indonesia';
        break;
      case 'id-ID' :
        countryName = 'Indonesia';
        break;
      case 'en-BR':
        countryName = 'Brazil';
        break;
      case 'pt-BR':
        countryName = t('brazil');
        break;
      case 'en-AE':
        countryName = 'United Arab Emirates';
        break;
      case 'en-EG':
        countryName = 'Egypt';
        break;
      case 'ja-JP':
        countryName = t('japan');
        break;
      case 'en-SA':
        countryName = 'Saudi Arabia';
        break;
      case 'en-QA':
        countryName = 'Qatar';
        break;
      default:
        countryName = 'Singapore';
        break;
    }
    return countryName;
  };

  const gotoPage = (key) => {
    closeDrawer();
    if (key === 1) {
      return router.push('/profile')
    } else if (key === 2) {
      return  router.push('/personal-center/' + userInfo.id);

    } else if (key === 10) {
      router.push({
              pathname:'/notice',
              query:{
                messageType: null
              }
            });
    } else if (key === 3) {
      return router.push('/myRewards/?type=active_voucher')

    }  else if (key === 6) {
      //  window.location.href =  `/login?redirect=${location.href}`;
      APILogout({}).then(resp => {
        localStorage.removeItem('token');
        localStorage.removeItem('fk-token');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('walletconnect');
        switch (router.pathname) {
          case '/discover':
            window.location.reload();
            break;
          case '/':
            window.location.reload();
            break;
          default:
            window.location.href =  `/`;
            break;
        }
      });
    } else if (key === 8) {
      window.open('https://igamie.tapfiliate.com/');
    } else if (key === 9) {
      window.open('https://blog.igamie.com');
    } else if (key === 4) {
      return router.push('/my-coins');
    } else if (key === 5) {
      return router.push('/myOrders');
    } else if (key === 11) {
      return router.push('/myNFTOrders');
    }
  };

  const gotoTopUp = () =>{
    closeDrawer();
    let url = "";
    if (window.location.href.indexOf("np.igamie") !== -1){
      url = "/g/iGamie_Poins-" + showCountryName();
    } else {
      url = "/g/iGamie_Point-" + showCountryName();
    }
    router.push(url);
  };

  const menuList = [
    // {name:t('myProfile'), value:1},
    {name:t('myOrders'), value:5},
    {name: t("myNFTOrder"), value: 11},
    {name:t('myGamieZone'), value:2},
    // {name:t('myRewards'), value:3},
    // {name:t('myCoins'), value:4},
    // {name:t("myChatHistory"), value:7},
    // {name:t("affiliateProgram"), value:8},
    // {name:t("igamieBlog1"), value:9},
    {name: 'My Notifications', value: 10},
    {name:t('logout1'), value:6}
  ];


  const copyWallet = () => {
    if (userInfo && userInfo.isBindWallet){
      var Url2 = userInfo.walletAddress;
      var oInput = document.createElement('input');
      oInput.value = Url2;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand('Copy');
      oInput.className = 'oInput';
      oInput.style.display = 'none';
      document.body.removeChild(oInput);
      message.success('Public address copied to clipboard');
    }
  };


  return <Drawer closable={false}
                 className='mobile-login-menu-new'
                 placement='right'
                 onClose={closeDrawer}
                 visible={visible}>
    <div className='mobile-menu-header-close-language-wrap'>
      <div onClick={closeDrawer}>
        <CloseOutlined></CloseOutlined>
      </div>
      <div className='language-wrap'>
        <div className='language-icon'></div>
        {showCountryName()}

        <select className='language-select-co'  onChange={ changeLanguage }>
          {
            languageListMobile.map((item, index) => {
              return (
                <option value={item.value}>{item.name}</option>
              )
            })
          }
        </select>

      </div>
    </div>

    <div className='mobile-menu-user-header'>
      <div className='user-avatar-wrap'>
        <div onClick={gotoMyProfile}>
          <Img src={userInfo?.headPic} className='user-avatar' />
        </div>
        <div onClick={gotoMyProfile} className='head_pic_edit'></div>
      </div>
      <div className='user-name'>{userInfo?.nickName}</div>
      <div className='user-email'>UserID {userInfo?.id}</div>
    </div>

    <div className='mobile-menu-wallet-bind-wrap'>
      {
        userInfo && userInfo.isBindWallet ?
          <div onClick={copyWallet} className='user-wallet-address-wrap'>
            <div className='wallet-success-icon'>
            </div>
            <div>{splitWalletAddress(userInfo.walletAddress)}</div>
            <div className='copy-btn'></div>
          </div>
            :
          <div onClick={()=>setChooseWalletModal(true)} className='connect-wallet-btn'></div>
      }
    </div>

    <div className='mobile-menu-coins-wrapper'>
      <div>{coinsBalance} {t('points2')}</div>
      <div className='coins-footer'>
        <div onClick={gotoTopUp} className='coins-title'>{t('topUp')}</div>
        <div style={{width:".3rem"}}></div>
        <div onClick={  gotoMyCoins } className='coins-title redeem-btn'>{t('redeem')}</div>
      </div>
    </div>

    <div className='mobile-menu-coins-section-title'>My Rewards</div>

    <div className='mobile-menu-coins-my-wallet'>
      <div onClick={()=>gotoVoucher(0)} className='my-wallet-item'>
        <div className='flex-wrap'>
          <div className='my-wallet-icon voucher-icon'></div>
          {
            userWalletInfo.voucherNum && userWalletInfo.voucherNum > 0 ?
              <div className='my-wallet-nums'>X{userWalletInfo.voucherNum}</div> : null
          }
        </div>
        <div style={{height:".3rem"}}></div>
        <div className='title'>Vouchers</div>
        <div className='desc'>{t("discountTips")}</div>
      </div>
      <div onClick={()=>gotoVoucher(1)} className='my-wallet-item'>
        <div className='flex-wrap'>
          <div className='my-wallet-icon exclusive-icon'></div>
          {
            userWalletInfo.exclusiveCardNum && userWalletInfo.exclusiveCardNum > 0 ?
              <div className='my-wallet-nums'>X{userWalletInfo.exclusiveCardNum}</div> : null
          }
        </div>
        <div style={{height:".3rem"}}></div>
        <div className='title'>Exclusive Cards</div>
        <div className='desc'>{t("membershipTips")}</div>
      </div>
      <div onClick={()=>gotoVoucher(2)} className='my-wallet-item'>
        <div className='flex-wrap'>
          <div className='my-wallet-icon gamecode-icon'></div>
          {
            userWalletInfo.gameCodeNum && userWalletInfo.gameCodeNum > 0 ?
              <div className='my-wallet-nums'>X{userWalletInfo.gameCodeNum}</div> : null
          }
        </div>
        <div style={{height:".3rem"}}></div>
        <div className='title'>{t("gameCode")}</div>
        <div className='desc'>{t("cDKeys")}</div>
      </div>
      <div onClick={()=>gotoVoucher(3)} className='my-wallet-item'>
        <div className='flex-wrap'>
          <div className='my-wallet-icon nft-icon'></div>
          {
            userWalletInfo.nftNum && userWalletInfo.nftNum > 0 ?
              <div className='my-wallet-nums'>X{userWalletInfo.nftNum}</div> : null
          }
        </div>
        <div style={{height:".3rem"}}></div>
        <div className='title'>Tokens & NFTs</div>
        <div className='desc'>{t("digitalTips")}</div>
      </div>
    </div>

    <div style={{marginTop:".1rem"}} className='mobile-menu-coins-section-title'>My Account</div>
    <div className='mobile-menu-list'>
      {
        menuList.map((item, index) => {
          return (
            i18n.language === 'ja' && item.value === 2 ? null :
              <div className='mobile-menu-item' key={index} onClick={ () => {  gotoPage(item.value)  }}>
                <div className='menu-left-item'>
                  {item.name}
                </div>
                <div className='menu-right-btn'></div>
              </div>
          )
        })
      }
    </div>

    <WalletChooseModal visible={chooseWalletModal}
                       onCancel={() => setChooseWalletModal(false)}
    />
  </Drawer>;

};

export default  MobileDrawer;
