/**
 * 监听数据的变化
 * @param obj 需要监听的对象
 * @param name 需要监听的属性
 * @param func 数据变化后的回调函数
 */
export const watch = (obj, name, func) => {
  Object.defineProperty(obj, name, {
    get: function() {
      return obj;
    },
    set: newValue => {
      func && func(newValue);
    },
  });
};
