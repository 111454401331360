import { SETMODULEMAP } from "@/constants/ActionTypes";

const initialState = {
  map: {},
}

const setModuleMap = (state = initialState, { type, payload }) => {
  switch (type) {
    case SETMODULEMAP: {
      return {
        ...state,
        map: payload,
      }
    }
    default:
      return state;
  }
}

export default setModuleMap;
