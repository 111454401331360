import locale from "antd/lib/date-picker/locale/en_US";
import webhost from "./webhost";
import {message, notification} from "antd";
import { APIIntegralMessageList, APIIntegralMessageListUnLogin } from '../api';
import { t } from 'i18next';
import $ from 'jquery';
import defaultAvatarSrc1 from "@/imgs/defaultAvatar/1.svg";
import defaultAvatarSrc2 from "@/imgs/defaultAvatar/2.svg";
import defaultAvatarSrc3 from "@/imgs/defaultAvatar/3.svg";
import defaultAvatarSrc4 from "@/imgs/defaultAvatar/4.svg";
import React from "react";





export const getCoinsPrice = (sellingPrice, deductionAmount, region) => {
    if (region === 'en-PH' || region === 'id-ID') {
        return `${Number(sellingPrice - deductionAmount).toFixed(0)}`
    }
    return `${Number(sellingPrice - deductionAmount).toFixed(2)}`
}

export const coinsNoticeActionNew = (type) => {
    console.log('coinsNoticeActionNew');
    APIIntegralMessageList().then(resp => {
        let res = resp.result;
        if (res[0]) {
            $('body').append(
                '<div class="coins-notice-wrapper"> <div class="coins-n-con"></div> <img src="https://cdn.igamie.com/static/coins_logo.gif" class="coin-icon coin-icon-ty" /> </div>'
            );
            let coinEle = document.getElementsByClassName('coin-icon')[0];
            let coinRect = coinEle.getBoundingClientRect();
            let headCoin = document.getElementsByClassName('coin-logo-i')[0];
            let headCoin1 = document.getElementsByClassName('coin-logo-i-1')[0];
            let headCoin2 = document.getElementsByClassName('coin-logo-i-2')[0];
            let headCoinRes = headCoin || headCoin1 || headCoin2;
            let headCoinRect = headCoinRes.getBoundingClientRect();

            console.log('headCoinRect', headCoinRect);
            setTimeout(() => {
                let style = "opacity:1;position:fixed;margin-top:0;margin-left:0;width:" + headCoinRect.width + "px;height:" + headCoinRect.height + "px;top:" + headCoinRect.top + "px;left:" + headCoinRect.left + "px;";
                coinEle.style = style;
            }, 800);

            setTimeout(() => {
                $('.coin-icon-ty').remove();
               $('.coin-bottom-num').css('opacity', 1);
               $('.coin-bottom-num').text(`+${res[0].integral}`);

            }, 1000);


            setTimeout(() => {
                $('.coins-notice-wrapper').remove();

                $('.coin-bottom-num').css('opacity', 0);
            }, 2000);
        }
    });


};


export const coinsNoticeAction = () => {
    // let list = [{integral:10}, {integral:20}, {integral:30}];
    // for (let i in list) {
    //     setTimeout(() => {
    //         notification.open({
    //             className:'coins-notification',
    //             closeIcon:null,
    //             top:'100px',
    //             description:
    //               <div className='coins-content'>
    //                 <img className='coin-icon' src='https://cdn.igamie.com/static/coins_1.svg' />
    //                 <div className='coin-des'>
    //                   <span className='content-des'>Congratulations on getting </span>
    //                   <span className='coins-num'>{list[i].integral}</span>
    //                   <span className='content-des'>coins!</span>
    //                 </div>
    //               </div>
    //           });
    //     }, 100 * i);
    // }
    APIIntegralMessageListUnLogin().then(resp => {
        let resList1 = resp.result ? resp.result.slice(0, 5) : [];
        let resList = resList1.filter((item) => { return item.integral })
        for (let i in resList) {
            setTimeout(() => {
                notification.open({
                    className:'coins-notification',
                    closeIcon:null,
                    top:'100px',
                    duration: 8,
                    description:
                      <div className='coins-content'>
                        <img className='coin-icon' src='https://cdn.igamie.com/static/coins_1.svg' />
                        <div className='coin-des'>
                          <span className='content-des'>Congratulations on getting </span>
                          <span className='coins-num'>{resp.result[i].integral}</span>
                          <span className='content-des'>points!</span>
                        </div>
                      </div>
                  });
            }, 200 * i);
        }
    });
};

export const getYearMonthDay = (nS) => {
    nS = nS ? nS : '0';
    let rens = nS.replace(' ', 'T');
    let timeStamp = Date.parse(rens);
    // return new Date(timeStamp).getFullYear()
    return (new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate() ) + '.' + (new Date(timeStamp).getMonth() < 9 ?  '0' + (new Date(timeStamp).getMonth() + 1) :  new Date(timeStamp).getMonth() + 1 )  + '.' + new Date(timeStamp).getFullYear();
    // return new Date(timeStamp).getFullYear() + '/' + (new Date(timeStamp).getMonth() + 1) + '/' + (new Date(timeStamp).getDate())
};

export const isVideo = (str) => {
    let index = str.lastIndexOf('.')
    let type = str.substring(index)
    if (type !== '.mp4' && type !== '.rmvb' && type !== '.avi' && type !== '.ts') {
        return false
    }
    return true
}

export const getGameDetailLink = (item, router) => {
    let alias = item.gameAlias || item.alias;
    alias = alias.split(' ').join('_');
    if (item.merchant || item.gameMerchant) {
        if (item.merchant === '37games' || item.gameMerchant === '37games') {
            return `/sl/${encodeURIComponent(alias)}`;
        }
        return `/re/${encodeURIComponent(alias)}`;
    }
    return `/g/${encodeURIComponent(alias)}`;
 };

export const getVoucherToGameDetailLink = (item, router) => {
    let alias = item.gameAlias || item.alias;
    alias = alias.split(' ').join('_');
    if (item.merchant || item.gameMerchant) {
        if (item.merchant === '37games' || item.gameMerchant === '37games') {
            return `/sl/${encodeURIComponent(alias)}`;
        }
        return `/re/${encodeURIComponent(alias)}`;
    }
    return `/g/${encodeURIComponent(alias)}`;
 };


export const getGameDetailLinkNew = (item, router) => {
    let alias = item.gameAlias || item.alias;
    alias = alias.split(' ').join('_');
    if (item.merchant || item.gameMerchant) {
        if (item.merchant === '37games' || item.gameMerchant === '37games') {
            return  `/sl/${encodeURIComponent(alias)}`;
        }
        return `/re/${encodeURIComponent(alias)}`;
    }
    return `/g/${encodeURIComponent(alias)}`;
};

export const gotoGameDetailNew = (item, router) => {
    let alias = item.gameAlias || item.alias;
    alias = alias.split(' ').join('_');
    if (item.merchant) {
        if (item.merchant === '37games') {
            return router.push(`/sl/${encodeURIComponent(alias)}`);
        }
        return router.push(`/re/${encodeURIComponent(alias)}`);
    }

    return  router.push(`/g/${encodeURIComponent(alias)}`);
};

export const getCookie =  (name) => document.cookie.match(`[;+]?${name}=([^;]*)`)?.pop();

export const getCookieByString = function (value, string) {
    if (!string) {
        return '';
    }
    let arrCookie = string.split('; ');
    for (let i = 0; i < arrCookie.length; i++) {
        let arr = arrCookie[i].split('=');
        if (arr[0] === value) {
            return arr[1];
        }
    }
    return '';
};

export const getRightAreaCode = (locale) => {
    switch (locale) {
        case 'en':
            return '1';
        case 'en-ID':
            return '62';
        case 'id-ID' :
            return '62';
        case 'en-MY':
            return '60';
        case 'en-PH':
            return '63';
        case 'en-BR':
            return '55';
        case 'pt-BR':
            return '55';
        case 'en-EG':
            return '20';
        case 'en-SA':
            return '966';
        case 'en-AE':
            return '971';
        case 'en-QA':
            return '974';
        default:
            return '65';
    }
};



export const getYearMonthDayTimeStampNew = (timeStamp) => {
    let month = new Date(timeStamp).getMonth() + 1;
    switch (month) {
        case 1:
            month = 'Jan';
            break;
        case 2:
            month = 'Feb';
            break;
        case 3:
            month = 'Mar';
            break;
        case 4:
            month = 'Apr';
            break;
        case 5:
            month = 'May';
            break;
        case 6:
            month = 'Jun';
            break;
        case 7:
            month = 'Jul';
            break;
        case 8:
            month = 'Aug';
            break;
        case 9:
            month = 'Sep';
            break;
        case 10:
            month = 'Oct';
            break;
        case 11:
            month = 'Nov';
            break;
        case 12:
            month = 'Dec';
            break;
        default:
            break;
    }
    return (new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate() ) + ' ' +  month + ' ' + new Date(timeStamp).getFullYear();
};

export const getMothEnglishText = (month) => {
  switch (month) {
    case 1:
      month = 'Jan';
      break;
    case 2:
      month = 'Feb';
      break;
    case 3:
      month = 'Mar';
      break;
    case 4:
      month = 'Apr';
      break;
    case 5:
      month = 'May';
      break;
    case 6:
      month = 'Jun';
      break;
    case 7:
      month = 'Jul';
      break;
    case 8:
      month = 'Aug';
      break;
    case 9:
      month = 'Sep';
      break;
    case 10:
      month = 'Oct';
      break;
    case 11:
      month = 'Nov';
      break;
    case 12:
      month = 'Dec';
      break;
    default:
      break;
  }
  return month;
};

export const getMonthDayTimeStampNew = (timeStamp) => {
    let month = new Date(timeStamp).getMonth() + 1;
    switch (month) {
        case 1:
            month = 'Jan';
            break;
        case 2:
            month = 'Feb';
            break;
        case 3:
            month = 'Mar';
            break;
        case 4:
            month = 'Apr';
            break;
        case 5:
            month = 'May';
            break;
        case 6:
            month = 'Jun';
            break;
        case 7:
            month = 'Jul';
            break;
        case 8:
            month = 'Aug';
            break;
        case 9:
            month = 'Sep';
            break;
        case 10:
            month = 'Oct';
            break;
        case 11:
            month = 'Nov';
            break;
        case 12:
            month = 'Dec';
            break;
        default:
            break;
    }
    return (new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate() ) + ' ' +  month;
};


export const getYearMonthDayTimeTimeStampNewWhitoutPm = (timeStamp) => {
    let month = new Date(timeStamp).getMonth() + 1;
    switch (month) {
        case 1:
            month = 'Jan';
            break;
        case 2:
            month = 'Feb';
            break;
        case 3:
            month = 'Mar';
            break;
        case 4:
            month = 'Apr';
            break;
        case 5:
            month = 'May';
            break;
        case 6:
            month = 'Jun';
            break;
        case 7:
            month = 'Jul';
            break;
        case 8:
            month = 'Aug';
            break;
        case 9:
            month = 'Sep';
            break;
        case 10:
            month = 'Oct';
            break;
        case 11:
            month = 'Nov';
            break;
        case 12:
            month = 'Dec';
            break;
        default:
            break;
    }

     let hh = new Date(timeStamp).getHours() < 10 ? '0' + new Date(timeStamp).getHours() : new Date(timeStamp).getHours();
     let noon = '';
     let mm = new Date(timeStamp).getMinutes() < 10 ? '0' + new Date(timeStamp).getMinutes() : new Date(timeStamp).getMinutes();
     return  hh + ':' + mm + noon + ', ' +  (new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate())  + ' ' +  month + ' ' + new Date(timeStamp).getFullYear();

};

// hh:ss, d/m/y(22)
export const getHourTimeDayMonthYear = (timeStamp) => {
  if (!timeStamp) {
    return ''
  }
  const date = new Date(timeStamp);
  let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  let year = date.getUTCFullYear();
  year = year.toString().split('0');
  let day = date.getDate() < 0 ? '0' + date.getDate() : date.getDate();
  let hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
  let noon = '';
  let mm = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
  return hh + ':' + mm + ', ' + day + '/' + month + '/' + year[1];
}

export const getMonthDayTimeTimeStampNewWhitoutPm = (timeStamp) => {
    let month = new Date(timeStamp).getMonth() + 1;
    switch (month) {
        case 1:
            month = 'Jan';
            break;
        case 2:
            month = 'Feb';
            break;
        case 3:
            month = 'Mar';
            break;
        case 4:
            month = 'Apr';
            break;
        case 5:
            month = 'May';
            break;
        case 6:
            month = 'Jun';
            break;
        case 7:
            month = 'Jul';
            break;
        case 8:
            month = 'Aug';
            break;
        case 9:
            month = 'Sep';
            break;
        case 10:
            month = 'Oct';
            break;
        case 11:
            month = 'Nov';
            break;
        case 12:
            month = 'Dec';
            break;
        default:
            break;
    }

     let hh = new Date(timeStamp).getHours() < 10 ? '0' + new Date(timeStamp).getHours() : new Date(timeStamp).getHours();
     let noon = '';
     let mm = new Date(timeStamp).getMinutes() < 10 ? '0' + new Date(timeStamp).getMinutes() : new Date(timeStamp).getMinutes();
     return  hh + ':' + mm + noon + ', ' +  (new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate())  + ' ' +  month ;

};


export const getMonthDayWithPm = (timeStamp) => {
    let month = new Date(timeStamp).getMonth() + 1;
    switch (month) {
        case 1:
            month = 'Jan';
            break;
        case 2:
            month = 'Feb';
            break;
        case 3:
            month = 'Mar';
            break;
        case 4:
            month = 'Apr';
            break;
        case 5:
            month = 'May';
            break;
        case 6:
            month = 'Jun';
            break;
        case 7:
            month = 'Jul';
            break;
        case 8:
            month = 'Aug';
            break;
        case 9:
            month = 'Sep';
            break;
        case 10:
            month = 'Oct';
            break;
        case 11:
            month = 'Nov';
            break;
        case 12:
            month = 'Dec';
            break;
        default:
            break;
    }

     let hh = new Date(timeStamp).getHours() < 10 ? '0' + new Date(timeStamp).getHours() : new Date(timeStamp).getHours();
     let noon = Number(hh) > 12 ? 'pm' : 'am';
     let mm = new Date(timeStamp).getMinutes() < 10 ? '0' + new Date(timeStamp).getMinutes() : new Date(timeStamp).getMinutes();
     return   (new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate())  + ' ' +  month + ',' + hh + '' + noon ;

}

export const getYearMonthDayTimeTimeStampNew = (timeStamp) => {
    let month = new Date(timeStamp).getMonth() + 1;
    switch (month) {
        case 1:
            month = 'Jan';
            break;
        case 2:
            month = 'Feb';
            break;
        case 3:
            month = 'Mar';
            break;
        case 4:
            month = 'Apr';
            break;
        case 5:
            month = 'May';
            break;
        case 6:
            month = 'Jun';
            break;
        case 7:
            month = 'Jul';
            break;
        case 8:
            month = 'Aug';
            break;
        case 9:
            month = 'Sep';
            break;
        case 10:
            month = 'Oct';
            break;
        case 11:
            month = 'Nov';
            break;
        case 12:
            month = 'Dec';
            break;
        default:
            break;
    }

     let hh = new Date(timeStamp).getHours() < 10 ? '0' + new Date(timeStamp).getHours() : new Date(timeStamp).getHours();
     let noon = Number(hh) > 12 ? 'pm' : 'am';
     let mm = new Date(timeStamp).getMinutes() < 10 ? '0' + new Date(timeStamp).getMinutes() : new Date(timeStamp).getMinutes();
     return  hh + ':' + mm + noon + ', ' +  (new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate())  + ' ' +  month + ' ' + new Date(timeStamp).getFullYear();

};

export const isSameYear = (year1, year2) => {
    console.log('xixixi', new Date(year1).getFullYear() === new Date(year2).getFullYear());

   return new Date(year1).getFullYear() === new Date(year2).getFullYear();
};


export const locationHasRegion = () => {
    let pathName = window.location.pathname;
    if (pathName.includes('en-SG') || pathName.includes('en-MY') || pathName.includes('en-ID') || pathName.includes('id-ID') || pathName.includes('en-PH')) {
        return true;
    }
};


export const getYearMonthDayNew = (nS) => {
    nS = nS ? nS : '0';
    let rens = nS.replace(' ', 'T');
    let timeStamp = Date.parse(rens);
    let month = new Date(timeStamp).getMonth() + 1;
    switch (month) {
        case 1:
            month = 'Jan';
            break;
        case 2:
            month = 'Feb';
            break;
        case 3:
            month = 'Mar';
            break;
        case 4:
            month = 'Apr';
            break;
        case 5:
            month = 'May';
            break;
        case 6:
            month = 'Jun';
            break;
        case 7:
            month = 'Jul';
            break;
        case 8:
            month = 'Aug';
            break;
        case 9:
            month = 'Sep';
            break;
        case 10:
            month = 'Oct';
            break;
        case 11:
            month = 'Nov';
            break;
        case 12:
            month = 'Dec';
            break;
        default:
            break;
    }


    return (new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate() ) + ' ' +  month + ' ' + new Date(timeStamp).getFullYear();

};




export const getYearMonthDay1 = (nS) => {
    let timeStamp = Date.parse(nS);
    // return new Date(timeStamp).getFullYear()
    return (new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate() ) + '.' + (new Date(timeStamp).getMonth() < 9 ?  '0' + (new Date(timeStamp).getMonth() + 1) :  new Date(timeStamp).getMonth() + 1 )  + '.' + new Date(timeStamp).getFullYear();

};


export const getYearMonthDayTime = (timeStamp) => {
    let date = new Date(timeStamp);
    let YY = new Date(timeStamp).getFullYear();
    let MM = new Date(timeStamp).getMonth() < 9 ? '0' + (new Date(timeStamp).getMonth() + 1) : new Date(timeStamp).getMonth() + 1;
    let DD = new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate();
    let hh = new Date(timeStamp).getHours() < 10 ? '0' + new Date(timeStamp).getHours() : new Date(timeStamp).getHours();
    let mm = new Date(timeStamp).getMinutes() < 10 ? '0' + new Date(timeStamp).getMinutes() : new Date(timeStamp).getMinutes();
    let ss = new Date(timeStamp).getSeconds() < 10 ? '0' + new Date(timeStamp).getSeconds() : new Date(timeStamp).getSeconds();
    return  `${DD}.${MM}.${YY} ${hh}:${mm}:${ss}`;
};


export const getTransCard = (str) => {
    var reg = /^(\d{2})\d+(\d{4})$/;
    str = str.replace(reg, "$1 ********** $2");
    return str;
};

export const isMobile = () => {
    var sUserAgent =  window.navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) && sUserAgent.match(/ipad/i)[0]  === "ipad";
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) === "iphone os" ;
    var bIsIphoneOsNew = sUserAgent.match(/iphone os/i) && sUserAgent.match(/iphone os/i)[0];

    var bIsMidp = sUserAgent.match(/midp/i) === "midp";
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) === "rv:1.2.3.4";
    var bIsUc = sUserAgent.match(/ucweb/i) === "ucweb";
    var bIsAndroid = sUserAgent.match(/android/i) === "android";
    var bIsAndroidNew = sUserAgent.match(/android/i) && sUserAgent.match(/android/i)[0];
    var bIsCE = sUserAgent.match(/windows ce/i) === "windows ce";
    var bIsWM = sUserAgent.match(/windows mobile/i) === "windows mobile";

    var bIsMinWin = (window.innerWidth || ( document.body && document.body.clientWidth )) < 820

    if (!( bIsMinWin || bIsIpad || bIsIphoneOs || bIsIphoneOsNew || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsAndroidNew || bIsCE || bIsWM)){
      return false;
    }
    return true;

};

export const isMobileAndroid = () => {
  var sUserAgent =  window.navigator.userAgent.toLowerCase();
  var bIsAndroid = sUserAgent.match(/android/i) === "android";
  if (!bIsAndroid) {
    return false;
  }
  return true;
}

export const isTikTok = () => {
    var sUserAgent =  window.navigator.userAgent.toLowerCase();
    if (sUserAgent.match(/bytedance/i) === 'bytedance') {
        return true
    }
}

export const getLanguage = (str) => {
    switch (str) {
        case 'en_US':
            return '1';
        case 'es_ES':
            return '3';
        case 'fr_FR':
            return '2';
        case 'pl_PL':
            return '4';
        case 'pt_BR':
            return '5';
        case 'ar_AR':
            return '6';
        default:
            return '1';
    }
};

export const encode = (str) => {
  var r = "";
  // string 类型的可以当做字符串对象来用
  for (var i = 0; i < str.length; i++)
  {
    // 取出下标为 i 字符的编码
    var code = str.charCodeAt(i);
    // 将字符对应的编码，拼接到一个空字符串上
    r += code;
    // 每个符号后，添加 , 分割
    r += ",";
  }
  return r;

};

export const decode = (str) => {
  // 思路：
  // 1. 按照 , 分割字符串
  // 2. 将每个 分割的子字符串 转换成 数字，再转换成 字符
  //   96 97 98
  // 3. 将还原的字符，拼接在一起，再设置到 input 中去
  var arr = str.split(",");
  // 用于拼接结果的
  var r = "";
  for (var i = 0; i < arr.length; i++)
  {
    // 获取加密后的 字符串的编码，是一个数字
    var code = parseInt(arr[i]);
    r += String.fromCharCode(code);
  }
  // 将拼接后的结果，设置到 input 中
  return r;

};


export const getGotoGameDetail = (alias, id) => {
    console.log('alias', alias);
    let resAlias;
    if (alias){
        resAlias = alias.split(' ').join('_');
    } else {
        resAlias = id;
    }
    return resAlias;
};


export const getPushPathByLn = () => {
    let babelLang = localStorage.getItem('babelLang');
    if (babelLang === 'null' || babelLang === 'undefined' || babelLang === null) {
        return '';
    }
    let pathName = window.location.pathname;
    // if(!pathName.includes('/en/') && babelLang === 'en_US'){
    //   return ''
    // }

    return  '/' + babelLang;
    // switch (babelLang) {
    //     case 'en-SG':
    //         return '/en-SG'
    //     case 'en-MY':
    //         return '/en-MY'
    //     case 'en-ID':
    //         return '/en-'
    //     case 'pl_PL':
    //         return '/pl'
    //     case 'pt_BR':
    //         return '/pt-BR'
    //     case 'ar_AR':
    //         return '/ar'
    //     default:
    //         return '/'
    // }
};

export const getPushPathByLnInfo = () => {
    let babelLang = localStorage.getItem('babelLang');
    let pathName = window.location.pathname;
    if (!pathName.includes('/en/') && babelLang === 'en_US'){
      return '';
    }
    switch (babelLang) {
        case 'en_US':
            return '/en';
        case 'es_ES':
            return '/es';
        case 'fr_FR':
            return '/fr';
        case 'pl_PL':
            return '/pl';
        case 'en_BR':
            return '/en-br';
        case 'en_AR':
            return '/ar';
        default:
            return '/en';
    }

};

export const getQueryString = (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r !== null) return unescape(r[2]);
    return null;
};

export const getQueryStringByStr = (name, str) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = ('?' + str.split('?')[1]).substr(1).match(reg);
    if (r !== null) return unescape(r[2]);
    return null;
};

export const isApp = () => {
    let urlApp = getQueryString('app');
    let storageApp = localStorage.getItem('app');
    if (urlApp === 'true' || storageApp === 'true' ){
        localStorage.setItem('app', 'true');
        return true;
    }
};

export const debounceNew = (func, delay) => {
    let timer = null;
    console.log('0000')
    return function () {
        clearTimeout(timer);
        let _this = this;
        let _args = arguments;
        console.log('11')
        timer = setTimeout(function () {
            func.apply(_this, _args)
        }, delay)
    }
}

export const debounce = (fn, delay) => {
    let timer = null; // 借助闭包
    return function() {
        if (timer){
            clearTimeout(timer);
        }
        timer = setTimeout(fn, delay); // 简化写法
    };
};

export const numFormat = (num) => {
    if (!num){
      return 0;
    }
    var numStr = num.toString();
    if (numStr.length <= 3){
      return numStr;
    }
    if (numStr.length >= 4 && numStr.length < 7){
      return  (num / 1000).toFixed(1) + "k";
    }
    if (numStr.length >= 7){
      return  (num / 1000000).toFixed(1) + "m";
    }
  };



export const gotoPostDetails = (router, item) =>{
  let title = item.title.length > 35 ?
    item.title.substring(0, 35).replace(/\s+/g, "_").replace(/-+/g, "_").replace(/#+/g, "").replace(/\?/g, "") :
    item.title.replace(/\s+/g, "_").replace(/-+/g, "_").replace(/#+/g, "").replace(/\?/g, "") ;
  title = title.replace(/\./g, "");
  let gameName = (item.tags === undefined || item.tags === null || item.tags.length < 1) ? "hotTag" : item.tags.replace(/\s+/g, "_").replace(/\|+/g, "_").replace(/#/g, "");
  router.push('/post/[...id]', `/post/${gameName}/${title}/${item.id}`,  {locale: router.locale} );
};


/** 检查图片大小 */
export const  checkSize = (file) => {
  return new Promise((resolve, reject) => {
    let _URL = window.URL || window.webkitURL;
    let img = new Image();
    img.onload = () => {
      let valid = img.width >= 300 && img.height >= 300;
      if (valid){
        resolve();
      } else {
        message.error(t("checkPicSizeTips"));
        reject();
      }
    };
    img.src = _URL.createObjectURL(file);
  });
};


/**
 * 将表情转字符
 * @param {}} str 表情字符
 */
export const emojiToText = (str) => {
  var patt = /[\ud800-\udbff][\udc00-\udfff]/g; // 检测utf16字符正则
  str = str.replace(patt, function(char) {
    var H, L, code;
    if (char.length === 2) {
      H = char.charCodeAt(0); // 取出高位
      L = char.charCodeAt(1); // 取出低位
      code = (H - 0xd800) * 0x400 + 0x10000 + L - 0xdc00; // 转换算法
      return '&#' + code + ';';
    }
    return char;
  });
  return str;
};

/**
 * 将字符转表情
 * @param {*} str utf16态的表情编码
 */
export const textToEmoji = (str) => {
  var reg = /&#.*?;/g;
  var result = str.replace(reg, function(char) {
    var H, L, code;
    if (char.length === 9) {
      code = parseInt(char.match(/[0-9]+/g), 10);
      H = Math.floor((code - 0x10000) / 0x400) + 0xd800;
      L = ((code - 0x10000) % 0x400) + 0xdc00;
      return unescape('%u' + H.toString(16) + '%u' + L.toString(16));
    }
    return char;
  });
  return result;
};

/**  下拉获取数据  */
export const getScrollTop = () => {
  var scrollTop = 0, bodyScrollTop = 0, documentScrollTop = 0;
  if (document.body) {
    bodyScrollTop = document.body.scrollTop;
  }
  if (document.documentElement) {
    documentScrollTop = document.documentElement.scrollTop;
  }
  scrollTop = (bodyScrollTop - documentScrollTop > 0) ? bodyScrollTop : documentScrollTop;
  return scrollTop;
};
/**  下拉获取数据  */
export const getScrollHeight = () => {
  var scrollHeight = 0, bodyScrollHeight = 0, documentScrollHeight = 0;
  if (document.body) {
    bodyScrollHeight = document.body.scrollHeight;
  }
  if (document.documentElement) {
    documentScrollHeight = document.documentElement.scrollHeight;
  }
  scrollHeight = (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight;
  return scrollHeight;
};
/**  下拉获取数据  */
export const getWindowHeight = () => {
  var windowHeight = 0;
  if (document.compatMode === 'CSS1Compat') {
    windowHeight = document.documentElement.clientHeight;
  } else {
    windowHeight = document.body.clientHeight;
  }
  return windowHeight;
};

export const cdn = (path) => {
  return path && path.replace(/igamie-web.s3.ap-southeast-1.amazonaws.com/, 'cdn.igamie.com');
};

export const loadAvatar = (src) => {
  const defaultAvatarList = [defaultAvatarSrc1, defaultAvatarSrc2, defaultAvatarSrc3, defaultAvatarSrc4];
  const defaultIndex = Math.floor(Math.random() * 4);
  return (
    src ?
      cdn(src) :
      defaultAvatarList[defaultIndex].src
  );
};

export const redirectLogin = (func) => {

  if (func){
    return  func();
  }

  let hrefNow = window.location.href;
  return window.location.href = "/login?redirect=" + encodeURI(hrefNow);
}

export const isAndroid = () => {
  var sUserAgent =  window.navigator.userAgent.toLowerCase();
  var bIsAndroid = sUserAgent.match(/android/i) === "android";
  var bIsAndroidNew = sUserAgent.match(/android/i) && sUserAgent.match(/android/i)[0];
  if (bIsAndroid || bIsAndroidNew){
    return true;
  }
  return false;

};


export const ossImgType = (src, width = 100, height = 100) => {
    let fit = 'lfit'
    let quality = 60
    const s3Url = "igamie-web.s3.ap-southeast-1.amazonaws.com";
    if (src && src.indexOf(s3Url) !== -1){
        src = src ? src.replace(/igamie-web.s3.ap-southeast-1.amazonaws.com/, 'cdn.igamie.com') : src;
    } else {
        if (src) {
            if (width && height) {
              src = src + `?x-oss-process=image/format,webp/resize,m_${fit},w_${width},h_${height}/quality,q_${quality}`;
            } else {
              src = src + `?x-oss-process=image/format,webp/resize,m_${fit}/quality,q_${quality}`;
            }
        }
    }
    return src
}


export const isTrue = (params) => {
   if (params === [] || params === {} || params === '' || params === undefined || params ===  null || params === 0 || params ===  -1 || params === 'undefined' || params === 'null' ) {
       return false
   }
   return true
}

export const checkHaveMobilePhone = (userInfo) =>{
  if (userInfo.mobilePhone !== null && userInfo.mobilePhone?.length >= 4){
    return true;
  }
  return false;
};

export const checkHaveEmail = (userInfo) =>{
  if (userInfo === null || userInfo.length <= 0) return false;
  if (userInfo.email && userInfo.email?.indexOf('wallet-null') !== -1) return false;
  if (userInfo.email !== null && userInfo.email?.indexOf('@') !== -1 && userInfo.email?.length < 30){
    return true;
  }
  return false;
};


export const defaultHeadPic = "https://imomo.s3.ap-southeast-1.amazonaws.com/icon-default-headerl.png";
