import React, {useState} from "react";
import styles from "./index.module.scss";
import {Button, message, Modal} from "antd";
import {walletConnect} from "@/tools/walletConnect";
import {APIGetProfile, APIModifyWallet, APIUserBindWalletNew} from "@/api";
import useDispatchAction from "@/hooks/useDisptachAction";
import {setUserInfo} from "@/redux/actions/userInfo";
import {useSelector} from "react-redux";
import {WalletGetMetamaskProviderAndAddress} from "@/tools/walletTools";
// import { evmWallets, ParticleConnect } from "@particle-network/connect";
// import particleConfig from "@/config/particleConfig";


const WalletChooseModal = ({visible = false, onCancel, onOk}) => {
  const dispatchAction = useDispatchAction({setUserInfo});
  const userInfo = useSelector(state => state.userInfo.userInfo.userInfo);
  const [walletModelState, setWalletModelState] = useState({
    createLoading:false,
    connectLoading:false,
    metamaskLoading: false,
    walletLoading: false,


  });

  const connectWallet = () =>{
    walletConnect(true, (walletAddress)=>{
      setWalletModelState({
        ...walletModelState,
        connectLoading: true
      });

    });
  };

  const bindWallet = (walletAddress, walletType, uuid, particleToken) => {
    APIModifyWallet(JSON.stringify({
      walletAccounts:walletAddress,
      walletAddress: walletAddress,
      walletType: walletType,
      particleUuid: uuid,
      particleToken: particleToken
    })).then(resp=>{
      if (resp.result){
        APIGetProfile().then(resp => {
          dispatchAction.setUserInfo(resp.result);
          localStorage.setItem('userInfo', JSON.stringify(resp.result));
          onOk?.();
          onCancel();
        }).finally(() => {
          setWalletModelState({
            connectLoading: false,
            createLoading: false,
          });
        });
      } else {
        setWalletModelState({
          connectLoading: false,
          createLoading: false,
        });
      }
    });
  };

  // 绑定用户钱包
  const bindUserWallet = (walletAddress, signData, walletNumber) => {
    APIUserBindWalletNew(JSON.stringify({
      walletAccounts:walletAddress,
      walletAddress:walletAddress,
      walletType: 1,
      loginToken: signData,
      walletNum: walletNumber,
    })).then(res => {
      message.success('succeed')
      APIGetProfile().then(resp => {
        dispatchAction.setUserInfo(resp.result);
        localStorage.setItem('userInfo', JSON.stringify(resp.result));
        onOk?.();
        onCancel();
      })
    }).finally(() => {
      setWalletModelState({
        connectLoading: false,
        createLoading: false,
        metamaskLoading: false,
        walletLoading: false,
      });
    })
  }

  const metaMaskConnectClick = async() => {
      setWalletModelState({
        ...walletModelState,
        metamaskLoading: true
      });
    await WalletGetMetamaskProviderAndAddress({callBack: metamaskCallBack});

  };

  const metamaskCallBack = (provider, address, signData, walletNumber) => {
    if (address && address.length > 0) {
      return  bindUserWallet(address[0], signData, walletNumber);
    }
    setWalletModelState({
      ...walletModelState,
      metamaskLoading: false
    });
  };

  // const metaMaskConnectClick = async () => {
  //   setWalletModelState({
  //     ...walletModelState,
  //     metamaskLoading: true
  //   });
  //   const connectKit = new ParticleConnect({
  //       projectId: particleConfig.NEXT_PUBLIC_PARTICLE_APP_PROJECT_ID,
  //       clientKey: particleConfig.NEXT_PUBLIC_PARTICLE_APP_CLIENT_KEY,
  //       appId: particleConfig.NEXT_PUBLIC_PARTICLE_APP_ID,
  //       chains: [
  //           {
  //               id: 56,
  //               name: 'BSC',
  //           }
  //       ],
  //       wallets: evmWallets({ qrcode: false}),
  //   });
  //   try {
  //         connectKit.connect('metamask').catch(e => {
  //           setWalletModelState({
  //             ...walletModelState,
  //             metamaskLoading: false
  //           });
  //         })
  //         connectKit.on('connect', async (provider) => {
  //           let addressArr =  await provider.request({ method: 'eth_accounts' })
  //           let address = addressArr[0]
  //           console.log('address', address)
  //           bindUserWallet(address)
  //         });
  //   } catch (e) {
  //       console.log('e', e);
  //       setWalletModelState({
  //         ...walletModelState,
  //         metamaskLoading: false
  //       });
  //   }


  // }

  const walletConnectClick = () => {
    walletConnect(true, (walletAddress, signData, walletNumber)=>{
      setWalletModelState({
        ...walletModelState,
        walletLoading: true
      });
      bindUserWallet(walletAddress, signData, walletNumber)
    });
  }


  return (
    <>
      <Modal width={549} transitionName={"ant-fade"} onCancel={onCancel} maskClosable={false} centered visible={visible} footer={null} className='login-new-modal'>
        <div className='veri-header'>
          <div>Connect Wallet</div>
        </div>
        <div className={styles.content_box}>
          {/* <Button disabled={walletModelState.connectLoading} loading={walletModelState.createLoading} onClick={() => createWallet()} className={styles.btn_create_wallet}>
            <span className={styles.title}>Create a wallet</span>
            <span className={styles.sub_title}>I'm new and let's set up now</span>
          </Button> */}
          <Button  loading={walletModelState.metamaskLoading} className={`${styles.wallet_connect_btn_metamask}`} onClick={ metaMaskConnectClick  }>
            <img className={styles.left_icon} src='https://static-img.igamie.com/static/metamask_icon1.webp'  />
            <span className={styles.center_name}>MetaMask</span>
            <img className={styles.right_icon} src='https://static-img.igamie.com/static/metamask_icon2.webp'  />
          </Button>
          <Button  loading={walletModelState.walletLoading} className={styles.wallet_connect_btn_walletcon} onClick={ walletConnectClick } >
              <img className={styles.left_icon} src='https://static-img.igamie.com/static/walletConnect_icon2.png'  />
              <span className={styles.center_name} >WalletConnect</span>
              <img className={styles.right_icon} src='https://static-img.igamie.com/static/walletConnect_icon1.png' />
          </Button>
          {/* <Button disabled={walletModelState.createLoading} loading={walletModelState.connectLoading} onClick={() => connectWallet()} className={styles.btn_wallet_connect}>
            <span className={styles.title}>Connect with WalletConnect</span>
            <span className={styles.sub_title}>I already have a wallet</span>
          </Button> */}

        </div>

      </Modal>

    </>
  );
};

export default WalletChooseModal;
