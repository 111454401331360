import { SETEXPIRINGPPOINTS } from "@/constants/ActionTypes";

const initialState = {
  expired: 0,
}

const setExpiringPoints = (state = initialState, { type, payload }) => {
  switch (type) {
    case SETEXPIRINGPPOINTS: {
      return {
        ...state,
        expired: payload
      }
    }
    default:
      return state;
  }
}

export default setExpiringPoints
