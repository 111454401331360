import React, {useEffect, useState} from "react";
import {Modal} from "antd";
import styles from "./index.module.scss";
import {useSelector} from "react-redux";
import {setLoginBindTipShow} from "@/redux/actions/home";
import useDispatchAction from "@/hooks/useDisptachAction";
import {useRouter} from "next/router";
import {useTranslation} from "react-i18next";

const LoginBindTip = () => {
  const router = useRouter();
  const vis = useSelector(state => state.home.setLoginBindTipShow && state.home.setLoginBindTipShow.visible);
  let { t, i18n} = useTranslation();

  const dispatchAction = useDispatchAction({setLoginBindTipShow});

  const [isWallet, setIsWallet] = useState(false);

  const onClose = () => {
    dispatchAction.setLoginBindTipShow(false);
  };

  const gotoBind = () => {
    dispatchAction.setLoginBindTipShow(false);
  };

  useEffect(() => {
    const isBindWallet = localStorage.getItem('isNewRegister') === '1';
    //
    setIsWallet(isBindWallet);
  }, [vis]);

  return (
   <Modal destroyOnClose={true} width={450} closable={true} maskClosable={false} footer={null} closeIcon={<></>} wrapClassName={'gamie-zone-modal-wrap'} visible={vis} centered={false} className={'gamie-zone-modal'}>
     <div className={styles.page}>

       <div className={styles.top_wrapper}>
         <div className={styles.success_icon}></div>
         <div className={styles.success_txt}>{t('loginBindTitle')}</div>
       </div>
       <div className={styles.hit_text}>{t('loginBindRubiessAdded')}</div>
       <div className={styles.hit_text}>{t('loginBindHitText')}</div>
       <div className={styles.bottom_wrapper}>
         <div onClick={gotoBind} className={styles.confirm_btn}>{t('loginBindBtnClose')}</div>
       </div>
     </div>
   </Modal>
  );
};

export default LoginBindTip;
