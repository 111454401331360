
import host from '../tools/host';
import request from '../tools/request';
import ajaxRequest from '@/tools/ajaxRequest';

// const localhost = 'http://localhost:8090';
const localhost = host;


export const APICreateOrder = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/order/create`, JSON.stringify(params));
};

export const APICreateNftTempOrder = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/order/temporary`, JSON.stringify(params))
}

export const APICreateUndealOrder = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/order/create`, JSON.stringify(params))
}

// export const APIGetProfileUnDeal = (params) => {
//     return request.GET(`${host}/imomo/api/user/profile`, params);
// };
export const APIGetProfileUnDeal = (params) => {
    return ajaxRequest.get_undeal(`${host}/imomo/api/user/profile`, params);
};

export const APILogin = (params) => {
    return ajaxRequest.postJson_undeal(`${host}/imomo/api/user/login`, params);
};

export const APILogout = (params) => {
    return ajaxRequest.get_undeal(`${host}/imomo/api/user/logout`, params);
};

export const APIRegister = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/user/register`, params);
};

export const APISendRetrieveCode = (params) => {
    return ajaxRequest.post(`${host}/imomo/email/retrieve_code`, params);
};

export const APISendVerificationCode = (params) => {
    return ajaxRequest.post(`${host}/imomo/email/verification_code`, params);
};

export const APISmsRetrieveCode = (params) => {
    return ajaxRequest.post(`${host}/imomo/sms/retrieve_code`, params);
};

export const APISmsVericationCode = (params) => {
    return ajaxRequest.post(`${host}/imomo/sms/verification_code`, params);
};



export const APIGetProfile = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/user/profile`, params);
};

export const APIGuestDetail = (params) => {
    return ajaxRequest.getJson(`${host}/imomo/api/order/guest/detail`, params);
};

export const APIGuestRegister = (params) =>{
    return ajaxRequest.postJson(`${host}/imomo/api/user/guest/register`, params);
};

export const APICheckAccount = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/game/check_account`, params);
};

export const APIGetYoyoAccount = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/game/get_account`, params);
};

export const APIUtmCookie = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/web/add_cookies`, params);
};

// export const APIGetPromotionList = (params) => {
//     return ajaxRequest.postJson(`${host}/imomo/api/promotion/list`, params)
// }
export const APIGetPromotionList = (params, other) => {
    return request.POST(`${localhost}/imomo/api/promotion/list`, params, other);
};

export const APIGetPromotionListNew = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/promotion/list`, params);
};

export const APIGetPromotionByCategory = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/promotion/list/new`, params);
}

export const APIGetHomeData = (params, other) => {
    return request.GET(`${localhost}/imomo/api/game/index`, params, other);
};

export const APIGetHomeDataAjax = (params) => {
    return ajaxRequest.get_undeal(`${host}/imomo/api/game/index`, params)
}

export const APIGetGameList = (params, other) => {
    return request.POST(`${localhost}/imomo/api/game/list`, params, other);
};

export const APIGetDiscountList = (params, other) => {
    return request.POST(`${localhost}/imomo/api/discount/list`, params, other);
};


export const APITest = (params) => {
    return request.POST(`${host}/imomo/api/promotion/list`, params);
};

export const APIGetGameAlias = (params, other) => {
    return request.GET(`${localhost}/imomo/api/game/alias`, params, other);
};

export const APIGetGameAliasOld = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/game/alias`, params);
};

export const APIGetServerList = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/game/server_list`, params);
};

export const APIGetRoleList = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/game/role_list`, params);
};

export const APIGetSaintServerList = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/game/gtarcade/server_list`, params);
};

export const APIGetSaintRoleList = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/game/gtarcade/role_list`, params);
};

export const APIGetGameDetail = (params, other) => {
   return request.GET(`${localhost}/imomo/api/game/detail`, params, other);
};

export const APIGetPaymentChannel = (params, other) => {
    return request.GET(`${host}/imomo/api/payment/channls`, params, other);
};

export const APIGetPaymentChannelOld = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/payment/channls`, params);
};

export const APIVoucherStatus = (params, other) => {
    return request.GET(`${host}/imomo/api/voucher/sku/status`, params, other);
};

export const APIVoucherLimitInfo = (params, other) => {
  return request.POST(`${host}/imomo/api/voucher/info`, params, other);
};

export const APIVoucherStatusOld = (params) => {
    return ajaxRequest.get_undeal(`${host}/imomo/api/voucher/sku/status`, params);
};

export const APIGetRegionIp = (params) => {
    return ajaxRequest.get(`${host}/imomo/region/ip`, params);
};

export const APIGetOrderNotify = (params) => {
    return ajaxRequest.get_undeal(`${host}/imomo/api/order/notify`, params);
};

export const APIGetBestShotList = (params, other) => {
    return request.POST(`${host}/imomo/bestScreenshot/page_list`, params, other);
};

export const APIGetBestShotListNew = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/bestScreenshot/page_list`, params);
};

export const APIThumbShot = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/screenshotComment/like`, params);
};

export const APIScreenShotsAddComment = (params) => {
    return ajaxRequest.postJson_undeal(`${host}/imomo/api/screenshotComment/saveComment`, params);
};

export const APIScreenShotsAdd = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/screenshot/save`, params);
};

export const APIGetHomeData1 = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/tag/listGame`, params);
};

export const APIGetGameList1 = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/game/list`, params);
};

export const APIScreenShotsGiveLike = (params) => {
    return ajaxRequest.postJson_undeal( `${host}/imomo/api/screenshotComment/like`, params);
};

export const APIGetGamieZoneGameList = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/screenshot/listGames`, params);
};

export const APIScreenShotsGetListNew = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/screenshot/list`, params);
};

export const APIScreenShotsGetList = (params, isLogin) => {
    // return ajaxRequest.postJson(isLogin ? `${host}/imomo/api/screenshot/listUser` : `${host}/imomo/api/screenshot/list`, params);
    return ajaxRequest.postJson(`${host}/imomo/api/screenshot/listUser`, params);
};
export const APIGetUserLastMessageCount = (params) => {
  return ajaxRequest.get_undeal(`${host}/imomo/api/message/lastMessageCount`, params);
};

export const APIGetUserLastMessageList = (params) => {
  return ajaxRequest.get_undeal(`${host}/imomo/api/message/lastMessageList`, params);
};

export const APIGetUserMessageList = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/message/list`, params);
};

export const APIGetUserMessageDetails = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/message/detail`, params);
};

export const APIGetShopCartList = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/cart/cartList`, params);
};

export const APIGetUserVoucherList = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/cart/getUserVoucherList`, params);
};

export const APIRemoveCartItem = (params) => {
  return ajaxRequest.delete(`${host}/imomo/api/cart/removeUserCartItem`, params);
};

export const APICartItemQuantity = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/cart/updateCartItemQuantity`, params);
};

export const APIAddCart = (params) => {
    return ajaxRequest.postJson_undeal(`${host}/imomo/api/cart/addCart`, params);
};

export const APIGetCampaignDetail = (params, other) => {
    return request.GET(`${host}/imomo/api/campaign/campaign_detail`, params, other);
};
export const APICreateOrderByCart = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/cart/createOrderByCart`, params);
};
export const APIGetOrderDetailNew = (params, other) => {
    return request.GET(`${host}/imomo/api/order/detailNew`, params, other);
};
export const APICreatePaymentRecord = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/payment_record/create`, JSON.stringify(params));
};

export const APIGetOrderVerificationCode = (params) => {
    return ajaxRequest.get(`${host}/imomo/email/order/verification_code`, params);
};

export const APIVerificationOrderCode = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/order/verification_code`, params);
};

export const APIDownLoadOrderCode = (params) => {
    return ajaxRequest.file(`${host}/imomo/api/order/down/code`, params);
};
export const APIScreenShotsShare = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/screenshot/addShares`, params);
};
export const APIScreenShotsDeleteById = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/screenshot/deleteByUser`, params);
};
export const APIScreenShotsGetComment = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/screenshotComment/listCommentNew`, params);
};
export const APIScreenShotsGetDetails = (params, isLogin) => {
  return ajaxRequest.get(isLogin ? `${host}/imomo/api/screenshot/getDetailByUser` : `${host}/imomo/api/screenshot/getDetail`, params);
};
export const APIGetScreenShotsDetails = (params, other) => {
  return request.GET( `${host}/imomo/api/screenshot/getDetail`, params, other);
};
export const APIGetScreenShotsDetailsToken = (params, other) => {
  return request.GET( `${host}/imomo/api/screenshot/getDetailByUser`, params, other);
};
export const APIScreenShotsGetMyScreenShots = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/screenshot/getByUser`, params);
};

export const APIGetIntegralAccount = (params, other) => {
  return request.GET(`${host}/imomo/api/integral/account`, params, other);
};

export const APIGetExchangeAmazonList = (params, other) => {
  return request.POST(`${host}/imomo/api/v1/userAmazonRecord/productList`, params, other);
};

export const APIGetIntegralAccountPage = (params) => {
    return ajaxRequest.get_undeal(`${host}/imomo/api/integral/account`, params);
};

export const APIGetIntegralAccountNew = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/integral/account`, params);
};


export const APIGetIntegralDetailed = (params, other) => {
    return request.POST(`${host}/imomo/api/integral/account`, params, other);
};

export const APIGetIntegralDetailedPage = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/integral/detail/list`, params);
};

export const APIGetIntegralProductList = (params, other) => {
    return request.GET(`${host}/imomo/api/integral/product/list`, params, other);
};

export const APIGetIntegralProductListPage = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/integral/product/list`, params);
};

export const APIGetTaskList = (params, other) => {
    return request.GET(`${host}/imomo/api/task/list`, params, other);
};

export const APIProductRedeem = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/integral/product/redeem/${params.productId}`, params);
};

export const APIProductRedeemUndeal = (params) => {
    return ajaxRequest.get_undeal(`${host}/imomo/api/integral/product/redeem/${params.productId}`, params);
};

export const APIGetVoucherList  = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/voucher/list`, params);
};

export const APICancelOrder = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/order/cancel`, params);
};

export const APIGetOrderLists = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/order/list`, params);
};

export const APIGetDictItems = (params) => {
    return ajaxRequest.post(`${host}/imomo/dict/items`, params);
};

export const APIModifyUserInfo = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/user/modify`, params);
};

export const APIGetGameDefaultDetail = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/game/default_detail`, params);
};

export const APIGetProductList37 = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/game/product_list`, params);
};

export const APIGetOrderDetail = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/order/detail`, params);
};

export const APIGetPaymentStatus = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/payment_record/status`, params);
};

export const APIGetVoucherDetail = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/voucher/detail`, params);
};

export const APIGetReferralContent = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/referral/campaign/today_campaign`, params);
};
export const APIGetUserLikes = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/screenshot/getByUser/likes`, params);
};

export const APIGetUserLikeGame = (params) => {
  return ajaxRequest.getJson(`${host}/imomo/api/screenshot/favorate/game`, params);
};

export const APIUserFollow = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/user/follow/action`, params);
};

export const APICancelAccountGetCode = (params) => {
    return ajaxRequest.get(`${host}/imomo/email/account_cancellation_code`, params);
};

export const APINoticeReadAll = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/message/userOneClickRead`, params);
};

export const APIGetUnReadCount = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/message/platformCount`, params);
};

export const APIGetVoucherInfo = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/voucher/info`, params);
};

export const APIGetVoucherParentInfo = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/voucher/detail/parent`, params);
}

export const APIGetReferralCode = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/commission/clicks`, params);
};

export const APIGetReferralCodeOther = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/user/referral/code`, params);
};
export const APIGetReferralStatus = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/user/referral/status`, params);
};
export const APIGetReferralList = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/user/referral/list`, params);
};
export const APIGetReceiveStatus = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/user/referral/receive_status`, params);
};
export const APIReceiveAward = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/user/referral/receive_award`, params);
};
export const APISmsBindCode = (params) => {
    return ajaxRequest.get(`${host}/imomo/sms/bind_code`, params);
};
export const APIUserBindEmail = (params) => {
    return ajaxRequest.post(`${host}/imomo/api/user/bind_email`, params);
};
export const APIEmailBindCode = (params) => {
    return ajaxRequest.get(`${host}/imomo/email/bind_code`, params);
};
export const APIUserBindMobilePhone = (params) => {
    return ajaxRequest.post(`${host}/imomo/api/user/bind_mobile_phone`, params);
};

export const APISendChangePasswordCode = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/user/change_password_code`, params);
};

export const APIChangePasswordNew = (params) => {
    return ajaxRequest.post(`${host}/imomo/api/user/change_password`, params);
};

export const APIUploadPic = (params) => {
    return ajaxRequest.upload(`${host}/imomo/upload/pic`, params);
};

export const APIUploadPicNew = (params) => {
  return ajaxRequest.upload(`${host}/api/training/uploadImage`, params);
};

export const APISearchNew = (params, type) => {
    return ajaxRequest.get(`${host}/imomo/api/search/index${type ?  "/" +  type : ""}`, params);
};


export const APIGetApCampaignDetail = (params, other) => {
    return request.GET(`${host}/imomo/api/referral/campaign/campaign_detail`, params, other);
};

export const APIApGetUserBalance = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/user/balance`, params);
};

export const APIApGetBalanceRecord = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/user/balance/record`, params);
};

export const APIApWithdrawApply = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/withdraw/apply`, params);
};

export const APIApWithdrawRecord = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/withdraw/record`, params);
};

export const APIApWithdrawDetail = (params) => {
    return ajaxRequest.get(`${host}/imomo/withdraw/detail`, params);
};

export const APIGetMyReferrals = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/user/referral/my_referrals`, params);
};

export const APIGetReferralOrders = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/user/referral/orderList`, params)
};

export const APIGetreferralKolInfo = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/user/referral/kol_info`, params);
};

export const APISaveKol = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/user/referral/save_kol`, params);
};

export const APICheckChannel = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/user/referral/check_channel`, params);
};

export const APIGetShortUrl = (params) => {
    return ajaxRequest.get(`${host}/imomo/promote/short_url`, params);
};

export const APIGetReferralDetail = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/user/referral/detail`, params);
};


export const APIBlindboxRedeem = (params) => {
    return ajaxRequest.get_undeal(`${host}/imomo/api/rewards/redeem/${params.blindBoxId}`);
};

export const APIBlindboxReceiverList = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/rewards/receiver/list`, params);
};

export const APIGetTagList = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/tag/list?num=50&orderBy=screenshot_tag_num&name=${params.name}`);
};

export const APIReceiverCheck = (params) => {
    return ajaxRequest.get_undeal(`${host}/imomo/api/rewards/check/free`, params);
};

export const APIGetUserFreeBoxNum = (params) => {
    return ajaxRequest.get_undeal(`${host}/imomo/api/task/userFreeBoxNum`, params);
}

export const APIRewardsHistory = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/rewards/histroy`, params);
};

export const APIGetBlindBoxPic = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/rewards/box/detail/${params.blindBoxId}`, );
};

export const APIGetRewardByHistory = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/rewards/histroy/${params.id}`);
};
export const APIGetTagListGamieZone = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/tag/list?num=10&orderBy=sort`, params);
};

export const APIGetTagListGamieZoneHome = (params, other) => {
    return request.GET(`${host}/imomo/api/tag/list?num=10&orderBy=sort`, params, other);
};

export const APITaskSignin = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/task/signIn`, params);
};

export const APIIntegralMessageList = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/message/integralMessageList`, params);
};

export const APIIntegralMessageListUnLogin = (params) => {
    return ajaxRequest.get_undeal(`${host}/imomo/api/message/integralMessageList`, params);
};

export const APIGetTaskListAjax = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/task/list`, params);
};

export const APIGetGameCodeHistory = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/integral/product/code/history`, params);
};

export const APIIntegralVerificationCode = (params) => {
    return ajaxRequest.get(`${host}/imomo/email/integral/verification_code`, params);
};

export const APIGameCodeView = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/integral/product/view/code`, params);
};

export const APIUpdateOrderRemark = (params) => {
    return ajaxRequest.post(`${host}/imomo/api/order/update/info`, params);
};
export const APIReportPost = (params) => {
  return ajaxRequest.postJson_undeal(`${host}/imomo/api/screenshot/report`, params);
};
export const APICancelAccount = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/user/delete_account`, params);
};

export const APIGetVoucherPayment = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/voucher/payment`, params);
};

export const APIPkTaskCoins = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/screenshot/pickup/take/coins`, params);
};

export const APIPkPostLike = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/screenshot/pickup/like`, params);
};

export const APIPkPostList = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/screenshot/pickup/list`, params);
};

export const APIPkTaskProgress = (params) => {
    return ajaxRequest.get_undeal(`${host}/imomo/api/screenshot/pickup/task/progress`, params);
};

export const APIPkPostAllList = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/screenshot/pickup/list/all`, params)
}

export const APIGetActivityDetail = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/activity/detail`, params)
}
export const APIGetTopicDetails = (params, other) => {
  return request.GET(`${host}/imomo/api/topic/getDetail`, params, other);
};
export const APICreateTopicPost = (params) => {
  return ajaxRequest.postJson_undeal(`${host}/imomo/api/topicPost/save`, params);
};
export const APIGetTopicPostList = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/topicPost/list`, params);
};


export const APIGetTopicFollowTopic = (params) => {
  return ajaxRequest.postJson_undeal(`${host}/imomo/api/topic/followTopic`, params);
};
export const APIGetTopicPostLike = (params) => {
  return ajaxRequest.postJson_undeal(`${host}/imomo/api/TopicPostComment/like`, params);
};
export const APIPinTopicPost = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/topicPost/topicPostReview`, params);
};
export const APIAddTopicPostComment = (params) => {
  return ajaxRequest.postJson_undeal(`${host}/imomo/api/TopicPostComment/saveComment`, params);
};
export const APIGetTopicPostComment = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/TopicPostComment/listComment`, params);
};
export const APIGetTopicList = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/topic/list`, params);
};
export const APIGetTopicPostDetails = (params, other) => {
  return request.GET(`${host}/imomo/api/topicPost/getDetail`, params, other);
};
export const APIForwardTopicPost = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/topicPost/forwardPost`, params);
};










export const APIInventoryTips = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/inventory/tips/save`, params);
};

export const APIDeleteMyTopicPost = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/topicPost/deleteOwnPost`, params);
  };

  export const APIGetEmUser = (params) => {
     return ajaxRequest.get(`${host}/imomo/api/easemob/getEmUser`, params)
  }

export const APIGetCpsGameInfo = (params, other) => {
  return request.GET(`${host}/imomo/api/cps/game/info`, params, other)
}

export const APIGetCpsGameInfoNew = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/cps/game/info`, params)
}



export const APIGetPayMethods = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/payment/sku/channls`, params)
}

// export const APIGetPaymentChannel = (params, other) => {
//     return request.GET(`${host}/imomo/api/payment/channls`, params, other);
// };

export const APIGetPayMethodsSku = (params, other) => {
    return request.GET(`${host}/imomo/api/payment/sku/channls`, params, other)
}

export const APIGetActivityTaskList = (params) => {
    return ajaxRequest.get_undeal(`${host}/imomo/api/activity/task/list`, params)
}

export const APITopicGetPostList = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/topicPost/listUser`, params);
};
export const APITopicGetPostLike = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/topicPost/listUserLikes`, params);
};
export const APIPersonCenterUserDetail = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/user/userZoneDetail`, params);
};

export const APIPersonCenterUserTopic = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/topic/userTopic`, params);
};

export const APIPersonCenterUserFan = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/user/userFan`, params);
};

export const APIPersonCenterUserFollowing = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/user/userFollow`, params);
};

export const APIPersonCenterUserFollowTopics = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/topic/followTopicList`, params);
};

export const APIOssUploadFile = (params) => {
    return ajaxRequest.upload(`${host}/imomo/upload/uploadFile`, params);
};

export const APIOssUploadVideo = (params) => {
    return ajaxRequest.upload(`${host}/imomo/upload/uploadVideo`, params);
};

export const APIGetMiniGameList = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/miniGame/list`, params);
};

export const APIGetCpsGameTags = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/cps/game/tags`, params)
};

export const APIGetCpsGameList = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/cps/game/list`, params)
};

export const APIGetIMToken = (params) => {
  return ajaxRequest.get_undeal(`${host}/imomo/api/easemob/getEasemobToken`, params);
}

export const APIGetGrowthDetailById = (params) => {
    return ajaxRequest.get_undeal(`${host}/imomo/api/activity/growthDetail/${params.campaignId}`)
}

export const APIApplyFission = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/activity/applyFission/${params.campaignId}`)
}

export const APIApplyFissionUndeal = (params) => {
    return ajaxRequest.get_undeal(`${host}/imomo/api/activity/applyFission/${params.campaignId}`)
}

export const APIGetInvitingHistory = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/activity/historyPage`, params)
}

export const APIGetHelperSchedule = (params) => {
    return ajaxRequest.get_undeal(`${host}/imomo/api/activity/schedule/${params.referralCode}`)
}

export const APISendVerificationCodeNew = (params) => {
   return ajaxRequest.get(`${host}/imomo/email/activity/verification_code`, params)
}

export const APIViewAcCode = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/activity/view/code`, params)
}

export const APIReceiveAcReward = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/activity/receiveReward`, params)
}

export const APIGetOutGameToken = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/games/generate/token`)
}

export const APIGetHistoryRewardList = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/activity/historyRewardList/${params.campaignId}`)
}

export const APIUserHelpReferral = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/activity/help/${params.referralCode}`)
}

// IM 文字信息发送接口
export const APIIMSendTxtMessage = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/easemob/sendTextMessage`, params);
};

// IM 图片信息发送接口
export const APIIMSendImgMessage = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/easemob/sendImage`, params);
};

// IM 图片信息发送接口
export const APIIMUploadImage = (params) => {
  return ajaxRequest.upload(`${host}/imomo/api/easemob/file`, params);
};

// 小游戏兑换接口
export const APIRedeemMiniGame = (params) => {
    return ajaxRequest.get_undeal(`${host}/imomo/api/miniGame/redeem/${params.miniGameId}`)
}
export const APIGetMiniGameDetails = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/miniGame/detail/${params.miniGameId}`);
};

export const APIServiceGetMiniGameDetails = (params, other) => {
  return request.GET(`${localhost}/imomo/api/miniGame/detail/${params.miniGameId}`, {}, other);
}

// 小游戏兑换接口登录
export const APIRedeemMiniGameLogin = (params) => {
    return ajaxRequest.get_undeal(`${host}/imomo/api/miniGame/redeem/${params.miniGameId}`)
}

// 积分抵扣 查询
export const APIOrderIntegralCheck = (params) => {
   return ajaxRequest.get_undeal(`${host}/imomo/api/integral/order/check`, params)
}


// 活动参与
export const APIAllActivityApply = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/activity/apply`, params)
}

export const APIApplyActivityUnLogin = (params) => {
    return ajaxRequest.postJson_undeal(`${host}/imomo/api/activity/apply`, params)
}

export const APIGetRegisterBanner = (params) => {
  return ajaxRequest.get_undeal(`${host}/imomo/api/game/index/new`, params)
}

// 新首页接口
export const APIGetHomeDataNew = (params, other) => {
    return request.GET(`${localhost}/imomo/api/game/index/new`, params, other)
}

// 首页banner位配置接口
export const APIGetHomeCampaignConfig = (params, other) => {
  return request.GET(`${localhost}/imomo/api/campaign/homeConfig`, params, other)
}

// 人拉人详情
export const APIGetReferralContentDetail = (params, other) => {
  return request.GET(`${host}/imomo/api/referral/campaign/today_campaign`, params, other);
};

// 活动详情
export const APIGetActivity1Detail = (params, other) => {
  return request.GET(`${host}/imomo/api/activity/detail`, params, other)
}


// rsgPK 接口
export const APIGetRsgPKInfo = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/rsg/pickup/info`, params);
};
export const APIRsgPickUp = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/rsg/pickup/pickup`, params);
};
export const APIRsgUseCoins = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/rsg/pickup/integtal/redeem`, params);
};
export const APIGetRsgInviteLink = () => {
    return ajaxRequest.get(`${host}/imomo/api/rsg/pickup/invite`);
};
export const APIRsgSigned = () => {
    return ajaxRequest.get(`${host}/imomo/api/rsg/pickup/signed`);
}


// 校验用户是否绑定钱包
export const APICheckUserIsBindWallet = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/user/checkUserIsBind`, params)
}

// nft product list
export const APINftProductList = (params) => {
    return ajaxRequest.get(`${host}/imomo/external/product/list`, params)
}
// 检查钱包是否已注册
export const APICheckWallet = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/user/checkAccount`, params);
};

export const APICheckEmail = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/user/checkEmail`, params);
};
export const APIGetHomeDataDisCoverTag = (params, other) => {
  return request.GET(`${localhost}/imomo/api/cps/game/tags/count`, params, other);
}

// 获取nft订单列表
export const APIGetNftOrderList = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/order/list/nft`, params);
};


// 助力列表
export const APIGetActivityListPage = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/activity/list/page`, params)
}

// App 活动奖励轮播列表
export const APIGetActivityAwardList = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/activity/award/list`, params)
}

// 领取活动奖励
export const APIActivityReceiveReward = (params) => {
   return ajaxRequest.get(`${host}/imomo/api/activity/receiveReward`, params)
}
// 用户绑定第三方平台
export const APIUserBindWallet = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/user/userWalletBind`, params);
};

export const APIGetMyNft = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/userNft/list/page`, params);
};


export const APIWithDrawNft = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/userNft/apply/pickUp`, params);
};

export const APIModifyWallet = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/user/bindWallet`, params);
}

export const APIGetCoinsSkuList = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/game/coins/skus`, params);
};

export const APICompleteActivityTask = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/activity/task/complete`, params)
}

export const APIGetNewWalletAddress = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/wallet/generate`, params);
};


export const APIUserGetWalletInfo = (params) => {
  return ajaxRequest.get_undeal(`${host}/imomo/api/voucher/numbers`, params)
}

export const APIGetUserGeneratorWEB3Token = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/user/generatorToken`, params);
};

export const APIGetLeaderboardList = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/activity/list/leaderboard`, JSON.stringify(params))
}

export const APIDailyUpChain = (params) => {
    return ajaxRequest.get_undeal(`${host}/imomo/api/journey/syncJourneyDaily`, params)
}

/**
 * 用户绑定钱包接口
 */
export const APIUserBindWalletNew = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/user/bindWallet`, params)
}


export const APIJourneyUserDaily = (params) => {
    return ajaxRequest.get_undeal(`${host}/imomo/api/journey/yesterdayUserJourney`, params)
}

export const APIUserWalletDetails = (params) => {
    return ajaxRequest.get_undeal(`${host}/imomo/api/user/wallet/detail`, params);
}

export const APIGetIGMBalance = (params) => {
    return ajaxRequest.get_undeal(`${host}/imomo/api/ruby/balance`, params);
};

export const APIIGMWithdrawalUSDT = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/igm/withdrawal/apply`, params);
};

export const APIPointRedemptionIGM = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/igm/points/redemption/apply`, params);
};

export const APICreateUSDTRechargeIGMOrder = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/igm/order/recharge/create`, params);
};

// 天马游戏列表
export const APISkyHorseGameList = () => {
    return ajaxRequest.get_undeal(`${host}/imomo/external/game/list`)
}

// 天马游戏启动链接
export const APILaunchGameLink = (params) => {
    return ajaxRequest.getJson(`${host}/imomo/external/game/launch/url/en-us/${params.gameId}`)
}

// 查询游戏平台账户余额
export const APISkyHorseWalletAmount = (params) => {
    return ajaxRequest.get_undeal(`${host}/imomo/external/game/wallet/amount`, params)
}

// 游戏平台账户充值
export const APISkyHorseRecharge = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/external/game/wallet/recharge`, JSON.stringify(params))
}

// 游戏平台提现
export const APISkyHorseWithdraw = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/external/game/wallet/withdraw`, JSON.stringify(params))
}
export const APIMiniGameConfigList = (params) => {
  return ajaxRequest.get_undeal(`${host}/imomo/api/miniGame/configs/${params}`);
};


// 通过输入多少IGM返回多少第三方游戏金币数
export const APISkyHorseAmountCalc = (params) => {
    return ajaxRequest.get_undeal(`${host}/imomo/external/game/amount/calc`, params)
}
export const APIBlindBoxRewardsReceive = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/rewards/receive/digital`, params);
};

// 新版首页banner
export const APIGetPromotionByCategoryNew = (params, other) => {
    return request.POST(`${localhost}/imomo/api/promotion/list/new`, params, other);
}

// airdrop list
export const APIGetAirdropList = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/activity/list/airdropList`, JSON.stringify(params))
}

// airdrop 活动详情
export const APIGetAirdropDetail = (params) => {
    return ajaxRequest.get(`${host}/imomo/api/activity/airdrop/info`, params)
}

// airdrop 活动申请参与
export const APIApplyAirdrop = (params) => {
    return ajaxRequest.postJson(`${host}/imomo/api/activity/airdrop/apply`, JSON.stringify(params))
}

export const APIRequestGetAirdropDetail = (params, other) => {
  return request.GET(`${localhost}/imomo/api/activity/airdrop/info`, params, other);
}

// airdrop 活动详情
export const APIGetIGMRanking = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/igm/igmLeaderboard`, params);
};

export const APILaunchJiliGameLink = (params) => {
  return ajaxRequest.getJson(`${host}/imomo/external/game/launch/url/en-us/${params.gameId}`);
};

export const APIJiliSkyHorseWalletAmount = (params) => {
  return ajaxRequest.get_undeal(`${host}/imomo/external/game/wallet/amount`, params)
};

// 天马游戏列表
export const APIJiliSkyHorseGameList = () => {
  return ajaxRequest.get_undeal(`${host}/imomo/external/game/list`)
}

// 通过输入多少IGM返回多少第三方游戏金币数
export const APISkyHorseAmountCall = (params) => {
  return ajaxRequest.get_undeal(`${host}/imomo/external/game/amount/calc`, params)
}

export const APISkyHorseNewWithdraw = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/external/game/wallet/withdraw`, JSON.stringify(params))
}

// 游戏平台账户充值
export const APISkyHorseNewRecharge = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/external/game/wallet/recharge`, JSON.stringify(params))
}

// IGM 提现到钱包请求
export const APIIGMWithdrawToWallet = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/igm/order/withdrawal/igm`, JSON.stringify(params))
}

// IGM 提现到钱包详情
export const APIIGMWithdrawToWalletDetails = (params) => {
  return ajaxRequest.get_undeal(`${host}/imomo/api/igm/order/detail`, params)
}

// IGM 创建充值订单
export const APICreateIGMDepositOrder = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/igm/order/recharge/igm`, JSON.stringify(params))
}

// IGN 提现订单列表
export const APIIGMWithdrawOrderList = (params) => {
  return ajaxRequest.postJson_undeal(`${host}/imomo/api/igm/order/withdrawal/igm2Page`, JSON.stringify(params))
}

export const APIGetBlindBoxDetail = (params, other) => {
  return request.GET(`${host}/imomo/api/activity/detail`, params, other);
};

export const APIGetTransactionHistoryList = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/igm/order/page`, JSON.stringify(params));
};

export const APIGetCoinsList = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/game/detail`, params);
};

export const APIGetCoinsAliasName = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/game/alias`, params);
};

export const APIGetPaymentChannelUnit = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/payment/channls`, params);
};

export const APIGetMysteryBoxRewardWinner = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/rewards/winners`)
}

export const APIGetTotalUserNum = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/user/total`, params)
}

export const APIPublishNftProduct = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/product/publishProduct`, JSON.stringify(params))
}

// 用户发布商品分页列表接口
export const APINftProductListC2c = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/product/list`, JSON.stringify(params))
}
export const APIGetNFTGoodsDetails = ({productId}) => {
  return ajaxRequest.get(`${host}/imomo/api/product/detail/${productId}`, {});
};

export const APINFTAddComment = (params) => {
  return ajaxRequest.postJson_undeal(`${host}/imomo/api/product/doProductDiscuss`, params);
};

export const APINFTGiveLike = (params) => {
  return ajaxRequest.postJson_undeal(`${host}/imomo/api/product/like`, params);
};

export const APINFTUnLike = (params) => {
  return ajaxRequest.postJson_undeal(`${host}/imomo/api/product/unLike`, params);
};

// 用户已买nft订单详情
export const APIGetUserBuyNftOrderDetail = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/product/order/list`, JSON.stringify(params))
}
export const APIBuyNFTGoods = ({productId}) => {
  return ajaxRequest.get(`${host}/imomo/api/product/doBuyProduct/${productId}`, {});
};

export const APIGetUserNFTSold = (params) => {
  return ajaxRequest.postJson_undeal(`${host}/imomo/api/product/publish/list/page`, params);
};

export const APIGetUserNFTOrderList = (params) => {
  return ajaxRequest.postJson_undeal(`${host}/imomo/api/product/order/list`, params);
};

// nft c2c 发布的列表
export const APIGetUserNFTPublishList = (params) => {
  return ajaxRequest.postJson_undeal(`${host}/imomo/api/product/publish/list/page`, params);
};

// nftc2c 订单详情
export const APIGetNftC2cOrderDetail = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/product/order/detail/${params.orderId}`, params)
}

// nft c2c 用户取消订单
export const APINftC2cCancelOrder = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/product/doCancel`, JSON.stringify(params))
}

// nft c2c 卖家发货接口
export const APINftC2cDoShipProduct = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/product/doShipProduct`, JSON.stringify(params))
}

// nft c2c 用户确认收货接口
export const APINftC2cDoConfirmReceipt = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/product/doConfirmReceipt`, JSON.stringify(params))
}

// nft c2c 个人中心 点赞nft列表
export const APIGetUserNFTGoodsLikeList = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/product/like/page`, params)
}

// nft 详情 删除评论
export const APIDeleteNFTComment = ({commentId}) => {
  return ajaxRequest.delete(`${host}/imomo/api/product/deleteDiscuss/${commentId}`, {});
}

// 删除nft 商品
export const APIDeleteNFTGoods = ({productId}) => {
  return ajaxRequest.delete(`${host}/imomo/api/product/delete/${productId}`, {});
}

// ai 创建图片
export const APIAICreateImg = (params) => {
  return ajaxRequest.postJson_undeal(`${host}/imomo/api/image/text2Image`, params);
};

// ai 图片详情
export const APIGetAIDetailsImg = ({imgId}) => {
  return ajaxRequest.get(`${host}/imomo/api/image/detail/${imgId}`, {});
};

// AI history
export const APIGetAIImgHistoryList = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/image/page`, params);
};

// AI img delete
export const APIAIImgDelete = ({imgId}) => {
  return ajaxRequest.delete(`${host}/imomo/api/image/delete/${imgId}`, {});
};

// AI deposit $
export const APIAIDepositInBankCard = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/ruby/recharge/amount2Ruby`, params);
};

// AI deposit List
export const APIGetRubyDepositList = () => {
  return ajaxRequest.get(`${host}/imomo/api/ruby/rubyProductList`, {});
};

// AI deposit List server
export const APIGetRubyDepositListServer = (params, other) => {
  return request.GET(`${localhost}/imomo/api/ruby/rubyProductList`, params, other);
};

// generate model list
export const APIGetGenerateModelList = () => {
  return ajaxRequest.post_undeal(`${host}/imomo/api/training/pageModel`, {});
};

// generate model common list
export const APIGetGenerateModelCommonList = () => {
  return ajaxRequest.post(`${host}/imomo/api/training/pageModelCommon`, {});
};

// card emoji click
export const APIImgCardEmojiClick = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/screenshotComment/emoji`, params)
};

// 打赏接口
export const APIDiscoverRewards = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/rubyTip/tipping`, params);
};

// 获取lora tag
export const APIGetSearchLoraTagList = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/search/index/userTagQuery`, params);
};

// 获取热门创作者列表
export const APIGetCreatorList = () => {
  return ajaxRequest.get(`${host}/imomo/api/userRecommended/list`, {});
};

// 获取android 下载链接
export const APIGetAndroidDownloadLink = () => {
  return ajaxRequest.get(`${host}/imomo/api/v1/appVersion/getUrl`, {});
};

// 获取ruby统计数量
export const APIGetTipBoxData = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/rubyTip/tippingStatistics`, params);
};

// 获取user 详情与最近三个post
export const APIGetUserInfoAndLastPost = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/user/userZoneDetailShare`, params);
};

// 获取热门创作者&post列表
export const APIGetCreatorAndPostList = () => {
  return ajaxRequest.get(`${host}/imomo/api/userRecommended/listRecommended`, {});
};

// 统计支付
export const APIStatisticsPay = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/userRubyRechargeRecord/submitOrder`, params);
};

// 提交兑换Amazon
export const APIConfirmExchangeAmazon = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/v1/userAmazonRecord/redeem`, params);
};


export const APIChat = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/v1/bot/chat2Thread`, params);
};

export const APIGetBotList = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/v1/bot/getBotList`, params);
};

export const APICreateBotThread = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/v1/bot/createThread`, params);
};

export const APIGetHistoryList = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/v1/bot/chatContentHistory`, params);
};

export const APIGetBotById = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/v1/bot/getById?id=${params.id}`);
};

export const APICreateBot = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/v1/userBotComment/createBot`, params);
};

export const APIEditBot = (params) => {
  return ajaxRequest.postJson(`${host}/imomo/api/v1/bot/modifyBot`, params);
};

export const APILikeBot = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/v1/userBotComment/likeBot?botId=${params.id}`);
};

export const APIGetMyBotList = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/v1/bot/getMyBotList`, params);
};

export const APIDeleteMyBotById = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/v1/userBotComment/deletedBot?botId=${params.id}`);
};

export const APIDeleteMyChatBotById = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/v1/bot/deleteThread?botId=${params.id}`);
};

export const APIBuyBot = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/v1/userBotPurchase/purchase?botId=${params.id}`);
};

export const APIScreenShotsGetNewHomeList = (params) => {
  // return ajaxRequest.postJson(isLogin ? `${host}/imomo/api/screenshot/listUser` : `${host}/imomo/api/screenshot/list`, params);
  return ajaxRequest.get(`${host}/imomo/api/screenshot/listNewsHome`, params);
};

export const APIGetHomePageStatics = (params, other) => {
  return request.GET(`${localhost}/imomo/api/screenshot/homepageStatistics`, params, other);
};

export const APIGetHomePageBanner = (params, other) => {
  return request.GET(`${localhost}/imomo/api/userRecommended/listRecommended`, params, other);
};

export const APIGetRewardsDay = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/v1/rewardsDay/rewardDayList`, params);
};

export const APIGetRewards = (params) => {
  return ajaxRequest.get_undeal(`${host}/imomo/api/v1/userRewardsDay/reward`, params);
};

export const APIGetTwitterLoginUrl = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/user/getTwitterLoginUrl`, params);
};

export const APILoginV2 = (params) => {
  return ajaxRequest.postJson_undeal(`${host}/imomo/api/user/emailLoginV2`, params);
};

export const APIGetImomoTaskList = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/v1/pointTask/list`, params);
};

export const APICompleteTask = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/v1/pointTaskDetail/complete?taskId=${params.taskId}`);
};

export const APIGetMyInviteCode = () => {
  return ajaxRequest.get(`${host}/imomo/api/user/referral/code`);
};

export const APIEditMyInviteCode = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/user/referral/modifyMyReferralCode`, params);
};

export const APIGetMyInviteUserList = (params) => {
  return ajaxRequest.get(`${host}/imomo/api/user/referral/referralList`, params);
};
