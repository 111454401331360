import React, {useEffect, useState} from "react";
import styles from "./index.module.scss";
import {Modal, Switch} from "antd";
import {useSelector} from "react-redux";
import {APIGetProfile, APIModifyUserInfo} from "@/api";
import useDispatchAction from "@/hooks/useDisptachAction";
import {
  addToNumber,
  reset, setAllowPointsExchangeIGM,
  setCoinsBalance,
  setExpiringPoints,
  setHideAppDownload,
  setIGMBalance, setIGMTransactionModal, setLoginShowModal, setNftSellHidden, setNftSellShow
} from "@/redux/actions/home";
import {setUserInfo} from "@/redux/actions/userInfo";
import {t} from "i18next";

const SettingModal = ({vis, close}) => {
  const userInfo = useSelector(state => state.userInfo.userInfo.userInfo);
  const dispatchAction = useDispatchAction({setUserInfo});

  const [deleteModalVis, setDeleteModalVis] = useState(false);
  const [isBlur, setIsBlur] = useState(false);
  const [isShowNSFW, setIsShowNSFW] = useState(false);
  const [isShowNSFWSpells, setShowNSFWSpells] = useState(false);

  const onClose = () => {
    close?.();
  };

  const onShowDeleteModal = () => {
    setDeleteModalVis(true);
  };

  const onBlurSwitchChange = async (checked) => {
    setIsBlur(checked);
    const isRes = await modifyInfo({isBlurImage: checked ? 1 : 0});
    if (!isRes) {
      setIsBlur(!checked);
    }
  };

  const onNSFWSwitchChange = async (checked) => {
    setIsShowNSFW(checked);
    const isRes = await modifyInfo({isShowImageContent: checked ? 1 : 0});
    if (!isRes) {
      setIsShowNSFW(!checked);
    }
  };

  const onShowNSFWChange = async (checked) => {
    setShowNSFWSpells(checked);
    const isRes = await modifyInfo({isShowImageSpells: checked ? 1 : 0});
    if (!isRes) {
      setShowNSFWSpells(!checked);
    }
  };

  const modifyInfo = async (params) => {
    try {
      const { resultCode } = await APIModifyUserInfo(JSON.stringify(params));
      if (resultCode === 200) {
        getProfile();
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  };

  const getProfile = () => {
    APIGetProfile().then(resp => {
      if (resp.resultCode === 200) {
        dispatchAction.setUserInfo(resp.result);
      }
    });
  };

  useEffect(() => {
    setIsBlur(userInfo.isBlurImage === 1);
    setIsShowNSFW(userInfo.isShowImageContent === 1);
    setShowNSFWSpells(userInfo.isShowImageSpells === 1);
  }, [userInfo]);

  return (
    <>
      <Modal destroyOnClose={true} width={620} closable={true} maskClosable={false} footer={null} closeIcon={<></>} wrapClassName={'gamie-zone-modal-wrap'} visible={vis} centered className={'gamie-zone-modal'}>
        <div className={styles.page}>
          <div className={styles.head_wrapper}>
            <div className={styles.title}>{t('imomoSetting')}</div>
            <div onClick={onClose} className={styles.close_icon}></div>
          </div>
          <div className={styles.content_wrapper}>
            <div className={styles.switch_wrapper}>
              <div className={styles.label}>{t('imomoSettingBlurNSFWImg')}</div>
              <Switch checked={isBlur} onChange={onBlurSwitchChange} className={styles.switch} />
            </div>
            <div className={styles.switch_wrapper}>
              <div className={styles.label}>{t('imomoSettingNSFWContent')}</div>
              <Switch checked={isShowNSFW} onChange={onNSFWSwitchChange} className={styles.switch} />
            </div>
            {/* <div className={styles.switch_wrapper}>*/}
            {/*  <div className={styles.label}>Show NSFW Spells</div>*/}
            {/*  <Switch checked={isShowNSFWSpells} onChange={onShowNSFWChange} className={styles.switch} />*/}
            {/* </div>*/}
          </div>
          {/* <div onClick={onShowDeleteModal} className={styles.delect_label}>Delete Account</div>*/}
        </div>
      </Modal>
      <Modal destroyOnClose={true} width={620} closable={true} maskClosable={false} footer={null} closeIcon={<></>} wrapClassName={'gamie-zone-modal-wrap'} visible={deleteModalVis} centered className={'gamie-zone-modal'}>
        <div className={styles.page}>
          <div className={styles.head_wrapper}>
            <div className={styles.title}>Warnings</div>
            <div onClick={() => setDeleteModalVis(false)} className={styles.close_icon}></div>
          </div>
          <div className={styles.content_wrapper}>
            <div className={styles.delete_content}>
              Are you sure you want to delete your account? All of your data will be permanently removed. This action cannot be reversed.
            </div>
          </div>
          <div className={styles.bottom_wrapper}>
            <div onClick={() => setDeleteModalVis(false)} className={styles.cancel_btn}>Cancel</div>
            <div onClick={null} className={styles.confirm_btn}>Delete</div>
          </div>
        </div>
      </Modal>
    </>
  );

};

export default SettingModal;
