import {
    message
} from 'antd';

export const loginShowModal = {
  showModal: false,
  step: 1,
  login: false,
};

// 统一处理服务端的返回结果
export const dealResult = async (resp, props) => {
    if (resp.resultCode === 200 || resp.resultCode === 20033 || resp.resultCode === 130012) {
        return true;
    }
    if (resp.resultCode === 10006 || resp.resultCode === 10004 || resp.resultCode === 10007 || resp.resultCode === 10005 || resp.code === '45000' || resp.resultCode === 20033 || resp.resultCode === 10000) {
      localStorage.removeItem("token");
      loginShowModal.showModal = true;
        return 'unlogin';
    }
    return false;
};
