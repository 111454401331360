import {SETISREGISTER, SETUSERINFO} from "@/constants/ActionTypes";

export function setUserInfo(payload) {
    return {
       type: SETUSERINFO,
       payload
    };
}

export function setIsRegister(payload) {
    return {
        type: SETISREGISTER,
        payload
    };
}
