import $ from 'jquery';
import {
    dealResult
} from './dealResult';
// import Headers from './headers';
import {
    message
} from 'antd';

function ajaxRequest(methods, url, params, contentType) {
    return new Promise((resolve, reject) => {

        console.log('requesttoken', token);

        // localStorage.setItem('token', token)
        let token = localStorage.getItem('token') || localStorage.getItem('fk-token');
        const regions = localStorage.getItem('regions') ? localStorage.getItem('regions') : '';
        let resRegions;
        switch (regions) {
            case 'en':
                resRegions = "SG";
                break;
            // case 'en-MY':
            //     resRegions = "MY";
            //     break;
            // case 'id-ID':
            //     resRegions = "ID";
            //     break;
            // case 'en-ID':
            //     resRegions = "ID";
            //     break;
            // case 'en-PH':
            //     resRegions = "PH";
            //     break;
            // case 'pt-BR':
            //     resRegions = 'BR';
            //     break;
            // case 'en-BR':
            //     resRegions = "BR";
            //     break;
            // case 'en-EG':
            //     resRegions = "EG";
            //     break;
            // case 'ja-JP':
            //     resRegions = "JP";
            //     break;
            // case 'en-AE':
            //     resRegions = "AE";
            //     break;
            // case 'en-SA':
            //     resRegions = 'SA';
            //     break;
            // case 'en-QA':
            //     resRegions = 'QA';
            //     break;
            case 'ja':
                resRegions = 'JP';
                break;
            default:
                resRegions = "SG";
                break;
        }

        $.ajax({
            type: methods,
            url: url,
            data: params,
            dataType: 'json',
            contentType: contentType === 'json' ? 'application/json' : 'application/x-www-form-urlencoded',
            // headers: headers,
            beforeSend: function (request) {
                if (token){
                    request.setRequestHeader('token', token);
                    request.setRequestHeader('regions', resRegions || 'SG');
                } else {
                    request.setRequestHeader('regions', resRegions || 'SG' );
                }


            },
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true,
            // withCredentials: true,
            success: resp => {
                // if(/\/g\//.test(window.location.href)) {
                //     return resolve(resp)
                // }
                let flog = dealResult(resp);
                flog.then(res => {
                    if (res === 'unlogin') {
                        reject();
                    } else if (!res) {
                        message.error(resp.resultMessage || 'System error report, if you have questions, please contact customer service');
                        return reject(resp.resultMessage);
                    } else {
                        resolve(resp);
                    }
                });
            },
            error: err => {
                console.log('errcc', err);
                reject(err);
            }
        });
    });
}

function ajaxRequestUnDeal(methods, url, params, contentType) {
    return new Promise((resolve, reject) => {
        let token = localStorage.getItem('token') || localStorage.getItem('fk-token') ;
        const regions = localStorage.getItem('regions') ? localStorage.getItem('regions') : '';
        let resRegions;
        switch (regions) {
            case 'en':
                resRegions = "SG";
                break;
            // case 'en-MY':
            //     resRegions = "MY";
            //     break;
            // case 'id-ID':
            //     resRegions = "ID";
            //     break;
            // case 'en-ID':
            //     resRegions = "ID";
            //     break;
            // case 'en-PH':
            //     resRegions = "PH";
            //     break;
            // case 'pt-BR':
            //     resRegions = 'BR';
            //     break;
            // case 'en-BR':
            //     resRegions = "BR";
            //     break;
            // case 'en-EG':
            //     resRegions = "EG";
            //     break;
            // case 'ja-JP':
            //     resRegions = "JP";
            //     break;
            // case 'en-AE':
            //     resRegions = "AE";
            //     break;
            // case 'en-SA':
            //     resRegions = 'SA';
            //     break;
            // case 'en-QA':
            //     resRegions = 'QA';
            //     break;
            case 'ja':
              resRegions = 'JP';
              break;
            default:
                resRegions = "SG";
                break;
        }



        $.ajax({
            type: methods,
            url: url,
            data: params,
            dataType: 'json',
            contentType: contentType === 'json' ? 'application/json' : 'application/x-www-form-urlencoded',
            // headers: headers,
            beforeSend: function (request) {
                if (token){
                    request.setRequestHeader('token', token);
                    request.setRequestHeader('regions', resRegions || 'SG');
                } else {
                    request.setRequestHeader('regions', resRegions || 'SG' );
                }
            },
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true,
            // withCredentials: true,
            success: resp => {
                // if(/\/g\//.test(window.location.href) ||  ) {
                //     return resolve(resp)
                // }
                // let flog = dealResult(resp)
                // if (!flog) {
                //     message.error(resp.resultMessage)
                //     return
                // }
                resolve(resp);
            },
            error: err => {
                console.log('errcc', err);
                reject(err);
            }
        });
    });
}

function ajaxRequestUpload(methods, url, params) {
    return new Promise((resolve, reject) => {
        var formData = new FormData();
        console.log('params', params);
        var fileType = params.file.type.split('/')[0];
        formData.append('file', params.file);
        formData.append('type', fileType);
        $.ajax({
            type: methods,
            url: url,
            processData: false,
            data: formData,
            // dataType: 'json',
            contentType: false,
            success: resp => {
                let flog = dealResult(resp);
                if (!flog) {
                    message.error(resp.msg);
                    return;
                }
                resolve(resp);
            },
            error: err => {
                console.log('errcc', err);
                reject(err);
            }
        });
    });
}

function ajaxRequestFile(methods, url, params) {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token') || localStorage.getItem('fk-token');
        $.ajax({
            type: methods,
            url: url,
            data: params,
            xhrFields: {
                responseType: "arraybuffer"
            },
            beforeSend: function (request) {
                request.setRequestHeader('token', token);
            },
            withCredentials: true,
            success: (resp, status, xhr) => {
                console.log('xhr', xhr);
                console.log('fgfg', xhr.getResponseHeader);
                // let Disposition = xhr.getResponseHeader('Content-Disposition')
                let filename = "";
                let disposition = xhr.getResponseHeader('Content-Disposition');
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disposition);
                    if (matches !== null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                    }
                }

                console.log('file', filename);



                // let fileName = Disposition.split(";")[1]
                // let fileName = 'code.csv'
                let content = resp;
                let blob = new Blob([content], {
                    type: "application/vnd.ms-excel"
                });
                if ("download" in document.createElement("a")) {
                    // 非IE下载
                    let link = document.createElement("a");
                    link.download = filename;
                    link.style.display = "none";
                    link.href = URL.createObjectURL(blob);
                    document.body.appendChild(link);
                    link.click();
                    URL.revokeObjectURL(link.href); // 释放URL 对象
                    document.body.removeChild(link);
                } else {
                    // IE10+下载
                    navigator.msSaveBlob(blob);
                }



                // resolve(xhr)
            },
            error: (err, status, xhr) => {
                console.log('err', err);
                console.log('status', status);
                console.log('xhr', xhr);
                reject(err, status, xhr);
            }
        });
    });

}

export default {
    post(url, params) {
        return ajaxRequest('POST', url, params, '');
    },
    delete(url, params) {
        return ajaxRequest('DELETE', url, params, '');
    },
    get(url, params, token) {
        return ajaxRequest('GET', url, params, '', token);
    },
    upload(url, params) {
        return ajaxRequestUpload('POST', url, params);
    },
    postJson(url, params,) {
       return ajaxRequest('POST', url, params, 'json');
    },
    getJson(url, params,) {
       return ajaxRequest('GET', url, params, 'json');
    },

    file(url, params) {
        return ajaxRequestFile('GET', url, params);
    },

    get_undeal(url, params, token) {
        return ajaxRequestUnDeal('GET', url, params, '', token);
    },
    post_undeal(url, params, token) {
        return ajaxRequestUnDeal('post', url, params, '', token);
    },
    postJson_undeal(url, params, token ) {
        return ajaxRequestUnDeal('post', url, params, 'json', token);
    }

};
