import React, {useEffect, useRef, useState} from "react";
import {Avatar, Button, Input, Layout, message, Modal, Spin} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import Img from "@/components/Img";
import {
  APICreatePaymentRecord,
  APICreateUndealOrder,
  APIGetCoinsAliasName,
  APIGetCoinsList, APIGetIGMBalance, APIGetIntegralAccountPage, APIGetOrderDetail,
  APIGetPaymentChannelUnit
} from "@/api";
import {t} from "i18next";
import {useRouter} from "next/router";
import {useSelector} from "react-redux";
import useDispatchAction from "@/hooks/useDisptachAction";
import {
  setAllowPointsExchangeIGM,
  setBuyCoinsModal,
  setCoinsBalance,
  setExpiringPoints,
  setIGMBalance
} from "@/redux/actions/home";
import {getQueryString} from "@/tools/action";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 55,
      color:'#ff9345'
    }}
    spin
  />
);

const showCountryName = (router) => {
  let regions = router.locale;
  let countryName = '';
  switch (regions) {
    case 'en':
      countryName = t('singapore');
      break;
    case 'en-MY':
      countryName = t('malaysia');
      break;
    case 'en-PH':
      countryName = 'Philippines';
      break;
    case 'en-ID':
      countryName = 'Indonesia';
      break;
    case 'id-ID' :
      countryName = 'Indonesia';
      break;
    case 'en-BR':
      countryName = 'Brazil';
      break;
    case 'pt-BR':
      countryName = 'Brazil';
      break;
    case 'en-AE':
      countryName = 'United Arab Emirates';
      break;
    case 'en-EG':
      countryName = 'Egypt';
      break;
    case 'ja-JP':
      countryName = t('japan');
      break;
    case 'en-SA':
      countryName = 'Saudi Arabia';
      break;
    case 'en-QA':
      countryName = 'Qatar';
      break;
    default:
      countryName = 'Singapore';
      break;
  }
  return countryName;
};

// 积分购买弹窗  <Spin className='discover-post-loading' size='large' indicator={antIcon} /> 加载中
const BuyCoinsModal = () =>{

  const router = useRouter();
  const [step, setStep] = useState(1); // 1:积分商品列表 2:支付方式选择 3:购买积分详情 4:积分订单详情

  const visible = useSelector(state => state.home.setBuyCoinsModal.showModal);
  const dispatchAction = useDispatchAction({setBuyCoinsModal, setIGMBalance, setCoinsBalance, setAllowPointsExchangeIGM, setExpiringPoints});

  const [coinsList, setCoinsList] = useState([]);
  const [payChannelList, setPayChannelList] = useState([]);
  const [currentUnit, setCurrentUnit] = useState("");
  const [readyEmail, setReadyEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [orderDetails, setOrderDetails] = useState({status:0});

  const [checkedCoin, setCheckedCoin] = useState({});
  const [checkedPayment, setCheckedPayment] = useState({});
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [orderId, setOrderId] = useState(-1);

  const timer = useRef(null);

  const clickCoinItem = (item) =>{
    setCheckedCoin(item);
    setStep(2);
  };

  const getCoinsList = (id) =>{
    APIGetCoinsList({id:id}).then(resp=>{
      setCoinsList(resp.result.skus[0].List);
      getPayChannel(resp.result.regions);
    });
  };

  const getPayChannel = (regions) =>{
    APIGetPaymentChannelUnit({regions: regions}).then(resp=>{
      setCurrentUnit(resp.result[0].currencyUnit);
      setPayChannelList(resp.result);
      setCheckedPayment(resp.result[0]);
    });
  };

  const onLoad = async () => {
   const coinsAliasName = onBuyPoints().split('_').join(' ');
   const coinsId = await APIGetCoinsAliasName({key: coinsAliasName});
   getCoinsList(coinsId.result);
  };

  const onBuyPoints = () => {
    let url = "";
    if (window.location.href.indexOf("np.igamie") !== -1 || window.location.href.indexOf("localhost") !== -1) {
      url = "iGamie_Poins-" + showCountryName(router);
    } else {
      url = "iGamie_Point-" + showCountryName(router);
    }
    return url;
  };

  const onClose = () => {
    if (step > 1){
      setStep(step - 1 );
    }
    if (step === 1 || step === 4){
      onCancel();
    }

  };

  const onCancel = () => {
    setStep(1);
    clearInterval(timer.current);
    setOrderDetails({status:0});
    dispatchAction.setBuyCoinsModal(false);
  };

  const checkEmailValid = (email) => {
    let flag = true;
    // 验证邮箱是否正确
    if (!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email)){
      flag  = false;
    }
    setEmailValid(flag);
    return flag;
  };

  const toStep4 = (next) => {
    if (readyEmail && emailValid === true){
      setStep(next);
    } else {
      message.error('Please enter a valid email address');
    }
  };

  const onChangeEmail = (e) => {
    checkEmailValid(e.target.value);
    setReadyEmail(e.target.value);
  };

  const createOrderAndPay = () =>{
    let params = {
      skuId: checkedCoin.id,
      quantity: 1,
      payMethodId: checkedPayment.id,
      sendEmail: readyEmail,
    };
    setLoadingBtn(true);
    APICreateUndealOrder(params).then(resp => {
      let id = resp.result.id;
      setOrderId(id);
      setLoadingBtn(true);
      localStorage.setItem("miniGameOrderID", id);
      localStorage.setItem("orderIdOpen" + id, "1");
      APICreatePaymentRecord({
        orderId: id,
        callBackUrl: window.location.href
      }).then(resp => {
        if (resp.result){
          let callback = resp.result;
          window.location.href = callback;
          setStep(4);
          startCheckOrderStatus(id);
        }
      }).finally(()=>{
        setLoadingBtn(false);
      });
    }).catch(error=>{
      setLoadingBtn(false);
    });
  };

  const startCheckOrderStatus = (orderId) =>{
    timer.current = setInterval(() =>{
      APIGetOrderDetail({
        orderId
      }).then(resp => {
        let result = resp.result;
        setOrderDetails(result);
        if (result.status === 2 || result.status === 3 || result.status === 4){
          getMyCoins();
          clearInterval(timer.current);
        }
      });
    }, 2000);
  };

  const getMyCoins = () => {
    APIGetIntegralAccountPage().then(resp => {
      if (resp.result){
        // setState({
        //   ...state,
        //   coinsNum: resp.result.balance
        // })
        dispatchAction.setCoinsBalance(resp.result && resp.result.balance);
        dispatchAction.setExpiringPoints(resp.result ? resp.result.expired : 0);
      }
    });

    APIGetIGMBalance().then(resp => {
      if (resp.result) {
        dispatchAction.setIGMBalance(resp.result && resp.result.balance);
        dispatchAction.setAllowPointsExchangeIGM(resp.result && resp.result.allowPointsExchange);
      }
    });
  };

  const toOrderDetails = () =>{
    onCancel();
    router.push("/orderDetail/" + orderId);
  };

  useEffect(async ()  =>{
    let currentPath = router.asPath;
    if (currentPath.indexOf("/miniGameDetail") !== -1) {
      await onLoad();
    }
  }, []);

  useEffect(()=>{
    return () =>{
      clearInterval(timer.current);
    };
  }, []);

  useEffect(()=>{
   let currentPath = router.asPath;
   if (currentPath.indexOf("/miniGameDetail") !== -1) {
     let orderId = getQueryString("orderId");
     let status = getQueryString("status");
     if (orderId && status){
       let realOrderId = localStorage.getItem("miniGameOrderID");
       let isOpen = localStorage.getItem("orderIdOpen" + realOrderId);  // 是否打开过这个订单
       if (isOpen && isOpen === "1"){
         localStorage.removeItem("orderIdOpen" + realOrderId);
         dispatchAction.setBuyCoinsModal(true);
         setOrderId(parseInt(realOrderId));
         startCheckOrderStatus(realOrderId);
         setStep(4);
       }
     }
   }
  }, []);


  return (
    <div>
      <Modal destroyOnClose={true} visible={visible} onCancel={onClose} width={620} zIndex={99999} transitionName={"ant-fade"} centered className={`login-new-modal ${styles.buy_coins_wrap}`} footer={false}>
        <div className={styles.model_title}>
          {
            step === 3 ? "Order details" : step === 4 ? "" : "Select product"
          }
        </div>
        {
          step === 1 ? <div className={styles.coins_wrapper}>
            {
              coinsList.map((item, index)=>{
                return <div key={index}  onClick={()=>clickCoinItem(item)} className={styles.coins_item}>
                  <div className={styles.coins_pic}></div>
                  <div className={styles.coins_name}>{item.coins} Points</div>
                  <div className={styles.coins_add_wrap}>
                    <div>+</div>
                    <div className={styles.coins_icon}></div>
                    <div>{item.coins}</div>
                  </div>
                  <div className={styles.buy_btn}>{currentUnit}{item.sellingPrice}</div>
                </div>;
              })
            }
          </div> : null
        }

        {
          step === 2 ?  <div className={styles.pay_methods_wrap}>
            <div className={styles.pay_title}>Payment methods</div>
            <div className={styles.pay_methods_flex_wrap}>
              {
                payChannelList.map(item=>{
                  return (
                    <div onClick={()=>setCheckedPayment(item)} className={`${styles.pay_item} ${checkedPayment?.id === item.id ? styles.checked_pay : ""}`}>
                      <Avatar size={34} className={styles.pay_icon} src={item.icon}></Avatar>
                      <div>{item.paymentMethod}</div>
                    </div>
                  );
                })
              }
            </div>
            <div  className={styles.pay_title}>Request for order summary by email, if needed</div>
            <div  className={styles.pay_enter_email}>Enter your email if you want to receive order summary</div>
            <Input onChange={onChangeEmail} value={readyEmail} className={styles.email_input}></Input>
            <div className={styles.valid_email_tips}>{emailValid ? "" : "Please enter a valid email address!"}</div>
            <div onClick={()=>toStep4(3)} className={styles.confirm_btn}>Proceed to Checkout</div>
          </div> : null
        }

        {
          step === 3 ?
            <div className={styles.order_details}>
              <div className={styles.order_item_wrap}>
                <div className={styles.coins_container}>
                  <div className={styles.coins_detail_icon}></div>
                  <div className={styles.coin_name}>{checkedCoin.coins} Points</div>
                </div>
                <div className={styles.and_icon}>
                  <div className={styles.h_line}></div>
                  <div className={styles.v_line}></div>
                </div>
                <div className={styles.coins_container}>
                  <div className={`${styles.coins_detail_icon} ${styles.igm_detail_icon}`}></div>
                  <div className={styles.coin_name}>{checkedCoin.coins} IGM</div>
                </div>
              </div>
              <div className={styles.order_pay_info}>
                <div className={styles.pay_info_item}>
                  <div>Payment methods</div>
                  <div className={styles.pay_methods_item}>
                    <Avatar size={32} className={styles.pay_icon} src={checkedPayment.icon}></Avatar>
                    <div className={styles.pay_name}>{checkedPayment.paymentMethod}</div>
                  </div>
                </div>
                <div className={styles.pay_info_item}>
                  <div>Total</div>
                  <div className={styles.pay_methods_item}>
                    {currentUnit}{checkedCoin.sellingPrice}
                  </div>
                </div>
                <div style={{height:"7px"}}></div>
              </div>
              <Button loading={loadingBtn} onClick={createOrderAndPay} className={styles.confirm_btn}>Confirm</Button>
            </div>
            : null
        }

        {
          step === 4 ?
            <div className={`${styles.order_details} ${styles.margin_top40}`}>
              <div className={styles.payment_status_wrap}>
                <div className={`${styles.payment_successful} ${orderDetails.status === 2 ? "" : orderDetails.status === 0 ? styles.payment_pending : styles.payment_failed}`}></div>
                <div>
                  {
                    orderDetails.status === 2 ? "Payment Successful" : orderDetails.status === 0 ? "Payment Pending" : "Payment Failed"
                  }
                </div>
              </div>
              <div className={styles.order_pay_info}>
                <div className={styles.pay_info_item}>
                  <div>Payment methods</div>
                  <div className={styles.pay_methods_item}>
                    <Avatar size={32} className={styles.pay_icon} src={"https://igamie.oss-ap-southeast-1.aliyuncs.com/dev/2021-08-26/XYKJrSjYHagi.png"}></Avatar>
                    <div className={styles.pay_name}>PayNow</div>
                  </div>
                </div>
                <div className={styles.pay_info_item}>
                  <div>Total</div>
                  <div className={styles.pay_methods_item}>
                    S$0.7
                  </div>
                </div>
              </div>
              <div onClick={toOrderDetails} className={styles.confirm_btn}>View Order Details</div>
            </div>
            : null
        }
      </Modal>
    </div>
  );
};

export default BuyCoinsModal;
