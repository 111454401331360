import {message} from "antd";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import walletConfig from "@/config/walletConfig";


// TODO: isCheck 传入是否同意登录协议内容； connectCallback 传入钱包登录成功后要执行的方法
export const walletConnect = async (isCheck, connectCallback) => {
  if (localStorage.getItem('walletconnect')){
    localStorage.removeItem('walletconnect');
  }
  if (!isCheck) {
    return message.warning(`
            To proceed, please agree to the Terms of Service and Privacy Policy.
            `);
  }
  const bridge = 'https://bridge.walletconnect.org';
  const connector = new WalletConnect({ bridge, qrcodeModal: QRCodeModal,  });
  if (!connector.connected) {
    await connector.createSession();
  }
  await subscribeToEvents(connector, connectCallback);
};

const subscribeToEvents = async (connector, connectCallback) => {
  if (!connector) {
    return;
  }
  connector.on('connect', (error, payload) => {
    if (error) {
      throw error;
    }
     onConnect(payload, connectCallback, connector);
  });
  if (connector.connected) {
    const { chainId, accounts } = connector;
    const address = accounts[0];
    const walletNumber = Math.floor(Math.random() * 1000);
    const msgParams = [
      walletConfig.getWalletSignMessage(walletNumber, address),
      address,
    ];
    const signData = await connector.signPersonalMessage(msgParams);

     await connectCallback(address, signData, walletNumber);
  }
};


const onConnect = async ( payload, connectCallback, connector ) => {
  try {
    const { chainId, accounts } = payload.params[0];
    const address = accounts[0];
    const walletNumber = Math.floor(Math.random() * 1000)
    const msgParams = [
      walletConfig.getWalletSignMessage(walletNumber, address),
      address,
    ];
    const signData = await connector.signPersonalMessage(msgParams);
    connectCallback(address, signData, walletNumber);
  } catch (e) {
    connectCallback();
  }

};



export const ETHWalletAddressValidator = (address) =>{
  //  /^0x[0-9a-fA-F]{40}$/ //以太坊钱包地址

  if (!/^0x[0-9a-fA-F]+$/.test(address)) {
    // 检查是否具备地址的基本要求
    return false;
  }
  return true;
};

export const splitWalletAddress = (address) =>{
  if (address) {
    address = address.substring(0, 6) + "***" + address.substring(address.length - 4, address.length);
    return address;
  }
  return "";
};

export  const copyWallet = (walletAddress) => {
  var Url2 = walletAddress;
  var oInput = document.createElement('input');
  oInput.value = Url2;
  document.body.appendChild(oInput);
  oInput.select();
  document.execCommand('Copy');
  oInput.className = 'oInput';
  oInput.style.display = 'none';
  document.body.removeChild(oInput);
  message.success('Public address copied to clipboard');
};
