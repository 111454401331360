import {
    SETPCENV,
    SETMOBILEENV
} from '@/constants/ActionTypes';

const initialState = {
    env: 1  // 1代表pc， 2代表mobile
};

const env = (state = initialState, { type, payload }) => {
   switch (type) {
       case SETPCENV:
           return {
               ...state,
               env: 1
           };
       case SETMOBILEENV:
           return {
               ...state,
               env: 2
           };   
       default:
           return state;     
   } 
};

export default env;