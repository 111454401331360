import { HYDRATE } from 'next-redux-wrapper';
import { combineReducers } from 'redux';
import home from './home';
import user from './user';
import env from './env';
import userInfo from './userInfo';
import noticeCount from './noticeCount';
import loginModal from './login';

const _reducers = combineReducers({
  home,
  user,
  env,
  userInfo,
  noticeCount,
  loginModal
});

function reducers(state, action) {
  if (action.type === HYDRATE) {
    return { ...state, ...action.payload };
  }
  return _reducers(state, action);
}

export default reducers;
