import {SETIGMTRANSACTIONMODAL} from "@/constants/ActionTypes";

const initialState = {
  visible: 0,
}

const setIGMTransactionModal = (state = initialState, { type, payload }) => {
  switch (type) {
    case SETIGMTRANSACTIONMODAL: {
      return {
        ...state,
        visible: payload,
      }
    }
    default:
      return state;
  }
}

export default setIGMTransactionModal
