import { useInView } from 'react-intersection-observer';
import { Skeletonleton, Skeleton } from 'antd';
import React, { useState, useEffect, memo, useCallback, useRef } from 'react';
import Image from 'next/image';
const LazyImage = ({fit, quality,  width, height, src, alt, type, reg, className, isLazy, ...rest }) => {
    const s3Url = "igamie-web.s3.ap-southeast-1.amazonaws.com";
    if (src && src.indexOf(s3Url) !== -1){
      // src = src ? src.replace(/igamie-web.s3.ap-southeast-1.amazonaws.com/, 'cdn.igamie.com') : src;
    } else {
      if (src) {
          if (width && height) {
            src = src + ``;
          } else {
            src = src + ``;
          }
      }
    }
    src =  src && src.includes('oss') ? src.replace(/igamie.oss-ap-southeast-1.aliyuncs.com|null.null/, 'static-img.igamie.com') : src
    // src =  src ? src.replace(/igamie-web.s3.ap-southeast-1.amazonaws.com/, 'cdn.igamie.com') : src;
    let format = '/format/webp/quality/50';
    const [isonError, setisonError] = useState(0);
    const [isonLoad, setisonLoad] = useState(0);
    const [imgurl, setimgurl] = useState(null);
   const onErrorimg = useCallback(()=>{
    setisonError(1);
   }, [isonError]);
   const onLoadimg = useCallback(()=>{
    setisonLoad(1);
   }, [isonLoad]);
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px'
  });
  useEffect(()=>{
    let url = '';
    if (!type || type === undefined){
        if (reg !== '' && reg !== undefined){
            url = '?imageMogr2' + format + reg;
        } else {
            console.log('');
        }
        setimgurl(src);
    }
    if (type === 1){
        if (reg !== '' && reg !== undefined){
            url = format + reg;
        } else {
            url = format;
        }
        setimgurl(src + url);
    }
    if (type === 2){
       let src_arr = src.split('?');
       if (reg !== '' && reg !== undefined){
        url = '?imageMogr2' + reg + format;
       } else {
           console.log('');
       }
       setimgurl(src_arr[0] + url);
    }
   }, [src]);

  return (
     <>
     {isLazy === false ?
     imgurl ?
     <img
       {...rest}
       alt={alt}
       src={imgurl}
       className={`${className !== undefined ? className : ''}  ${isonError || !src || !isonLoad ? 'Skeletonleton-loading' : ''}`}
       onLoad={onLoadimg} onError={onErrorimg}
     />
     : (src ? <Skeleton type='img' ></Skeleton> : <img src='/img/noimg.jpg'></img>)

    : <div
      className='LazyImage_inView'
      ref={ref}
      data-inview={inView}
      style={{
        width: '100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
      }}
    >
      {inView ? (
        src ?
        imgurl ?
        <img
          {...rest}
          alt={alt}
          src={imgurl}
          className={`${className !== undefined ? className : ''}  ${isonError || !src || !isonLoad ? 'Skeletonleton-loading' : ''}`}
          onLoad={onLoadimg} onError={onErrorimg}
        />

        : <Skeleton type='img' ></Skeleton>
        :  <div></div>
      ) : null}
    </div>}
    </>
  );
};

export default LazyImage;
